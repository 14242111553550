/**
 * @typedef OrderItem
 * @type {object}
 * @property {number} id
 * @property {string} created_at
 * @property {string} subtotal_count
 * @property {string} delivery_date
 * @property {string} delivery_fee
 * @property {string} subtotal_sales_price_without_tax
 * @property {string} status
 * @property {string} flower_harvest_id
 * @property {string} flower_id
 * @property {string} order_id
 */

/**
 * @typedef OrderItems
 * @type {object}
 * @property {array.<OrderItem>} order_items
 * @property {array.<Date>} date_picker_dates
 * @property {string} start_date
 * @property {string} end_date
 * @property {object} counters
 * @property {number} counters.unconfirmed
 * @property {number} counters.confirmed
 * @property {number} counters.packed
 * @property {number} counters.shipped
 * @property {number} counters.unloaded
 * @property {number} counters.picked
 * @property {number} counters.delivered
 */

const changeStatus = (side, status) => {
  const arr = ["unconfirmed", "confirmed", "packed", "shipped", "delivered"];

  const idx = arr.indexOf(status);
  if (side === "forward") {
    return arr[idx + 1];
  }

  return arr[idx - 1];
};

export default {
  namespaced: true,
  state: {
    list: {
      all: {},
      unconfirmed: {},
      confirmed: {},
      packed: {},
      shipped: {},
      unloaded: {},
      picked: {},
      delivered: {}
    },
    listArr: {
      unconfirmed: [],
      confirmed: [],
      packed: [],
      shipped: [],
      unloaded: [],
      picked: [],
      delivered: []
    },
    date_picker_dates: [],
    closest_next_working_date: "",
    start_date: null,
    end_date: null,
    counters: {
      unconfirmed: 0,
      confirmed: 0,
      packed: 0,
      shipped: 0,
      unloaded: 0,
      picked: 0
    }
  },
  mutations: {
    setData(state, payload) {
      Object.keys(payload).forEach(x => {
        state[x] = payload[x];
      });
    }
  },
  actions: {
    async getOrderItems({ commit, state, rootState, dispatch }, { date, status, id }) {
      const { flowers, shops } = rootState;
      const flowersToLoad = [];
      const shopsToLoad = [];
      let url = "";

      if (status === "shipped") status = "shipped,unloaded,picked,delivered";

      if (date === null) {
        url = id ? `order_items/${id}` : `order_items?status=${status}`;
      } else {
        url = id ? `order_items/${id}` : `order_items?date=${date}&status=${status}`;
      }

      const res = await this._vm.$http("get", url);

      const processResults = async (newData, results) => {
        results.forEach(item => {
          console.log("[]...", item);
          item.delivery_date = new Date(item.delivery_date);
          item.order.order_date = new Date(item.order.order_date);

          newData.list[item.status][item.id] = item;
          newData.list.all[item.id] = item;

          item.order_id = item.id;

          if (!flowers.list[item.flower_id]) {
            flowersToLoad.push(item.flower_id);
          }

          if (!shops.list[item.shop.id]) {
            shopsToLoad.push(item.shop.id);
          }
        });

        Object.keys(newData.listArr).forEach(_status => {
          newData.listArr[_status] = Object.keys(newData.list[_status])
            .map(Number)
            .sort((a, b) => a - b);
        });

        commit("setData", newData);

        if (flowersToLoad.length) {
          await dispatch("flowers/getFlowers", [...new Set(flowersToLoad)], { root: true });
        }

        if (shopsToLoad.length) {
          await dispatch("shops/getShops", [...new Set(shopsToLoad)], { root: true });
        }
      };

      if (res.status === 200) {
        if (res.result.order_items) {
          const newData = {
            ...state,
            date_picker_dates: res.result.date_picker_dates.sort(),
            closest_next_working_date: res.result.closest_next_working_date,
            start_date: new Date(res.result.start_date),
            end_date: new Date(res.result.end_date),
            counters: res.result.counters
          };

          await processResults(newData, res.result.order_items);
        } else if (res.result.order_item) {
          const newData = {
            ...state
          };

          await processResults(newData, [res.result.order_item]);
        }
      }

      return true;
    },
    postOrderItems({ commit, dispatch, state }, payload) {
      return new Promise(resolve => {
        const data = {
          transition_type: payload.side,
          order_item_ids: payload.selectedItems
        };

        this._vm.$http("put", "order_items/transition", data).then(res => {
          if (res.status === 200) {
            const newData = { ...state };

            payload.selectedItems.forEach(id => {
              const newStatus = changeStatus(payload.side, payload.status);

              const newItem = {
                ...newData.list.all[id],
                status: newStatus
              };

              // Append to new status list
              newData.list[newStatus][id] = newItem;
              newData.list.all[id] = newItem;

              // Append to new status listArr
              newData.listArr[newStatus].push(id);

              // Remove from list
              delete newData.list[payload.status][id];

              // Remove from listArr
              newData.listArr[payload.status] = newData.listArr[payload.status].filter(x => x !== id);
            });

            commit("setData", newData);
            dispatch("setToast", { type: "success", msg: "ステータスを変更しました" }, { root: true });
            resolve(true);
          } else if (res.status === 512) {
            dispatch("setToast", { type: "error", msg: "ステータスを変更できませんでした" }, { root: true });
          }
        });
      });
    },
    rejectRequest({ commit, dispatch, state }, payload) {
      return new Promise(resolve => {
        this._vm.$http("put", "order_items/reject_request", payload).then(res => {
          if (res.status === 200) {
            const newData = { ...state };

            payload.order_item_ids.forEach(id => {
              // Remove from list
              delete newData.list.unconfirmed[id];

              // Remove from listArr
              newData.listArr.unconfirmed = newData.listArr.unconfirmed.filter(x => x !== id);
            });

            commit("setData", newData);
            dispatch("setToast", { type: "success", msg: "リクエストを断りました。" }, { root: true });
            resolve(true);
          }
        });
      });
    }
  },
  getters: {
    getState(state) {
      return state;
    },
    getItem: state => id => state.list.all[id]
  }
};
