/**
 * @typedef Dashboard
 * @type {object}
 * @property {number} flower_harvests_count
 * @property {number} order_items_count
 * @property {number} unread_messages_count
 * @property {number} order_items_count_today
 */

export default {
  state: {
    confirmed_order_item_count: 0,
    request_order_items_count: 0,
    unread_messages_count: 0,
    order_items_count_today: 0,
    flowers_count: 0,
    harvests_count: 0,
  },
  mutations: {
    setData(state, payload) {
      Object.keys(payload).forEach(x => {
        state[x] = payload[x];
      });
    }
  },
  actions: {
    async getDashboardData({ commit }) {
      const res = await this._vm.$http("get", "home");
      if (res.status === 200) {
        commit("setData", res.result);
        return true;
      }

      return false;
    }
  },
  getters: {}
};
