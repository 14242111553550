<template>
  <div v-if="!busy">
    <div class="d-flex align-center ma-4 pointer" @click="onGoBack()">
      <ArrowLeftIcon size="12" color="black" />
      <h4 class="ml-2 font-weight-normal">{{ $t("back_short") }}</h4>
    </div>

    <div>
      <img-handler direct :default-value="imgs" @finish="imageHandler" multi-preview :is-edit="!!flower_id"/>
      <div class="select-board">
        <div class="d-flex align-center ma-4">
          <div class="label">{{ $t("category") }}</div>
          <div class="w-100">
            <category :category-id="content.categoryId" @select="categoryHandler" :read-only="!!flower_id"></category>
          </div>
        </div>
        <div class="d-flex align-center ma-4">
          <div class="label">{{ $t("item") }}</div>
          <div class="w-100">
            <genus
              :genus-id="content.genusId"
              :category-id="content.categoryId"
              @select="genusHandler"
              :read-only="!!flower_id"
            ></genus>
          </div>
        </div>
        <div class="d-flex align-center ma-4">
          <div class="label">{{ $t("specie") }}</div>
          <div class="w-100">
            <specie
              :genus-id="content.genusId"
              :specie-id="content.specieId"
              :read-only="!!flower_id"
              @select="id => content.specieId = id"
            ></specie>
          </div>
        </div>
      </div>

      <div class="ma-4 d-flex py-4">
        <MstButton v-if="flower_id && edit" class="btn-show w-100" @click="save">{{ $t('save_changes') }}</MstButton>
        <MstButton v-else-if="flower_id && isWorking" class="btn-add w-100" @click="isWorkingHandler(false)">{{ $t('hide_product') }}</MstButton>
        <MstButton v-else-if="flower_id && !isWorking" class="btn-show w-100" @click="isWorkingHandler(true)">{{ $t('display_product') }}</MstButton>
        <MstButton v-else class="btn-add w-100" :disabled="isSaving" @click="save">{{ $t('add_product') }}</MstButton>
      </div>
    </div>
  </div>
</template>

<script>
import Category from "@/components/farm/flowers/category/index.vue";
import Genus from "@/components/farm/flowers/genus/index.vue";
import Specie from "@/components/farm/flowers/specie/index.vue";
import ImgHandler from "@/components/shared/ImgHandler/index.vue";
import ArrowLeftIcon from "@/components/icons/ArrowLeftIcon.vue";
import { MstButton } from "@/components/master";

export default {
  components: {
    Category,
    Genus,
    Specie,
    ImgHandler,
    ArrowLeftIcon,
    MstButton
  },
  data() {
    return {
      flower_id: null,
      imgs: [],
      isWorking: false,
      content: {
        categoryId: null,
        genusId: null,
        specieId: null
      },
      genusDialog: false,
      specieDialog: false,
      invalid: null,
      error: {},
      busy: false,
      isSaving: false,
      edit: false,
      editContent: {}
    };
  },
  async created() {
    this.flower_id = this.$route.params.flower_id;
    this.busy = true;

    if (this.flower_id) {
      await this.$store.dispatch("flowers/getFlowers", [this.flower_id]);

      const flower = this.$store.state.flowers.list[this.flower_id];

      this.img = flower.image_url;
      this.imgs = flower.images;
      this.content.specieId = flower.flower_specie.id;
      this.content.genusId = flower.flower_specie.flower_genus.id;
      this.content.categoryId = flower.category.id;
      this.isWorking = flower.is_working;

      await this.$store.dispatch("flowerSpecie/getFlowerSpecie", this.content.genusId);
    }

    const responses = [];
    responses.push(this.$store.dispatch("Category/getCategories"));
    responses.push(this.$store.dispatch("flowerGenus/getFlowerGenus"));
    await Promise.all(responses);
    this.busy = false;
  },
  methods: {
    categoryHandler(categoryId, keepSelectedGenus) {
      this.content.categoryId = parseInt(categoryId, 0);

      if (!keepSelectedGenus) {
        this.content.genusId = null;
      }
    },
    async isWorkingHandler(is_working) {
      const id = [this.flower_id];
      await this.$store.dispatch("flowers/putFlowerIsWorking", {
        ids: id,
        is_working
      });
      this.isWorking = is_working;
    },
    genusHandler(genusId, keepSelectedSpecie) {
      this.content.genusId = parseInt(genusId, 0);

      if (!keepSelectedSpecie) {
        this.content.specieId = null;
      }

      if (genusId) {
        this.$store.dispatch("flowerSpecie/getFlowerSpecie", genusId);
      }
    },
    onGoBack() {
      this.$router.go(-1);
    },
    imageHandler(e) {
      this.edit = true;
      switch (e) {
        case "OVERSIZE":
          this.$store.dispatch("setSnackBar", { msg: this.$t("image_size_over"), color: "red" }, { root: true });
          this.$store.dispatch("snackOn", {}, { root: true });
          break;

        case "WRONG_FORMAT":
          this.$store.dispatch("setSnackBar", { msg: this.$t("image_wrong_format"), color: "red" }, { root: true });
          this.$store.dispatch("snackOn", {}, { root: true });
          break;

        default:
          this.content.imgs = e;
          this.$store.dispatch("flowerSpecie/setNewImage", e[0]);
          break;
      }
    },
    async save() {
      this.isSaving = true;

      try {
        if (this.hasError(true)) throw new Error("hasError");
        if (this.flower_id) {
          if (Object.keys(this.content).length) {
            await this.$store.dispatch("flowers/putFlower", {
              flower_id: this.flower_id,
              data: this.content
            });
            this.$store.dispatch("flowerSpecie/setNewImage", null);
            this.edit = false;
          }
        } else {
          if (this.$store.getters["flowerSpecie/isCreatedWithoutImage"]) {
            this.$store.dispatch("flowerSpecie/putFlowerSpecie", {
              specieId: this.content.specieId,
              data: {
                data: (this.content.imgs ?? [])[0]?.image_url
              }
            });
            this.$store.dispatch("flowerSpecie/setIsCreatedWithoutImage", false);
          }
          const id = await this.$store.dispatch(
            "flowers/postFlower",
            this.content
          );

          if (id) {
            this.$store.dispatch("flowerSpecie/setNewImage", null);
            this.$router.push(`/farm/flower_genera/${this.content.genusId}/flowers/${id}`).then(() => {
              setTimeout(() => {
                this.$store.dispatch("setMstSnackBar", { msg: "商品の登録が完了しました。", color: "#21920F" }, { root: true });
                this.$store.dispatch("mstSnackOn", {}, { root: true });
              }, 2500);
            });
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.isSaving = false;
      }
    },
    editHandler() {
      if (!this.edit) {
        this.edit = true;
      } else if (Object.keys(this.editContent).length) {
        this.$store
          .dispatch("flowers/putFlowers", this.editContent)
          .then(() => {
            this.editContent = {};
            this.edit = false;
            this.createTableData();
          });
      } else {
        this.edit = false;
      }
    },
    hasError(ignoreImg) {
      this.invalid = false;
      this.error = {};

      const arr = [
        "imgs",
        "specieId",
      ];

      for (let i = 0; i < arr.length; i++) {
        const x = arr[i];
        if (ignoreImg && x === "imgs") {
          continue;
        }

        if (!this.content[x]) {
          this.invalid = x;
          this.error[x] = true;
          return true;
        }
      }

      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 600px) {
  .detail-table {
    width: 100%;
    & td:nth-child(1) {
      width: 100px;
    }
  }
}

.flower-invalid {
  color: red;
}

.t1 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.detail-select {
  height: 60px;
}

.w-100 {
  width: 100% !important;
}

.btn-add {
  background-color: white !important;
  color: #173169 !important;
  border: 1px solid #173169 !important;
  padding: 0 16px;
  font-weight: 400 !important;
}

.btn-show {
  color: white !important;
  background-color: #173169 !important;
  font-weight: 400 !important;
}

.label {
  width: 150px;
  min-width: 150px;
  font-size: 18px;
  color: #727272;
}

.select-board {
  border-top: 1px solid #D7D7D7;
  margin-right: -16px;
  margin-left: -16px;
  padding: 16px;
}
</style>
