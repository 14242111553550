<template>
  <div class="page-root">
    <div class="header">
      <img class="logo" src="@/assets/CAVIN_Logo_black.png" height="67" />
      <div class="logo-caption">あなたの地域は対応エリアです。<br>新規登録して<br>CAVINを始めましょう。</div>
    </div>

    <div class="content">
      <form class="card" @submit.prevent="submit">
        <div class="card-title">新規会員登録（{{ userType === 'Farm' ? '生産者' : '花屋' }}）</div>

        <v-text-field
          class="text-field"
          type="email"
          ref="email"
          v-model="email"
          :error="error"
          :label="$t('email')"
          outlined
          full-width
          hide-details
        />

        <v-text-field
          class="text-field"
          :type="showPassword ? 'text' : 'password'"
          ref="pwd1"
          v-model="pwd1"
          v-on:keyup="keyupHandler"
          :error="invalid"
          :label="$t('password')"
          outlined
          full-width
          autofocus
          hide-details
        />

        <v-text-field
          class="text-field"
          :type="showPassword ? 'text' : 'password'"
          ref="pwd2"
          v-model="pwd2"
          v-on:keyup="keyupHandler"
          :error="invalid"
          :label="$t('confirm_password')"
          outlined
          full-width
          autofocus
          hide-details
        />

        <div class="checkbox">
          <v-checkbox :label="$t('show_pwd')" v-model="showPassword" class="ma-0" color="success" hide-details />
        </div>

        <div v-if="error" class="label registered">{{ $t("email_already_registered") }}</div>

        <button class="button" type="submit" :disabled="disabled">アカウント作成</button>
      </form>

      <div class="note">
        {{ $t("login_is") }}
        <router-link class="link" :to="`/${userType}`">{{ $t("here") }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userType: this.$route.query.type,
      email: "",
      pwd1: "",
      pwd2: "",
      error: false,
      invalid: false,
      axiosOptions: {},
      showPassword: false,
    };
  },
  computed: {
    disabled() {
      if (!this.email || !this.pwd1 || !this.pwd2) return true;
      if (this.pwd1 !== this.pwd2) return true;
      if (!this.validEmail || !this.validPassword) return true;

      return false;
    },
    validEmail() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return re.test(this.email);
    },
    validPassword() {
      const re = /^([a-zA-Z0-9]{6,100})$/;

      return re.test(this.pwd1) && re.test(this.pwd2);
    }
  },
  methods: {
    async submit() {
      const payload = {
        user: {
          user_type: this.userType,
          email: this.email,
          password: this.pwd1,
          password_confirmation: this.pwd2
        }
      };

      const res = await this.$store.dispatch("sign_up", payload);

      if (!res) {
        this.error = true;
        return;
      }

      this.axiosOptions = {
        headers: {
          Authorization: res
        }
      };

      const base_address = this.$store.state.unuseableAreaUsers;

      const location = {
        location: {
          postal_code: base_address.postal_code,
          prefecture_id: base_address.prefecture_id ? base_address.prefecture_id : null,
          municipality: base_address.municipality,
          address: base_address.address,
          area_id: base_address.area_id
        }
      };

      const locRes = await this.$api.get("users/profile", this.axiosOptions);

      const locationId = (this.userType === "Farm") ? locRes.result.user.farm.locations[0].id : locRes.result.user.shop.locations[0].id;

      await this.$api.put(
        `/locations/${locationId}`,
        location,
        this.axiosOptions
      );

      this.$router.push({ path: `/users/confirm?type=${this.userType}`, params: { access_token: res } });
    },
    async setPwd() {
      this.invalid = false;
      if (!this.pwd1) {
        this.$refs.pwd1.focus();
        return;
      }

      if (!this.pwd2) {
        this.$refs.pwd2.focus();
        return;
      }

      if (this.pwd1 !== this.pwd2) {
        this.invalid = true;
      }
    },
    keyupHandler(e) {
      e.preventDefault();

      if (e.key === "Enter") {
        this.setPwd();
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.header {
  margin-bottom: 40px;

  .logo {
    display: block;
    margin: 0 auto;
  }

  .logo-caption {
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }
}

.content {
  max-width: 600px;
  margin: 0 auto;
}

.card {
  padding: 30px 20px 50px;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  color: #333;
}

.card-title {
  font-size: 26px;
  font-weight: bold;
  text-align: center;
}

.label {
  margin: 20px auto 0;
  max-width: 450px;
  font-size: 16px;
}

.registered {
  color: red;
}

.text-field {
  margin: 1rem auto;
  max-width: 450px;
}

.button {
  margin: 40px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 50px;
  background-color: #007e57;
  border-radius: 3px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  outline: none;
}

.button:disabled {
  background: #ccc;
}

.checkbox {
  color: #979797;
  font: SemiBold 14px/18px Muli;
}

.note {
  margin-top: 20px;
  font-size: 16px;
}

.link {
  color: inherit;
  text-decoration: underline;
}
</style>
