<template>
  <div class="overflow: auto">
    <div class="d-flex justify-space align-center">
      <v-btn @click="$emit('back')" class="ma-2" icon large>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <h3 class="ma-5">{{ $d(date, 'normalDate') }} <span class="ml-2 mr-2">({{ $d(date, 'day') }})</span>
      {{ $t("delivery") }} - {{ category == null ? $t("all_1") : category.name }}</h3>
    <v-divider/>
    <div style="overflow: auto; height: 73vh;">
      <v-list class="pt-0">
        <v-list-item class="pt-2 pb-2 pl-5 pr-5">
          <v-list-item-content>
            {{ $t("search") }} - {{ $t("choose_farm") }}
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <div v-for="item in availableFarms" :key="item.id">
          <v-list-item @click="$emit('click', {key: 'farmIds', value: item.id})" class="pt-2 pb-2 pl-5 pr-5">
            <v-list-item-content :class="{'filter-mob-font': farmIds.includes(item.id)}" class="pl-3">
              <div>
                <span class="mr-2">{{ item.name }}</span>
              </div>
            </v-list-item-content>

            <v-list-item-action>
              <v-icon v-if="farmIds.includes(item.id)" class="selected-item-icon">mdi-check</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-divider />
        </div>
      </v-list>
    </div>
    <div class="d-flex justify-space-around mb-4">
      <v-btn color="#03A9F4" @click="$emit('clearFilterBy', {key: 'farmIds'})" outlined>{{ $t("clear") }}</v-btn>
      <v-btn class="blue" @click="$emit('back')" style="width: 50%">{{ $t("confirm") }}</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    farmIds: {
      type: Array,
      default: null
    },
    date: {
      type: String,
      default: null
    },
    category: {
      type: Object,
      default: null
    }
  },
  computed: {
    availableFarms() {
      return this.$store.state.searchQuery.available_farms;
    }
  }
};
</script>

<style scoped>
</style>
