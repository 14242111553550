<template>
  <component
    :is="to ? 'router-link' : 'button'"
    :type="to ? undefined : 'button'"
    :to="to"
    class="mstLinkBar"
    @click="$emit('click')"
  >
    <MstIcon :type="icon" size="28px" class="mstLinkBar__icon" />
    {{ label }}
    <MstIcon type="chevron-right" size="24px" class="mstLinkBar__chevron" />
  </component>
</template>

<script>
import { MstIcon } from "@/components/master";

export default {
  name: "MstLinkBar",
  components: { MstIcon },
  props: {
    icon: { type: String, required: true },
    label: { type: String, required: true },
    to: { type: String },
  },
};
</script>

<style lang="scss" scoped>
.mstLinkBar {
  position: relative;
  display: block;
  border-radius: 8px;
  padding: 16px 52px;
  width: 100%;
  background: #f5f7f8;
  font-size: variables.$font-size-md;
  line-height: 1.2;
  text-align: left;
  font-weight: bold;
  text-decoration: none;
  color: variables.$color-black-900;
  transition: background 0.3s ease;

  &:hover,
  &:focus-visible {
    background: variables.$color-gray-100;
  }
}

.mstLinkBar__icon {
  position: absolute;
  top: 50%;
  left: 17px;
  color: variables.$color-brand-primary;
  transform: translateY(-50%);
}

.mstLinkBar__chevron {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}
</style>
