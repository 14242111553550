<template>
  <div class="page-container">
    <div v-if="isDateView" >
      <h2 class="text-center ma-4">{{ $t("farmHarvest") }}</h2>
      <div class="view-type-btn-group">
        <button>
          <img :src="require('@/assets/calendar-icon.svg')"/>
        </button>
        <button @click="isDateView = !isDateView">
          <img :src="require('@/assets/disabled-product-icon.svg')"/>
        </button>
      </div>
      <DateBaseList />
    </div>
    <div v-else>
      <div v-if="!selectedFlower">
        <h2 class="text-center ma-4">{{ $t("farmHarvest") }}</h2>
        <div class="d-flex view-type-btn-group">
          <button @click="isDateView = !isDateView">
            <img :src="require('@/assets/disabled-calendar-icon.svg')"/>
          </button>
          <button>
            <img :src="require('@/assets/product-icon.svg')"/>
          </button>
        </div>
        <FlowerBaseList @selectFlower="selectFlower" />
      </div>
      <div v-else>
        <FlowerBaseHarvestList :flower="selectedFlower" :flowerHarvests="flowerHarvests" @selectFlower="selectFlower" />
      </div>
    </div>
  </div>
</template>

<script>
import DateBaseList from "@/components/farm/flower_harvests/dateBaseList.vue";
import FlowerBaseList from "@/components/farm/flower_harvests/flowerBaseList.vue";
import FlowerBaseHarvestList from "@/components/farm/flower_harvests/flowerBaseHarvestList.vue";

export default {
  components: { DateBaseList, FlowerBaseList, FlowerBaseHarvestList },
  data() {
    return {
      isDateView: true,
      selectedFlower: null,
      flowerHarvests: [],
    };
  },
  methods: {
    async selectFlower(item) {
      if (item?.flower_id) {
        this.selectedFlower = item;
        await this.$store.dispatch("flowerHarvest/getHarvest", { flower_id: item.flower_id });
        this.flowerHarvests = await this.$store.state.flowerHarvest.list;
      } else {
        this.selectedFlower = null;
        this.flowerHarvests = [];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.view-type-btn-group {
  position: relative;
  left: calc( 100vw - 80px );
  top: -46px;
  display: flex;
  gap: 12px;
}
</style>
