import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";
import getDefaultLocation from "@/util/getDefaultLocation";
import auth from "./auth";
import admin from "./admin";
import flowerHarvest from "./flowerHarvest";
import Category from "./category";
import collections from "./collections";
import flowerGenus from "./flowerGenus";
import flowerSpecie from "./flowerSpecie";
import flowers from "./flowers";
import orderItems from "./orderItems";
import shops from "./shops";
import farms from "./farms";
import farmDashboard from "./farmDashboard";
import orders from "./orders";
import conversations from "./conversations";
import driverInformation from "./driverInformation";
import drivers from "./drivers";
import unuseableAreaUsers from "./unuseableAreaUsers";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    localStorageKey: "CavidApp",
    localStorageKeyAuth: "CavidAppAuth",
    httpBusy: false,
    isMobileMenuOpen: false,
    pdfData: null,
    toast: { type: null, msg: null },
    snackbar: {
      color: null,
      isEnable: false,
      msg: ""
    },
    user: {
      shop: {},
      driver: {
        areas: [{}]
      },
    },
    searchQuery: {
      available_farms: [],
      available_flower_genus: [],
      delivery_date_list: [],
      categories: []
    },
    defaultLocation: {},
    mstSnackBar: {
      color: null,
      isEnable: false,
      msg: ""
    },
  },
  mutations: {
    setBusy(state, payload) {
      state.httpBusy = payload;
    },
    setProfile(state, payload) {
      if (payload.user) {
        state.user = payload.user;
      }
      if (payload.defaultLocation) {
        state.defaultLocation = payload.defaultLocation;
      }
    },
    setSearchQuery(state, payload) {
      Object.keys(payload).forEach(x => {
        state.searchQuery[x] = payload[x];
      });
    },
    setMobileMenuOpen(state, payload) {
      state.isMobileMenuOpen = payload;
    },
    setToast(state, payload) {
      state.toast.type = payload.type;
      state.toast.msg = payload.msg;
    },
    setSnackBar(state, payload) {
      state.snackbar.msg = payload.msg;
      state.snackbar.color = payload.color;
    },
    setSnackBarEnable(state) {
      state.snackbar.isEnable = true;
    },
    setSnackBarDisable(state) {
      state.snackbar.isEnable = false;
    },
    setMstSnackBar(state, payload) {
      state.mstSnackBar.msg = payload.msg;
      state.mstSnackBar.color = payload.color;
    },
    setMstSnackBarEnable(state) {
      state.mstSnackBar.isEnable = true;
    },
    setMstSnackBarDisable(state) {
      state.mstSnackBar.isEnable = false;
    }
  },
  actions: {
    setBusy({ commit }, payload) {
      commit("setBusy", payload);
    },

    setMobileMenuOpen({ commit }, payload) {
      commit("setMobileMenuOpen", payload);
    },

    getProfile({ commit }, userType) {
      return new Promise(resolve => {
        this._vm.$http("get", "users/profile").then(res => {
          const obj = {
            user: res.result.user,
            defaultLocation: getDefaultLocation(res.result.user[userType].locations)
          };

          commit("setProfile", obj);
          resolve(res.result.user);
        });
      });
    },

    async getSearchQuery({ commit }) {
      const res = await this._vm.$http("get", "home/search_query");
      if (res.status === 200) {
        // MEMO: 「その他」を配列の最後にする
        const { categories } = res.result;
        const othersCategoryIndex = categories.findIndex(category => category.name === "その他");
        if (othersCategoryIndex > -1) {
          categories.push(categories[othersCategoryIndex]);
          categories.splice(othersCategoryIndex, 1);
        }

        commit("setSearchQuery", res.result);
        return true;
      }

      return false;
    },

    putProfile({ state, commit }, { userType, content }) {
      const body = {
        [userType]: content
      };

      this._vm.$http("put", `/shops/${state.user.shop.id}`, body).then(res => {
        if (res.status === 200) {
          commit("setProfile", { user: { ...state.user, ...content } });
        }
      });
    },

    postLocation({ state, commit }, location) {
      return new Promise(resolve => {
        this._vm.$http("post", "locations", { location }).then(res => {
          if (res.status === 201) {
            const user = { ...state.user };

            user.shop.locations.push({ id: res.result.location.id, ...location });

            commit("setProfile", { user: { ...state.user } });
            resolve(user.shop.locations.filter(x => !x.default).length - 1);
          }
          resolve(false);
        });
      });
    },

    putLocation({ commit, state }, { id, content }) {
      return new Promise(resolve => {
        const body = { location: content };

        this._vm.$http("put", `/locations/${id}`, body).then(res => {
          if (res.status === 200) {
            const newUser = { ...state.user };

            newUser.shop.locations = newUser.shop.locations.map(location => {
              if (location.id === id) {
                return {
                  ...location,
                  ...content
                };
              }
              return location;
            });

            commit("setProfile", { ...state.user, ...newUser });
            resolve(true);
          }

          resolve(false);
        });
      });
    },

    delLocation({ commit, state }, id) {
      this._vm.$http("delete", `/locations/${id}`).then(res => {
        if (res.status === 200) {
          const newUser = { ...state.user };

          newUser.shop.locations = newUser.shop.locations.filter(location => location.id !== id);

          commit("setProfile", { ...state.user, ...newUser });
        }
      });
    },

    getPdfDataAdmin({}, { token, farm_id, start_date, end_date }) {
      return new Promise(resolve => {
        axios.defaults.headers.common.Authorization = token;
        this._vm.$http("get", `farms/pdf?farm_id=${farm_id}&start_date=${start_date}&end_date=${end_date}`).then(res => {
          if (res.status === 200) {
            resolve(res.result);
          }
        });
      });
    },

    setToast({ commit }, { type, msg }) {
      commit("setToast", { type, msg });

      setTimeout(() => {
        commit("setToast", { type: null, msg: null });
      }, 2000);
    },
    setSnackBar({ commit }, { msg, color }) {
      commit("setSnackBar", { msg, color });
    },
    snackOn({ commit }) {
      commit("setSnackBarEnable");
    },
    snackOff({ commit }) {
      commit("setSnackBarDisable");
    },
    setMstSnackBar({ commit }, { msg, color }) {
      commit("setMstSnackBar", { msg, color });
    },
    mstSnackOn({ commit }) {
      commit("setMstSnackBarEnable");
    },
    mstSnackOff({ commit }) {
      commit("setMstSnackBarDisable");
    }
  },
  getters: {
    isBusy(state) {
      return state.httpBusy;
    },
    isAddressLimit(state) {
      return state.user.shop.locations && state.user.shop.locations.length >= 3;
    },
    getDefaultLocation(state) {
      return state.defaultLocation;
    },
    getSpecialDeliveryDays(state) {
      return state.defaultLocation.area ? state.defaultLocation.area.special_delivery_days.map(d => d.delivery_date) : [];
    },
    workingWdays(state) {
      let locations = [];
      if (state.user.shop && state.user.shop.locations) {
        locations = state.user.shop.locations;
      } else if (state.user.farm && state.user.farm.locations) {
        locations = state.user.farm.locations;
      } else {
        return locations;
      }
      const workingWdays = locations.map(location => location.area.working_wdays).reduce((previousResult, currentValue) => previousResult.concat(currentValue));

      return Array.from(new Set(workingWdays));
    }
  },
  modules: {
    auth,
    admin,
    flowerHarvest,
    flowers,
    orderItems,
    shops,
    farms,
    farmDashboard,
    orders,
    conversations,
    Category,
    collections,
    flowerGenus,
    flowerSpecie,
    driverInformation,
    drivers,
    unuseableAreaUsers
  }
});
