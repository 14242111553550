<template>
  <div class="filter mb-2">
    <CategoryTabs ref="categoryTabs" :categories="categories" :on-search="clickCategoryTab" />
    <div class="mb-2 d-flex justify-center">
      <div class="search-button-position" v-if="!showFilter">
        <MstFloatingButton :label="$t('search')" icon="mdi-file-search-outline" color="#173169" labelPosition="inside" @click="showFilter = !showFilter" />
      </div>
      <v-card v-if="showFilter" class="filter-mob d-flex flex-column">
        <div class="flex-grow-1">
          <div v-if="!filter">
            <div class="d-flex justify-space align-center">
              <v-btn @click="showFilter = !showFilter" class="ma-2" icon large>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <h3 class="ma-5">{{ $d(this.query.date, 'normalDate') }} <span class="ml-2 mr-2">({{ $d(this.query.date, 'day') }})</span>
              {{ $t("delivery") }} - {{ this.query.category == null ? $t("all_1") : this.query.category.name }}</h3>
            <v-divider/>

            <v-list class="pt-0">
              <item
                :name="$t('item')"
                :value="selectedGenusNames()"
                menu="genus"
                @change="e=>filter=e"
              />
              <v-divider/>

              <item
                :name="$t('farm')"
                :value="selectedFarmNames()"
                menu="farm"
                @change="e=>filter=e"
              />
              <v-divider/>

            </v-list>
          </div>

          <div v-else-if="filter==='genus'">
            <Genus @click="clickHandler" @back="filter=null" @clearFilterBy="clearFilterBy"
              :genusIds="query.genusIds" :date="query.date" :category="query.category"/>
          </div>

          <div v-else-if="filter==='farm'">
            <Farm @click="clickHandler" @back="filter=null" @clearFilterBy="clearFilterBy"
              :farmIds="query.farmIds" :date="query.date" :category="query.category"/>
          </div>
        </div>

        <div v-if="!filter" class="d-flex justify-space-around mb-4">
          <v-btn color="#03A9F4" @click="clearFilter" outlined>{{ $t("clear") }}</v-btn>
          <v-btn class="blue" @click="search" style="width: 60%">{{ $t("search_for") }}</v-btn>
        </div>
      </v-card>
    </div>
    <div class="search-criteria ps-3 pt-1 pb-1" v-if="!!searchCriteria">
      {{ $t('filter_criteria') }} : {{ searchCriteria }}
    </div>
  </div>
</template>

<script>
import Item from "@/components/shop/products/FilterMobile/Item.vue";
import Genus from "@/components/shop/products/FilterMobile/Genus.vue";
import Farm from "@/components/shop/products/FilterMobile/Farm.vue";
import CategoryTabs from "@/components/shop/products/CategoryTabs.vue";
import { MstFloatingButton } from "@/components/master";

export default {
  components: {
    Item,
    Genus,
    Farm,
    CategoryTabs,
    MstFloatingButton
  },
  props: {
    list: {
      type: Array,
      default: null
    },
    farms: {
      type: Array,
      default: null
    },
    categories: {
      type: Array,
      default: null
    },
    selectedDate: {
      type: String,
      default: null
    },
  },
  watch: {
    selectedDate() {
      this.query.date = this.selectedDate;
      this.$emit("click", this.query);
    }
  },
  data() {
    return {
      showFilter: false,
      query: {
        category: null,
        genusIds: [],
        farmIds: [],
        color: null,
        date: this.selectedDate,
        stem_size: null
      },
      filter: null,
      searchCriteria: null
    };
  },
  methods: {
    initialize() {
      if (this.$route.query.category_id) {
        this.clickHandler({
          key: "category",
          value: this.$store.state.searchQuery.categories
            .find(item => item.id === Number(this.$route.query.category_id)) ?? null
        });
      }
      if (this.$route.query.flower_genus_id) {
        if (Array.isArray(this.$route.query.flower_genus_id)) {
          const genusIds = this.$route.query.flower_genus_id.map(_id => Number(_id));
          this.query.genusIds = [...new Set(genusIds)]; // get unique values
        } else {
          this.query.genusIds = [Number(this.$route.query.flower_genus_id)];
        }
      }
      if (this.$route.query.farm_id) {
        if (Array.isArray(this.$route.query.farm_id)) {
          const farmIds = this.$route.query.farm_id.map(_id => Number(_id));
          this.query.farmIds = [...new Set(farmIds)]; // get unique values
        } else {
          this.query.farmIds = [Number(this.$route.query.farm_id)];
        }
      }
      let stem_size = null;
      if (this.$route.query.stem_size) {
        stem_size = Number(this.$route.query.stem_size);
      }
      if (stem_size !== null) {
        this.clickHandler({
          key: "stem_size",
          value: stem_size
        });
      }
      this.setFilterCriteria();
    },
    setFilterCriteria() {
      const criterias = [];
      if (this.selectedGenusNames()) {
        criterias.push(this.selectedGenusNames());
      }
      if (this.selectedFarmNames()) {
        criterias.push(this.selectedFarmNames());
      }
      this.searchCriteria = criterias.join("; ");
    },
    selectedGenusNames() {
      return this.query.genusIds.map(_id => this.$store.state.searchQuery.available_flower_genus.find(item => item.id === Number(_id)).name).join(", ");
    },
    selectedFarmNames() {
      return this.query.farmIds.map(_id => this.$store.state.searchQuery.available_farms.find(item => item.id === Number(_id)).name).join(", ");
    },
    clickCategoryTab(e) {
      if (!e.value) {
        this.clearBy("category");
      } else {
        this.query.category = e;
        this.clickHandler(e);
        this.search();
      }
    },
    clickHandler(e) {
      if (["farmIds", "genusIds"].includes(e.key)) {
        this.addRemoveArray(this.query[e.key], e.value);
      } else {
        this.query[e.key] = this.query[e.key] === e.value ? null : e.value;
      }

      // if (e.key !== "color") {
      //   this.filter = null;
      // }
    },
    search() {
      this.showFilter = false;
      this.$refs.categoryTabs.clearTab();
      this.setFilterCriteria();
      this.$emit("click", this.query);
    },
    clearFilter() {
      this.filter = null;
      // this.query.category = null;
      this.query.genusIds = [];
      this.query.farmIds = [];
      this.query.color = null;
      // this.query.date = null;
      this.query.stem_size = null;
      this.$emit("change", { key: "clear" });
    },
    clearFilterBy(e) {
      const initValue = (["farmIds", "genusIds"].includes(e.key)) ? [] : null;
      this.query[e.key] = initValue;
    },
    clearBy(key) {
      this.$refs.categoryTabs.clearTab();
      this.query[key] = null;
      this.search();
    },
    clearByStemSize() {
      this.query.stem_size = null;
      this.search();
    },
    addRemoveArray(array, object) {
      const index = array.findIndex(o => o === object);
      if (index === -1) array.push(object);
      else array.splice(index, 1);
      return array;
    }
  }
};
</script>

<style lang="scss">
.search-button-position {
  position: fixed;
  bottom: 100px;
  right: 20px;
  z-index: 100;
}

.filter-mob {
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  height: 100vh;
  width: 100vw;
  overflow: auto;
  font-family: sans-serif;
  z-index: 5;

  &-font {
    font-weight: 500;
    font-size: 16px;

    color: #4A8FCF;
  }
}

.selected-item-icon {
  color: #4A8FCF !important;
}

.search-criteria {
  border-top: 1px solid rgba(153, 153, 153, 1);
  border-bottom: 1px solid rgba(153, 153, 153, 1);
  font-size: 12px;

  position: fixed;
  left: 0;
  background: white;
  z-index: 2;
  width: 100vw;
  bottom: 56px;
  font-family: sans-serif;
}
</style>
