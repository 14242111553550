<template>
  <div class="pa-4 bg-back-gray my-2 rounded-8">
    <div class="d-flex justify-space-between align-center">
      <div class="mb-1 color-gray-900">
        {{ shopName }}
      </div>
      <div class="color-secondary font-weight-bold mb-1 text-xl">
        {{ totalCount }}
        <span class="text-xs">{{ $t('volumn') }}</span>
      </div>
    </div>
    <div class="d-flex justify-space-between">
      <p class="font-weight-bold mb-0 color-black-500">{{ speciesName }} / {{ genusName }}</p>
      <p class="mb-0">＠{{ harvestMinCount }}{{ $t('volumn') }} × {{ lotCount }}{{ $t('lot') }} × {{ price }}{{ $t('yen') }}</p>
    </div>
    <div class="d-flex justify-space-between">
      <p class="color-white bg-color-menu px-2">{{ stemSize }}</p>
      <p>= {{ totalPrice.toLocaleString() }}{{ $t('yen') }}</p>
    </div>
    <div class="d-flex justify-space-between align-center">
      <div>{{ $t('order') }} : {{ id }}</div>
      <div class="d-flex align-center">
        <button class="turndown-btn px-4 py-1 rounded-4 mr-2" @click="$emit('openCancelModal')">
          <svg width="12" height="11" viewBox="0 0 12 11" fill="none">
            <path d="M11 10.5L1 0.5M11 0.5L1 10.5" stroke="white" stroke-linecap="round"/>
          </svg>
          {{ $t('reject_order') }}
        </button>
        <button class="approval-btn px-4 py-1 rounded-4 d-flex align-center" @click="$emit('openConfirmModal')">
          <svg width="14" height="15" viewBox="0 0 14 15" fill="none">
            <path d="M2.33301 7.5L5.83301 11L11.6663 4" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          {{ $t('approval') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: Number,
    speciesName: String,
    genusName: String,
    stemSize: String,
    totalCount: Number,
    totalPrice: Number,
    price: Number,
    lotCount: Number,
    harvestMinCount: Number,
    shopName: String,
  },
  data() {
    return {
      mobile: this.$vuetify.breakpoint.xs
    };
  }
};
</script>

<style scoped>
.turndown-btn {
  background-color: #C75757;
  font-size: 14px;
  color: white;
}
.approval-btn {
  background-color: #67A16D;
  font-size: 14px;
  color: white;
}
.rounded-4 {
  border-radius: 4px;
}
</style>
