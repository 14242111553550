<template>
  <v-app id="app">
    <router-view :key="$route.fullPath" />
    <span class="version">V {{ version }}</span>

    <toast></toast>
    <snack-bar></snack-bar>
    <mst-snack-bar></mst-snack-bar>

    <v-progress-linear
      :active="isBusy"
      :z-index="300"
      :indeterminate="isBusy"
      fixed
      top
      dark
      :height="7"
      color="#375293"
    ></v-progress-linear>

    <v-overlay v-if="$route.path.startsWith('/shop')" :value="isBusy" :opacity="0.1" z-index="1500">
      <v-progress-circular
        indeterminate
        size="64"
        color="#375293"
      ></v-progress-circular>
    </v-overlay>

    <portal-target name="portal" />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import Toast from "@/components/Toast.vue";
import SnackBar from "@/components/SnackBar.vue";
import MstSnackBar from "@/components/master/MstSnackBar/index.vue";

export default {
  name: "App",
  components: { Toast, SnackBar, MstSnackBar },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
    };
  },
  computed: {
    ...mapGetters(["isBusy"]),
  },
  created() {
    const versionKey = "CavidAppVersion";
    const nowVersion = process.env.VUE_APP_VERSION;
    const lastVersion = window.localStorage.getItem(versionKey);
    window.localStorage.setItem(versionKey, nowVersion);
    if (nowVersion !== lastVersion) {
      window.navigator.serviceWorker.getRegistrations()
        .then(registrations => {
          registrations.forEach(registration => registration.unregister());
        });
      window.location.reload(true);
    }
    this.$store.dispatch("init");
    // if (this.$route.path !== "/maintenance") {
    //   this.$router.push("/maintenance");
    // }
    if (
      this.$route.path !== "/pdf"
      && this.$route.path !== "/shopPdf"
      && this.$route.path !== "/shopReceipt"
      && !this.$route.path.startsWith("/users")
      && !this.$route.path.startsWith("/farm/login")
      && !this.$route.path.startsWith("/shop/login")
      && !this.$route.path.startsWith("/admin/login")
    ) {
      if (this.$store.getters.isSignedIn) {
        const type = this.$store.getters.getUserType;
        const rootPath = this.$route.matched[0] && this.$route.matched[0].path;
        const path = `/${type}`;

        if (this.$route.path === "/" || rootPath !== path) {
          this.$router.push(path);
        }
      } else {
        this.$router.push("/");
      }
    }
  },
};
</script>

<style lang="scss">
a {
  text-decoration: none;
}

.error-text {
  color: red;
  font-size: 12px;
}
.v-btn {
  height: 2.5rem !important;
}
.v-date-picker-table .v-btn {
  height: 2rem !important;
}

.v-select {
  margin-bottom: -28px !important;
}

.pointer {
  cursor: pointer;
}

.w-100 {
  widows: 100% !important;
}

.input {
  width: 100%;
  height: 50px;
  background: #ffffff;
  border: 2px solid #cccccc;
  border-radius: 5px;
  padding-left: 0.5rem;
}

#customSelect .v-select .dropdown-toggle {
  background: #ffffff;
  border: 2px solid #cccccc;
  border-radius: 5px;
  padding-left: 0.5rem;
}

::placeholder {
  color: #cccccc;
  opacity: 1;
  padding-left: 0.5rem;
}

.invalid {
  border-color: rgba(255, 0, 0, 0.5) !important;
}

.green {
  width: 140px;
  background-color: #007e57;
  color: white !important;
  font: Bold 1rem Helvetica Neue;
}

.orange {
  width: 140px;
  background-color: #ff8c71;
  color: white !important;
  font: Bold 1rem Helvetica Neue;
}

.pink {
  width: 140px;
  background-color: #f06292;
  color: white !important;
  font: Bold 1rem Helvetica Neue;
}

.blue {
  width: 140px;
  background: #5faedb;
  border-radius: 3px;

  color: white !important;
  font: Bold 1rem Helvetica Neue;
}

.btn-white {
  background-color: #ffffff !important;
  color: #777777 !important;
  border: 1px solid #cccccc;
  font: Bold 1rem Helvetica Neue;
}

/* FLOWER COLOR INDICATORS */
.flower-color {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: 0.03125rem solid;
}
.flower-color.big {
  width: 2rem;
  height: 2rem;
}
.flower-color.small {
  width: 1rem;
  height: 1rem;
}
.flower-color.ex-small {
  width: 0.75rem;
  height: 0.75rem;
}

.flower-color-mix {
  background: linear-gradient(217deg, red, rgba(255, 0, 0, 0) 70.71%),
    linear-gradient(127deg, blue, rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(336deg, green, rgba(0, 0, 255, 0) 70.71%);
}

tbody tr:nth-of-type(odd) {
  background-color: #f5f5f5;
}

.version {
  position: fixed;
  color: #e6e6e6;
  font-size: 12px;
  bottom: 5px;
  left: 5px;
}

// Bug on data-tables, rows-per-page selectbox not aligned
.v-data-footer .v-input {
  margin-top: -35px !important;
}

.driver .theme--light.v-data-table .v-row-group__header {
  background: #d3d3d3 !important;
}
.driver tbody tr:nth-of-type(odd) {
  background-color: #ffffff;
}
.driver
  .theme--light
  .v-data-table
  tbody
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #f5f5f5;
}
.driver .theme--light .v-data-table tbody tr.v-data-table__selected {
  background: rgba(0, 121, 107, 0.12);
}
</style>
