<template>
  <div class="footer d-flex align-center justify-space-between py-3">
    <div
      v-for="item in menus"
      :key="item.name"
    >
      <router-link :to="item.path" :class="`d-flex flex-column align-center justify-center ${item.class} ${selectedMenu === item.name ? 'selected' : ''}`" >
        <HomeIcon v-if="item.name === 'farmDashboard'" size="24" :color="selectedMenu === item.name ? '#173169' : '#ccc'" />
        <FooterListIcon v-if="item.name === 'listRegistredProducts'" size="22" :color="selectedMenu === 'farmFlowersGenera' ? '#173169' : '#ccc'" />
        <BoxIcon v-if="item.name === 'farmFlowerHarvest'" size="24" :color="selectedMenu === item.name ? '#173169' : '#ccc'" />
        <CarIcon v-if="item.name === 'farmOrderItems'" size="25" :color="selectedMenu === item.name ? '#173169' : '#ccc'" />
        <MessageIcon v-if="item.name === 'farmConversations'" size="22" :color="selectedMenu === item.name ? '#173169' : '#ccc'" />

        <span class="mt-1 text-sm"
          :style="{color: selectedMenu === item.name ? '#173169' : '#ccc', 'font-weight': selectedMenu === item.name ? '700' : '400'}"
        >{{ $t(item.name) }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import HomeIcon from "@/components/icons/HomeIcon.vue";
import BoxIcon from "@/components/icons/BoxIcon.vue";
import MessageIcon from "@/components/icons/MessageIcon.vue";
import CarIcon from "@/components/icons/CarIcon.vue";
import FooterListIcon from "@/components/icons/FooterListIcon.vue";

export default {
  components: { HomeIcon, BoxIcon, MessageIcon, CarIcon, FooterListIcon },
  data() {
    return {
      mobile: this.$vuetify.breakpoint.xs,
      menus: [
        { path: "/farm/dashboard", name: "farmDashboard", class: "ga-farm-footer-dashboard" },
        { path: "/farm/flower_genera", name: "listRegistredProducts", class: "ga-farm-footer-flower" },
        { path: "/farm/flower_harvests", name: "farmFlowerHarvest", class: "ga-farm-footer-harvests" },
        { path: "/farm/order_items", name: "farmOrderItems", class: "ga-farm-footer-order_items" },
        { path: "/farm/conversations", name: "farmConversations", class: "ga-farm-footer-conversations" }
      ],
      selectedMenu: "farmDashboard",
    };
  },
  watch: {
    $route(to) {
      this.selectedMenu = to.name;
    }
  },
  mounted() {
    this.selectedMenu = this.$route.name;
  },
  methods: {
    isActive(menu) {
      return this.selectedMenu === menu ? "#007e57" : "#cccccc";
    }
  }
};
</script>

<style scoped>
.footer {
  border: 1px solid #eeeeee;
  padding-right: 8px;
  padding-left: 20px;
}

.selected {
  color: #007e57;
}
</style>
