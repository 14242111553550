function unreadCount(state) {
  state.unreadCount = 0;
  state.listArr.forEach(id => {
    const c = state.list[id];
    state.unreadCount += c.unread_messages_count;
  });
}

export default {
  namespaced: true,
  state: {
    list: {},
    listArr: [],
    lastId: 1,
    unreadCount: 0
  },
  mutations: {
    setList(state, payload) {
      Object.keys(payload).forEach(x => {
        state[x] = payload[x];
      });
    },
    setLastId(state, payload) {
      state.lastId = payload.lastId;
    }
  },
  actions: {
    resetLastId({ commit, state }) {
      const newState = { ...state };

      newState.lastId = 1;

      commit("setLastId", newState);
    },
    async getConversations({ commit, state }, setBusy = true) {
      const { result } = await this._vm.$http("get", "/conversations", null, setBusy);
      const newState = { ...state };

      newState.list = {};
      newState.listArr = [];

      result.conversations.forEach(conversation => {
        newState.list[conversation.id] = {
          ...conversation
        };

        newState.listArr.push(conversation.id);
      });

      unreadCount(newState);

      commit("setList", newState);
    },
    async getMessages({ state, commit }, { conversationId, setBusy = true }) {
      const { result } = await this._vm.$http("get", `conversations/${conversationId}/messages?count=20&after_id=${state.lastId}`, null, setBusy);

      const newState = { ...state };

      if (!newState.list[conversationId]) {
        newState.list[conversationId] = { messages: [] };
      }

      if (!newState.list[conversationId].messages) {
        newState.list[conversationId].messages = [];
      }

      result.messages.forEach(msg => {
        if (!newState.list[conversationId].messages.find(x => x.id === msg.id)) {
          newState.list[conversationId].messages.push(msg);
          newState.list[conversationId].messages.sort((a, b) => a.id - b.id);
        }

        if (msg.id > state.lastId) newState.lastId = msg.id;
      });

      unreadCount(newState);

      commit("setList", newState);

      return true;
    },
    async postMessage({ commit, state }, { conversationId, body, image }) {
      const data = {
        message: { body }
      };

      if (image) data.image = { data: image };

      const res = await this._vm.$http("post", `conversations/${conversationId}/messages`, data);

      if (res.status === 201) {
        const newState = { ...state };

        if (!newState.list[conversationId].messages.find(x => x.id === res.result.message.id)) {
          newState.list[conversationId].messages.push(res.result.message);
        }
        newState.list[conversationId].messages.sort((a, b) => a.id - b.id);

        unreadCount(newState);

        commit("setList", newState);
      }

      return null;
    }
  },
  getters: {
    conversations: state => {
      const conversations = Object.keys(state.list).map(id => state.list[id]);
      if (!conversations.length) return [];
      return conversations.sort((a, b) => (new Date(a.last_message.created_at).getTime() > new Date(b.last_message.created_at).getTime() ? -1 : 1));
    },
    getConversationById: state => id => {
      if (!Object.keys(state.list).length) return null;
      const conversations = Object.keys(state.list).map(itemId => state.list[itemId]);
      return conversations.find(conversation => conversation.id === id);
    },
    unreadCount(state) {
      return state.unreadCount;
    },
    lastMessageBody(state) {
      return conversationId => state.list[conversationId].last_message.body;
    }
  }
};
