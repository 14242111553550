<template>
  <div class="menu pb-6">
    <div v-if="mobile" class="pa-3 d-flex align-center justify-end">
      <div @click="closeMobileMenu">
        <CloseIcon size="48" color="white" />
      </div>
    </div>

    <div v-for="item in menus" :key="item.name">
      <router-link :to="item.path" @click.native="closeMobileMenu" :class="item.class">
        <div :class="{selected: selectedMenu === item.name}">
          <v-list-item link>
            <v-list-item-icon class="mr-1 my-auto" v-if="!mobile" >
              <HomeIcon color="white" v-if="item.name === 'farmDashboard'" />
              <FlowerIcon color="white" v-if="item.name === 'farmFlowerHarvest'" />
              <ColumnsIcon color="white" v-if="item.name === 'listRegistredProducts'" />
              <BoxesIcon color="white" v-if="item.name === 'farmOrderItems'" />
              <ChartlineIcon color="white" v-if="['farmSaleStatement', 'farmReceipt'].includes(item.name)" />
              <MessageIcon color="white" v-if="item.name === 'farmConversations'" />
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="menu-title">{{ $t(item.name) }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action v-if="!mobile">
              <ChevronIcon color="white" size="30" />
            </v-list-item-action>
          </v-list-item>
        </div>
        <div v-if="mobile" class="line" />
      </router-link>
    </div>

    <div v-if="mobile">
      <v-container fluid>
        <v-row>
          <v-col sm="6">
            <v-btn class="mobile-menu-btn ga-farm-hamburger-settings" color="white" to="/farm/setting/account" outlined block @click.native="closeMobileMenu">
              {{ $t("settings") }}
            </v-btn>
          </v-col>
          <v-col sm="6">
             <v-btn class="mobile-menu-btn" color="white" @click="logout" outlined block>{{ $t("logout") }}</v-btn>
          </v-col>
        </v-row>
      </v-container>

      <div class="d-flex flex-column mt-1 ml-3">
        <a
          href="https://cavin.ooo/terms-1"
          class="menu-subitem mt-3 ml-3"
          target="_blank"
        >利用規約
        <v-icon color="white" small>mdi-open-in-new</v-icon>
        </a>
        <a
          href="https://cavin.ooo/privacypolicy"
          class="menu-subitem mt-3 ml-3"
          target="_blank"
        >プライバシーポリシー
        <v-icon color="white" small>mdi-open-in-new</v-icon>
        </a>
        <a
          href="https://cavin.ooo/commerce"
          class="menu-subitem mt-3 ml-3"
          target="_blank"
        >特定商取引法に基づく表記
        <v-icon color="white" small>mdi-open-in-new</v-icon>
        </a>
        <a
          href="https://cavin.ooo/about"
          class="menu-subitem mt-3 ml-3"
          target="_blank"
        >運営元<v-icon color="white" small>mdi-open-in-new</v-icon>
        </a>
         <a
          href="https://www.wantedly.com/companies/company_6193267"
          class="menu-subitem mt-3 ml-3"
          target="_blank"
        >採用情報<v-icon color="white" small>mdi-open-in-new</v-icon>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import HomeIcon from "@/components/icons/HomeIcon.vue";
import BoxesIcon from "@/components/icons/BoxesIcon.vue";
import ColumnsIcon from "@/components/icons/ColumnsIcon.vue";
import FlowerIcon from "@/components/icons/FlowerIcon.vue";
import MessageIcon from "@/components/icons/MessageIcon.vue";
import ChevronIcon from "@/components/icons/ChevronIcon.vue";
import ChartlineIcon from "@/components/icons/ChartlineIcon.vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";

export default {
  components: {
    HomeIcon,
    BoxesIcon,
    ColumnsIcon,
    FlowerIcon,
    MessageIcon,
    CloseIcon,
    ChevronIcon,
    ChartlineIcon
  },
  data() {
    return {
      mobile: this.$vuetify.breakpoint.xs,
      menus: [
        { path: "/farm/dashboard", name: "farmDashboard", class: "ga-farm-hamburger-dashboard" },
        { path: "/farm/flower_genera", name: "listRegistredProducts", class: "ga-farm-hamburger-flowers" },
        { path: "/farm/flower_harvests", name: "farmFlowerHarvest", class: "ga-farm-hamburger-harvests" },
        { path: "/farm/order_items", name: "farmOrderItems", class: "ga-farm-hamburger-order_items" },
        { path: "/farm/conversations", name: "farmConversations", class: "ga-farm-hamburger-conversations" },
        { path: "/farm/pdf_picker", name: "farmSaleStatement", class: "ga-farm-hamburger-pdf-picker" },
        { path: "/farm/shipping_results", name: "farmReceipt", class: "ga-farm-hamburger-receipt" }
      ],
      selectedMenu: ""
    };
  },
  watch: {
    $route(to) {
      this.selectedMenu = to.name;
    }
  },
  mounted() {
    this.selectedMenu = this.$route.name;
  },
  computed: {
    officialLineUrl() {
      return process.env.VUE_APP_X_OFFICIAL_LINE_URL;
    }
  },
  methods: {
    closeMobileMenu() {
      this.$store.dispatch("setMobileMenuOpen", false);
    },
    logout() {
      this.$store.dispatch("logout");
    }
  }
};
</script>

<style scoped>
.menu {
  background-color: #173169;
  height: 100vh;
  overflow-y: auto;
}

.selected {
  background: #315198;
}

.menu-title {
  padding: 10px;
  font: Bold 1rem Helvetica;
  color: #ffffff;
}

.line {
  height: 1px;
  background: #4A8FCF;
}

.menu-subitem {
  font-size: 13px;
  color: white;
}

.mobile-menu-btn {
  height: 53px !important;
}
</style>
