<template>
  <div class="mst-floating-button">
    <div class="button pointer" :style="{'background-color': color}" @click="handleClick">
      <v-icon large color="white">{{ icon }}</v-icon>
      <span class="mx-auto" v-if="labelPosition === 'inside'">{{ label }}</span>
    </div>
    <span class="mx-auto" :style="{color: color}" v-if="labelPosition === 'outside'">{{ label }}</span>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "blue"
    },
    icon: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    to: {
      type: String,
      default: ""
    },
    labelPosition: {
      type: String,
      validator: value => ["inside", "outside"].includes(value),
      default: "inside"
    },
  },
  methods: {
    handleClick() {
      this.$emit("click");
      if (this.to !== "") {
        this.$router.push(this.to);
      }
    }
  }
};
</script>

<style lang="scss">
.mst-floating-button {
  width: 70px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;

  .button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 68px;
    min-width: 68px;
    height: 68px;
    border-radius: 50%;
    border: 1px solid #eee;
    color: #fff;
    padding: 0;
    font-size: 10px;
  }
}
</style>
