<template>
  <!-- eslint-disable -->

  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14">
    <path
      :fill="color"
      d="M14.5,32H1.5A1.5,1.5,0,0,0,0,33.5v11A1.5,1.5,0,0,0,1.5,46h13A1.5,1.5,0,0,0,16,44.5v-11A1.5,1.5,0,0,0,14.5,32ZM7,44H2V36H7Zm7,0H9V36h5Z"
      transform="translate(0 -32)"
    />
  </svg>

  <!-- eslint-enable -->
</template>

<script>
export default {
  props: {
    color: String,
    size: String
  }
};
</script>

<style>
</style>
