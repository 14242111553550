<template>
  <div class="body" :style="{backgroundImage: `url(${require('@/assets/flower-background.png')})`}">
    <div class="backdrop" />
    <div class="content d-flex flex-column justify-center align-center">
      <img :src="require('@/assets/CAVIN_Logo_white.png')" class ="mt-3" :style="mobile ? 'width: 150px' : 'width: 300px'" />

      <span
        class="mt-3 welcome"
        :style="mobile ? 'font-size: 12px' : ''"
      >{{ $t("welcome_landpage") }}</span>

      <v-container fluid class="menu-container">
        <v-row>
          <v-col sm="6" cols="12" class="d-flex justify-sm-end justify-center align-center">
            <router-link to="/farm">
              <v-card
                :class="`pa-5 d-flex flex-column justify-center align-center ${mobile ? 'card-mobile' : 'card'}`"
              >
                <span class="card-title">{{ $t("click_here_farm") }}</span>

                <div class="pa-3">
                  <flower-icon color="#E38C00" class="flower-icon" size="3rem" />
                  <img :src="require('@/assets/farmer.png')" class="farmer-icon" />
                </div>
                <span
                  :class="`${mobile ? 'card-content-mobile' : 'card-content'}`"
                >{{ $t("flower_registration_pricing") }}</span>
              </v-card>
            </router-link>
          </v-col>

          <v-col sm="6" cols="12" class="d-flex justify-sm-start justify-center align-center">

            <router-link to="/shop">
              <v-card
                :class="`pa-5 d-flex flex-column justify-center align-center ${mobile ? 'card-mobile' : 'card'}`"
              >
                <span class="card-title">{{ $t("click_here_shop") }}</span>

                <div class="pa-3">
                  <flower-icon2 color="#007E57" class="flower-icon2" />
                  <img :src="require('@/assets/shop.png')" class="shop-icon" />
                </div>
                <span
                  :class="`${mobile ? 'card-content-mobile' : 'card-content'}`"
                >{{ $t("purchase_flowers_feedback") }}</span>
              </v-card>
            </router-link>
          </v-col>
          <v-col sm="6" cols="12" class="d-flex justify-sm-end justify-center align-center">
            <router-link to="/driver">
              <v-card
                :class="`pa-5 d-flex flex-column justify-center align-center ${mobile ? 'card-mobile' : 'card'}`"
              >
                <span class="card-title">{{ $t("click_here_driver") }}</span>

                <div class="pa-3">
                  <img :src="require('@/assets/driver.svg')" />
                </div>
                <span
                  :class="`${mobile ? 'card-content-mobile' : 'card-content'}`"
                >{{ $t("delivery_status_check") }}</span>
              </v-card>
            </router-link>
          </v-col>
        </v-row>
      </v-container>

      <div class="signup">
        {{ $t("signup") }}
        <router-link to="/users/area" class="link" style="color: white">{{ $t("here") }}</router-link>
      </div>

      <div class="for-admin">
        {{ $t("for_admin") }}
        <router-link to="/admin" class="link" style="color: white">{{ $t("here") }}</router-link>
      </div>

      <div class="d-flex flex-row mt-3"></div>
    </div>
  </div>
</template>

<script>
import FlowerIcon from "@/components/icons/FlowerIcon.vue";
import FlowerIcon2 from "@/components/icons/FlowerIcon2.vue";

export default {
  components: {
    FlowerIcon,
    FlowerIcon2
  },

  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs;
    }
  }
};
</script>

<style scoped>
.body {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100vw;
  height: 100vh;
}

.backdrop {
  background-color: #333333;
  opacity: 0.8;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 0;
}

.content {
  position: absolute;
  z-index: 1;
  width: 100vw;
  height: 100vh;
}

.welcome {
  font: Bold 1rem Helvetica;
  color: white;
}

.card {
  width: 23rem;
  height: 15rem;
}
.card-mobile {
  width: 15rem;
  height: 12rem;
}

.card-title {
  font: Bold 1rem Noto Sans CJK JP;
}

.card-content {
  font: 1rem Noto Sans CJK JP;
}
.card-content-mobile {
  font: 0.8rem Noto Sans CJK JP;
}

.flower-icon {
  margin-right: -8px;
}
.farmer-icon {
  width: 4.5rem;
  margin-left: -8px;
}

.flower-icon2 {
  width: 3.5rem;
  margin-right: -8px;
}
.shop-icon {
  width: 4.5rem;
  margin-left: -8px;
}

.signup, .for-admin {
  font: Bold 1rem Helvetica;
  text-align: left;
  color: #ffffff;
}

.link {
  text-decoration: underline;
}

.menu-container {
  max-height: 100vh;
  overflow-y: scroll;
  margin-top: 8px;
  margin-bottom: 8px;
}
</style>
