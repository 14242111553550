<template>
  <div class="pa-3 bg-back-gray my-2 rounded-8">
    <div class="d-flex justify-space-between align-center">
      <div class="font-weight-bold mb-1 text-overflow-dot color-black-500">
        {{ speciesName }} / {{ genusName }}
      </div>
      <div class="color-secondary font-weight-bold mb-1 text-xl no-wrap">
        {{ totalCount }}
        <span class="text-xs">{{ $t('volumn') }}</span>
      </div>
    </div>
    <div class="d-flex justify-space-between">
      <p class="color-white bg-color-menu px-2">{{ stemSize }}</p>
      <p>{{ totalPrice.toLocaleString() }}{{ $t('yen') }}</p>
    </div>
    <div class="d-flex justify-space-between">
      <span class="pointer d-flex align-center" @click="showTransactions = !showTransactions">
          {{ $t('view_by_transaction') }}
          <ArrowRightIcon v-if="!showTransactions" class="ml-2" size="16" color="#191D38"/>
          <ArrowDownIcon v-else class="ml-2" size="16" color="#191D38"/>
      </span>
      <!-- <button class="color-white bg-secondary px-4 py-1 rounded-4" @click="$emit('openShipModal')">{{ $t('mark_shipped') }}</button> -->
    </div>

    <div class="transaction-content pt-3 mt-3" v-if="showTransactions">
      <div class="mb-4" :key="index" v-for="(transaction, index) in transactions">
        <div class="d-flex justify-space-between">
          <span class="font-weight-bold text-lg color-black-500">{{ transaction.shop_name }}</span>
          <span class="color-secondary font-weight-bold text-xl">{{ transaction.subtotal_count }}<span class="text-sm">{{ $t('yen') }}</span></span>
        </div>
        <div class="d-flex justify-space-between align-center">
          <div class="color-gray-800">{{ $t('order') }} : {{ transaction.id }}</div>
          <div>＠{{ transaction.min_count }}{{ $t('volumn') }} × {{ transaction.lot_count }}{{ $t('lot') }} × {{ transaction.unit_price }}{{ $t('yen') }}
            = {{ transaction.subtotal_harvest_price_without_tax }}{{ $t('yen') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowRightIcon from "@/components/icons/ArrowRightIcon.vue";
import ArrowDownIcon from "@/components/icons/ArrowDownIcon.vue";

export default {
  components: { ArrowRightIcon, ArrowDownIcon },
  props: {
    speciesName: String,
    genusName: String,
    stemSize: String,
    totalCount: Number,
    totalPrice: Number,
    transactions: Array,
  },
  data() {
    return {
      mobile: this.$vuetify.breakpoint.xs,
      showTransactions: false,
    };
  },
  methods: {
    toggle(index) {
      this.opened = this.opened === index ? null : index;
    }
  },
};
</script>

<style scoped>
.transaction-content {
  border-top: 1px solid #D7D7D7;
}
.rounded-4 {
  border-radius: 4px;
}
</style>
