<template>
  <div class="main">

    <div v-if="mobile">
      <div v-if="isMenuOpen" class="mobile-menu">
        <Menu />
      </div>

      <div v-else class="d-flex flex-column">
        <div ref="header">
          <Header />
        </div>

        <div v-if="initialized" class="mobile-body">
          <router-view />
          <div v-if="route!=='farmConversationsDetail'" style="height:120px" />
        </div>

        <div class="footer">
          <mobile-footer />
        </div>
      </div>
    </div>

    <div v-else class style="width: 100%; height: 100%; border: 1px solid green">
      <div class="header">
        <Header />
      </div>

      <div class="body d-flex">
        <div>
          <Menu />
        </div>
        <div v-if="initialized" class="body-content d-flex flex-column">
          <router-view class="flex-grow-1" />
          <Footer origin="farm" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/farm/Header.vue";
import Footer from "@/components/shared/Footer.vue";
import MobileFooter from "@/components/farm/MobileFooter.vue";
import Menu from "@/components/farm/Menu.vue";
import getDefaultLocation from "@/util/getDefaultLocation";

export default {
  components: { Header, Menu, MobileFooter, Footer },
  data() {
    return {
      mobile: this.$vuetify.breakpoint.xs,
      axiosOptions: {},
      user: {},
      initialized: false
    };
  },
  async mounted() {
    const res = await this.$api.get("users/profile", this.axiosOptions);

    this.$store.commit("setData", {
      agreee_terms: res.result.user.agreee_terms,
      is_confirmed: res.result.user.is_confirmed,
    });
    const obj = {
      user: res.result.user,
      defaultLocation: getDefaultLocation(res.result.user.farm.locations)
    };

    this.$store.commit("setProfile", obj);

    if (!res.result.user.is_confirmed) {
      this.$router.push({
        path: "/users/wait_confirmed",
        query: {
          type: res.result.user.user_type,
          email: res.result.user.email
        }
      });
    }

    this.initialized = true;
  },
  computed: {
    isMenuOpen() {
      return this.$store.state.isMobileMenuOpen;
    },
    route() {
      return this.$route.name;
    }
  }
};
</script>

<style scoped>
.main {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.body {
  background-color: #f5f5f5;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.mobile-body {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 140px);
}

.mobile-menu {
  overflow: auto;
  width: 100%;
  height: 100%;

}

.body-content {
  overflow-y: auto;
  overflow-x: hidden;
  width: calc(100vw - 230px);
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;
}
</style>
