<template>
  <div>
    <div class="product-list">
      <div class="d-flex image-container">
        <router-link :to="`/shop/products/${flowerHarvest.id}`" class="image-link">
          <div class="image">
            <ProductImage :src="image" :not_in_stock="(flowerHarvest.stock_count == 0)" list />
          </div>
        </router-link>
        <div class="flower-content">
          <div>
            <div class="d-flex flower-detail">
              <router-link :to="`/shop/products/${flowerHarvest.id}`">
                <div>
                  <div class="flower-farm-name">{{ flowerHarvest.farm_name }} </div>
                  <div class="flower-specie-name">{{ flowerHarvest.flower_specie_name }}</div>
                  <div class="flower-genus-name">{{ flowerHarvest.flower_genus_name }}</div>
                  <div class="flower-stem-size" v-if="flowerHarvest.stem_size != null">
                    <MstTextTag :text="`${flowerHarvest.stem_size}cm`" size="sm" />
                </div>
                </div>
              </router-link>
              <div class="cartin align-start">
                <div class="counter mr-1">
                  <Counter v-if="isInCart" :min="0" :max="maxLotCount" v-model="valueModel" />
                </div>
                <div>
                  <v-btn v-if="!isInCart" :disabled="flowerHarvest.stock_count === 0" class="cartin_button" @click="addDraftOrder" fab icon v-on:click.prevent>
                    <span class="cartin_button_inner">
                      <v-icon class="icon" color="#375293">mdi-plus</v-icon>
                    </span>
                  </v-btn>
                  <v-btn v-else class="go_cart_button" fab icon to="/shop/cart">
                    <span class="go_cart_button_inner">
                      <v-icon class="icon" color="white">mdi-cart-outline</v-icon>
                    </span>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="flower-price">
            <span class="flower-min-count" v-html="$t('min_count_per_unit')(flowerHarvest.min_count)" />
            <span class="flower-price-unit">1{{ $t("volumn") }}</span>
            <span class="flower-sales-price-without-delivery-fee">{{ flowerHarvest.sales_price_without_delivery_fee }}</span>
            <span class="flower-price-unit">{{ $t("yen") }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductImage from "@/components/shop/products/ProductImage.vue";
import Counter from "@/components/shared/v2/Counter.vue";
import { MstTextTag } from "@/components/master";

export const sizes = {
  "x-small": "10px",
  small: "12px",
};

export default {
  components: {
    ProductImage,
    Counter,
    MstTextTag,
  },
  props: {
    flowerHarvest: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      timeoutId: -1,
    };
  },
  computed: {
    image() {
      return this.flowerHarvest.flower_image_urls[0];
    },
    isInCart() {
      return this.$store.getters["orders/harvestIsInCart"](this.flowerHarvest.id);
    },
    normalOrderItem() {
      return this.$store.getters["orders/normalOrderItem"](this.flowerHarvest.id);
    },
    minLot() { return this.normalOrderItem.harvest_min_count; },
    maxLotCount() {
      return this.normalOrderItem.flower_harvest.stock_count / this.normalOrderItem.harvest_min_count;
    },
    valueModel: {
      get() {
        return this.normalOrderItem.lot_count;
      },
      set(value) {
        this.normalOrderItem.lot_count = value;
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(async () => {
          if (value === 0) {
            this.$store.dispatch("orders/deleteOrderItem", this.normalOrderItem);
          } else {
            await this.editCountHandler(value);
            this.$store.dispatch("orders/getDraftOrder");
          }
        }, 800);
      }
    },
  },
  methods: {
    async addDraftOrder() {
      await this.post("order_items", this.createRequestBody());
    },

    createRequestBody() {
      return {
        order_items: [{
          lot_count: 1,
          flower_harvest_id: this.flowerHarvest.id,
        }]
      };
    },
    async post(url = "/order_items", data) {
      class OrderItemCreateError extends Error {
        constructor(status, message) {
          super(message);
          this.status = status;
          this.name = "OrderItemCreateError";
        }
      }

      try {
        const resOrderItem = await this.$http("post", url, data);
        if (resOrderItem.status === 422) throw new OrderItemCreateError(422, "Failed to adding item into cart");
        if (resOrderItem.status === 521) throw new OrderItemCreateError(521, "Failed to adding item into cart");

        await this.$store.dispatch("orders/notifyAddCart");

        this.deliveryDate = "";
        this.harvestId = null;
      } catch (e) {
        if (e.status === 521) {
          this.$store.dispatch("orders/notifyUnbuyableAreaError");
        } else {
          this.$store.dispatch("orders/notifyAddToCartError");
        }
      } finally {
        await this.$store.dispatch("orders/getDraftOrder");
      }
    },
    async editCountHandler(newLotCount) {
      class OrderItemPutError extends Error {
        constructor(status, message) {
          super(message);
          this.status = status;
          this.name = "OrderItemPutError";
        }
      }

      const data = {
        id: this.normalOrderItem.id,
        lotCount: newLotCount,
        subtotalCount: newLotCount * this.minLot,
        flowerHarvestId: this.normalOrderItem.flower_harvest.id,
      };
      try {
        const res_status = await this.$store.dispatch("orders/changeCount", data);
        if (res_status === 521) throw new OrderItemPutError(521, "Failed to change count");
        if (res_status !== 200) throw new Error("Failed to change count");
        this.$store.dispatch("orders/notifyChangeCount");
      } catch (e) {
        if (e.status === 521) {
          this.$store.dispatch("orders/notifyUnbuyableAreaError");
        } else {
          this.$store.dispatch("orders/notifyChangeCountError");
        }
        console.log(e);
      }
    },
  }
};
</script>

<style lang="scss" scoped>
$secondary-color: #375293;

.product-list {
  display: block;
  background-color: #fff;
  border-top: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
}

.image-container {
  gap: 0 11px;
  padding: 10px;
}

.image-link {
  flex-shrink: 0;
  display: inline-block;
  width: 60px;
}

.image {
  width: 60px;
}

.flower-content {
  width: 100%;
}

.flower-price {
  display: flex;
  justify-content: right;
  align-items: baseline;
}

.flower-farm-name {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #999999;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.flower-specie-name {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #111111;
  margin-bottom: 5px;
}

.flower-genus-name {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #111111;
  margin-bottom: 7px;
}

.flower-stem-size {
  margin-bottom: 7px;
}

.flower-detail {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #EEEEEE;
  margin-bottom: 5px;
}

.flower-min-count {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #555555;
  margin-right: 9px;
}

.flower-price-unit {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #DD5055;
  margin-right: 3px;
}

.flower-sales-price-without-delivery-fee {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #DD5055;
}

.cartin {
  display: flex;
  justify-content: center;
  align-items: center !important;
  margin-left: 12px;
  margin-right: -4px;
}

.cartin_button {
  position: relative;
  right: 0;
  border-radius: 50%;
  width: 40px !important;
  height: 40px !important;
}

.cartin_button_inner {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid #375293;
  width: 20px;
  height: 20px;

  .icon {
    font-size: 16px;
  }
}

.go_cart_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px !important;
  height: 40px !important;
}

.go_cart_button_inner {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background: #375293;

  .icon {
    font-size: 12px;
  }
}
</style>
