<template>
  <div class="harvest-list">
    <v-btn class="btn-white ml-4 my-2" to="/farm/shipping_results">{{ $t("back") }}</v-btn>
    <p class="title mb-2">出荷日: {{ this.deliveryDateFormat() }}</p>

    <v-list class="pa-0">
      <template v-for="(harvest, index) in flowerHarvests">
        <v-list-item :key="index" class="harvest-item">
          <v-list-item-avatar v-if="harvest.images.length">
            <v-img :src="harvest.images[0].image_url" />
          </v-list-item-avatar>
          <v-list-item-content>
            <div class="genus-name"><span class="label">品目: </span>{{ harvest.flower_genus_name }}</div>
            <div class="specie-name"><span class="label">品種: </span>{{ harvest.flower_specie_name }}</div>
            <div class="d-flex justify-space-between">
              <div class="stem-size"><span class="label">全長: </span>{{ harvest.stem_size }}cm</div>
              <div class="total-count"><span class="label">個数: </span>{{ harvest.shipping_total_count }}個</div>
            </div>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>

    <p class="title total-price my-5">合計金額: {{ currency(this.totalPrice) }}</p>

    <div class="btn-area my-5">
      <v-btn
        :disabled="!canShowReceipt"
        @click="toPdf(receipt_id)"
        class="pdf-btn mb-5"
        color="#00796B"
        style="color: white;"
      >受領証を表示</v-btn>

      <v-btn
        class="contact-btn"
        color="#DFDFDF"
        :href="officialLineUrl"
        target="_blank"
      >問い合わせ</v-btn>
    </div>
  </div>
</template>

<script>
import { formatDate, currency } from "@/plugins/i18n";

export default {
  data() {
    return {
      deliveryDate: "",
      flowerHarvests: [],
      totalPrice: 0,
      canShowReceipt: false,
      weekArray: [
        this.$t("short_sunday"),
        this.$t("short_monday"),
        this.$t("short_tuesday"),
        this.$t("short_wednesday"),
        this.$t("short_thursday"),
        this.$t("short_friday"),
        this.$t("short_saturday")
      ]
    };
  },

  async created() {
    const { receipt_id } = this.$route.query;

    this.receipt_id = receipt_id;
    this.getShippingResult();
  },
  computed: {
    officialLineUrl() {
      return process.env.VUE_APP_X_OFFICIAL_LINE_URL;
    }
  },
  methods: {
    async getShippingResult() {
      const res = await this.$http2("get", `farm/farm_receipts/receipt_detail?id=${this.receipt_id}`);
      this.flowerHarvests = res.result.flower_harvests;
      this.deliveryDate = res.result.harvest_date;
      this.totalPrice = res.result.total_harvest_price_without_tax;
      this.canShowReceipt = res.result.can_show_receipt;
    },
    format(date) {
      const d = new Date(date);
      return formatDate(d, "yyyy年m月d日");
    },
    deliveryDateFormat() {
      const date = new Date(this.deliveryDate);
      return `${this.format(this.deliveryDate)}(${this.weekArray[date.getDay()]})`;
    },
    async toPdf(receipt_id) {
      await this.$api2.get(`/farm/farm_receipts/farm_receipt_pdf.pdf?id=${receipt_id}`, { responseType: "blob" }).then(response => {
        const blob = new Blob([response], { type: "application/pdf" });
        const objectUrl = URL.createObjectURL(blob);
        this.pdfsrc = objectUrl;
        window.location.href = this.pdfsrc;
      });
    },
    currency
  }
};
</script>

<style lang="scss" scoped>
.harvest-list {
  width: 100%;
}

.harvest-item {
  border-bottom: 1px solid #bbb;
}
.harvest-item:last-child {
  border-bottom: none;
}

.title {
  width: 90%;
  margin: 0 auto;
}

.label {
  font-size: 14px;
  font-weight: normal;
}

.specie-name {
  font-size: 18px;
  font-weight: bold;
}

.genus-name {
  font-size: 16px;
}

.stem-size,
.total-count {
  color: #666;
  font-size: 16px;
}

.total-price {
  width: 90%;
  margin: 0 auto;
  border-bottom: 1px solid #bbb;
}

.btn-area {
  text-align: center;
}

.pdf-btn,
.contact-btn {
  width: 90%;
}
</style>
