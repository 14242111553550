import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overflow: auto"},[_c('div',{staticClass:"d-flex justify-space align-center"},[_c(VBtn,{staticClass:"ma-2",attrs:{"icon":"","large":""},on:{"click":function($event){return _vm.$emit('back')}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c('h3',{staticClass:"ma-5"},[_vm._v(_vm._s(_vm.$d(_vm.date, 'normalDate'))+" "),_c('span',{staticClass:"ml-2 mr-2"},[_vm._v("("+_vm._s(_vm.$d(_vm.date, 'day'))+")")]),_vm._v(" "+_vm._s(_vm.$t("delivery"))+" - "+_vm._s(_vm.category == null ? _vm.$t("all_1") : _vm.category.name))]),_c(VDivider),_c('div',{staticStyle:{"overflow":"auto","height":"73vh"}},[_c(VList,{staticClass:"pt-0"},[_c(VListItem,{staticClass:"pt-2 pb-2 pl-5 pr-5"},[_c(VListItemContent,[_vm._v(" "+_vm._s(_vm.$t("search"))+" - "+_vm._s(_vm.$t("choose_farm"))+" ")])],1),_c(VDivider),_vm._l((_vm.availableFarms),function(item){return _c('div',{key:item.id},[_c(VListItem,{staticClass:"pt-2 pb-2 pl-5 pr-5",on:{"click":function($event){return _vm.$emit('click', {key: 'farmIds', value: item.id})}}},[_c(VListItemContent,{staticClass:"pl-3",class:{'filter-mob-font': _vm.farmIds.includes(item.id)}},[_c('div',[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(item.name))])])]),_c(VListItemAction,[(_vm.farmIds.includes(item.id))?_c(VIcon,{staticClass:"selected-item-icon"},[_vm._v("mdi-check")]):_vm._e()],1)],1),_c(VDivider)],1)})],2)],1),_c('div',{staticClass:"d-flex justify-space-around mb-4"},[_c(VBtn,{attrs:{"color":"#03A9F4","outlined":""},on:{"click":function($event){return _vm.$emit('clearFilterBy', {key: 'farmIds'})}}},[_vm._v(_vm._s(_vm.$t("clear")))]),_c(VBtn,{staticClass:"blue",staticStyle:{"width":"50%"},on:{"click":function($event){return _vm.$emit('back')}}},[_vm._v(_vm._s(_vm.$t("confirm")))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }