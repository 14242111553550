<template>
  <div class="search-div">
    <input class="search-input" :disabled="!genusId || readOnly" @input="onChangeSearch" :value="searchString" />
    <div class="search-result" v-if="searchString !== '' && onSearch" v-on:click.self="hideSearch" ref="searchRef">
      <div class="mx-4 pa-2" @click="onSpeciesItem(item.name, item.id)" v-for="item in searchResult" :key="item.id">
        <div class="my-1 d-flex justify-space-between align-center">
          <span>{{ item.name }}</span>
        </div>
      </div>
      <div v-if="searchResult.length === 0">
        <div class="mx-4 pa-2">{{ $t('not_found_species') }}</div>
      </div>
      <Form :genus-id="genusId" :edit="editId" @finish="onAdded" />
    </div>
  </div>
</template>

<script>
import CustomDialog from "@/components/shared/CustomDialog.vue";
import Form from "@/components/farm/flowers/specie/Form.vue";

export default {
  components: { CustomDialog, Form },
  props: {
    specieId: {
      type: Number,
      default: null
    },
    genusId: {
      type: Number,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: null
    },
  },
  data() {
    return {
      name: null,
      editId: null,
      searchString: "",
      searchResult: [],
      onSearch: false
    };
  },
  created() {
    if (this.specieId) {
      this.searchString = this.specieName();
    }
  },
  methods: {
    selectHandler(id) {
      this.$emit("select", id);
    },
    editHandler(id) {
      this.editId = id;
    },
    onAdded(id) {
      this.$emit("select", id);
    },
    hideSearch() {
      this.onSearch = false;
    },
    specieName() {
      return this.$store.getters["flowerSpecie/name"](this.specieId);
    },
    onChangeSearch(event) {
      this.onSearch = true;
      this.searchString = event.target.value;
      this.searchResult = this.$store.state.flowerSpecie.options[this.genusId].filter(option => option.name.includes(this.searchString));
      this.selectHandler(null);
    },
    onSpeciesItem(name, id) {
      this.searchString = name;
      this.searchResult = [];
      this.onSearch = false;
      this.$emit("select", id);
    }
  },
  mounted() {
    document.addEventListener("click", e => {
      if (this.$refs.searchRef && !this.$refs.searchRef.contains(e.target)) {
        this.searchString = "";
        this.hideSearch();
      }
    });
  }
};
</script>

<style scoped>
.search-input {
  border:  1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  height: 33px;
  padding: 4px 8px 4px 8px;
  width: 100%;
}

.search-button:focus {
  outline: none;
}
.search-result {
  width: 100%;
  position: absolute;
  background-color: white;
  overflow: auto !important;
  max-height: 250px;
  z-index: 99;
}
.search-div {
  position: relative;
}
input:disabled {
   background: #f1f1f1;
   cursor: not-allowed !important;
   color: #999;
 }

</style>
