<template>

  <h5 class="text-center mt-8">
    {{ $t('area_required') }} [
    <a :href="officialLineUrl" target="_blank" rel="noopener">{{ $t('here') }}</a> ]
  </h5>

</template>

<script>

export default {
  computed: {
    officialLineUrl() {
      return process.env.VUE_APP_X_OFFICIAL_LINE_URL;
    }
  },

};

</script>

<style lang="scss">

</style>
