<template>
  <div class="page-root">
    <div class="header">
      <img class="logo" src="@/assets/CAVIN_Logo_black.png" height="41" />
    </div>

    <div class="content">
      <div class="card">
        <div class="card-title">まもなく<br>利用開始できます。</div>
        <div class="message">
          1営業日を目安にご利用開始のメールをお送りいたします。<br>
          <br>
          今しばらくお待ちください。
        </div>
        <div class="message">
          また、CAVIN公式LINEアカウントへの登録をよろしくお願いいたします。<br>
          <br>
          <a :href="officialLineUrl">{{ officialLineUrl }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    officialLineUrl() {
      return process.env.VUE_APP_X_OFFICIAL_LINE_URL;
    }
  }
};
</script>

<style lang="scss" scoped>
.page-root {
  background-color: #eee;
}

.header {
  margin-bottom: 40px;

  .logo {
    display: block;
    margin: 0 auto;
  }
}

.content {
  max-width: 720px;
  margin: 0 auto;
}

.card {
  padding: 30px;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  color: #333;
}

.card-title {
  font-size: 26px;
  font-weight: bold;
  text-align: center;
}

.message {
  text-align: center;
  margin-top: 30px;
  color: #555;
  font-size: 16px;
}
</style>
