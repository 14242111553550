<template>
  <div class="collections">
    <CollectionList v-if="collections.length" :collections="collections" @click="handleClick" />
    <div v-else-if="initialized" class="collections__empty">
      <p class="collections__emptyMessage">現在コレクションは配信されていません。</p>
    </div>

    <HarvestSheet v-model="isVisibleFlowerHarvests" :flower-harvests="flowerHarvests" />
  </div>
</template>

<script>
import { CollectionList, HarvestSheet } from "@/components/domains/collections";

export default {
  components: { CollectionList, HarvestSheet },
  data() {
    return {
      initialized: false,
      isVisibleFlowerHarvests: false,
      flowerHarvests: [],
    };
  },
  async created() {
    await this.$store.dispatch("collections/fetchCollections");
    this.initialized = true;
  },
  computed: {
    collections() {
      return this.$store.getters["collections/collections"];
    },
  },
  methods: {
    async handleClick(id) {
      this.flowerHarvests = await this.getFlowerHarvests(id);
      this.isVisibleFlowerHarvests = true;
    },
    async getFlowerHarvests(flowerId) {
      const { result } = await this.$http2("get", `shop/flower_harvests/search?flower_id=${flowerId}`);
      return result.flower_harvests;
    },
  },
};
</script>

<style lang="scss" scoped>
.collections {
  margin: 0 auto;
  max-width: 500px;
  padding: 45px 15px 44px;
}

.collections__emptyMessage {
  margin-bottom: 0;
  font-size: 14px;
  text-align: center;
}
</style>
