<template>
  <div class="pa-4 bg-back-gray my-2 rounded-8">
    <div class="d-flex justify-space-between align-center">
      <div class="mb-1 text-overflow-dot">
        <span class="color-gray-900">{{ shopName }}</span><br/>
        <span class="text-xl color-secondary font-weight-bold color-black-500">{{ speciesName }} / {{ genusName }}</span>
      </div>
      <button class="no-picked-up-btn">{{ $t('order_status_' + status) }}</button>
    </div>
    <span class="color-white bg-color-menu px-2 mb-0">{{ stemSize }}</span>
    <div class="d-flex justify-end align-end color-secondary font-weight-bold text-xl">
      {{ totalCount }}
      <span class="text-xs">{{ $t('volumn') }}</span>
    </div>
    <div class="d-flex justify-space-between align-center">
      <div class="color-gray-800 mb-1">{{ $t('order') }} : {{ id }}</div>
      <div>＠{{ harvestMinCount }}{{ $t('volumn') }} × {{ lotCount }}{{ $t('lot') }} × {{ price }}{{ $t('yen') }} = {{ totalPrice.toLocaleString() }}{{ $t('yen') }} </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: Number,
    speciesName: String,
    genusName: String,
    stemSize: String,
    totalCount: Number,
    totalPrice: Number,
    price: Number,
    lotCount: Number,
    harvestMinCount: Number,
    shopName: String,
    status: String,
  },
  data() {
    return {
      mobile: this.$vuetify.breakpoint.xs
    };
  }
};
</script>

<style scoped>
.no-picked-up-btn {
  background-color: white;
  color: #173169;
  border: 1px solid #173169;
  padding: 6px 16px 6px 16px;
  border-radius: 100px;
  font-size: 14px;
  white-space: nowrap;
}
.rounded-4 {
  border-radius: 4px;
}
</style>
