<template>
  <div class="drawer-root">
    <div class="toggle d-flex align-center flex-column justify-center" @click="open">
      <MenuIcon size="30" color="black" />
    </div>
    <div class="content bg-color-menu" :class="{ opened }">
      <div class="header">
        <CloseIcon size="32" class="close ml-auto" @click.native="close" />
      </div>
      <div class="menu-list" @click="close">
        <router-link class="menu d-flex align-center" to="/shop/products">
          {{ $t("shopProducts") }}
          <ChevronIcon class="ml-auto" size="24" />
        </router-link>

        <router-link class="menu d-flex align-center ga-shop-hamburger-pdf_picker" to="/shop/pdf_picker">
          {{ $t("shop_invoice") }}
          <ChevronIcon class="ml-auto" size="24" />
        </router-link>

        <router-link class="menu d-flex align-center" to="/shop/orders">
        {{ $t("shopOrders") }}
          <ChevronIcon class="ml-auto" size="24" />
        </router-link>

        <router-link class="menu d-flex align-center" to="/shop/collections">
        {{ $t("shopCollections") }}
          <v-chip small class="ml-2" color="white">新機能</v-chip>
          <ChevronIcon class="ml-auto" size="24" />
        </router-link>

        <router-link class="menu d-flex align-center" to="/shop/conversations">
          メッセージ
          <ChevronIcon class="ml-auto" size="24" />
        </router-link>
      </div>

      <div class="buttons" @click="close">
        <router-link class="button d-flex align-center justify-center ga-shop-hamburger-settings" to="/shop/settings">{{ $t("settings") }}</router-link>

        <a class="button d-flex align-center justify-center" @click="logout">{{ $t("logout") }}</a>

        <a class="button line_support d-flex align-center flex-row justify-center ga-shop-hamburger-line"
          :href="officialLineUrl"
          x-large
          block
          outline>
          <img class="logo mr-3" src="@/assets/line.png" height="30" />
          <div>{{ $t("line_support") }}</div>
        </a>

        <router-link
          class="button cart d-flex align-center flex-row justify-center ga-shop-hamburger-cart"
          to="/shop/cart"
        ><CartOutlineIcon color="#375293" class="icon" /><span class="ml-3 color-primary">カートを見る<v-badge class="ml-2" color="red" :content="`${draftCount}`" /></span></router-link>
      </div>

      <div class="links mb-5">
        <a class="link" href="https://cavin.ooo/terms-1" target="_blank">利用規約<v-icon color="white" small>mdi-open-in-new</v-icon></a>
        <a class="link" href="https://cavin.ooo/privacypolicy" target="_blank">プライバシーポリシー<v-icon color="white" small>mdi-open-in-new</v-icon></a>
        <a class="link" href="https://cavin.ooo/commerce" target="_blank">特定商取引法に基づく表記<v-icon color="white" small>mdi-open-in-new</v-icon></a>
        <a class="link" href="https://cavin.ooo/about" target="_blank">運営元<v-icon color="white" small>mdi-open-in-new</v-icon></a>
        <a class="link" href="https://www.wantedly.com/companies/company_6193267" target="_blank">採用情報<v-icon color="white" small>mdi-open-in-new</v-icon></a>
      </div>
    </div>
    <div class="bg" :class="{ opened }" @click="close" />
  </div>
</template>

<script>
import MenuIcon from "@/components/icons/MenuIcon.vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";
import ChevronIcon from "@/components/icons/ChevronIcon.vue";
import CartOutlineIcon from "@/components/icons/CartOutlineIcon.vue";

export default {
  components: {
    MenuIcon,
    CloseIcon,
    ChevronIcon,
    CartOutlineIcon
  },

  data() {
    return {
      opened: false
    };
  },

  computed: {
    draftCount() {
      return this.$store.getters["orders/draftCount"];
    },
    officialLineUrl() {
      return process.env.VUE_APP_X_OFFICIAL_LINE_URL;
    }
  },

  methods: {
    open() {
      this.opened = true;
    },
    close() {
      this.opened = false;
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/");
    }
  }
};
</script>

<style lang="scss" scoped>
.drawer-root {
  color: #fff;
  fill: currentColor;
}

.toggle {
  height: 100%;
  width: 70px;
  cursor: pointer;
}

.content {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  box-shadow: 0px 3px 6px #0000001a;
  z-index: 9999;
  transform: translateX(100%);
  transition: transform 0.2s;
  overflow: auto;

  &.opened {
    transform: translateX(0%);
  }
}

.header {
  padding: 16px;
}

.close {
  display: block;
  cursor: pointer;
}

.menu-list {
  border-top: 1px dashed #ffffffcc;
}

.menu {
  border-bottom: 1px dashed #ffffffcc;
  height: 50px;
  padding: 0 12px 0 24px;
  font-size: 16px;
  font-weight: bold;
  color: currentColor;
  fill: currentColor;
}

.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  padding: 20px 25px;
}

.button {
  height: 52px;
  font-size: 16px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  cursor: pointer;

  &.line_support {
    grid-column: span 2;
    position: relative;

    .logo {
      position: absolute;
      left: 7px;
    }
  }

  &.cart {
    grid-column: span 2;
    background-color: #fff;
    font-weight: bold;
    position: relative;

    .icon {
      position: absolute;
      left: 7px;
    }

  }
}

.links {
  padding: 0 25px;
}

.link {
  display: block;
  width: max-content;
  color: #fff;
  font-size: 13px;

  & + & {
    margin-top: 20px;
  }
}

.bg {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  background-color: #000;
  opacity: 0;
  z-index: 9998;
  pointer-events: none;
  transition: opacity 0.2s;

  &.opened {
    pointer-events: all;
    opacity: 0.5;
  }
}
.menu_label {
  font-size: 10px;
}
</style>
