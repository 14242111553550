<template>
  <SettingLayout :active-index="1" :is-edit.sync="isEdit" @save="save">

    <div v-if="mobile">
      <div class="mt-4">{{ $t("external_app") }}</div>
      <div class="d-flex justify-space-between align-center mt-4">
        <img
          src="../../assets/line.png"
          :style="`width: 55px; ${!user.line_linked && 'opacity: 0.3'}`"
        />
        <div>
          <div>{{ $t("line_app") }}</div>
          <div style="color: #BDBDBD;">{{ $t("click_for_details") }}</div>
        </div>
        <div v-if="user.line_linked">
          <v-btn class="line-linked" disabled>{{ $t("linked") }}</v-btn>
        </div>
        <div v-else>
          <v-btn
            class="green"
            style="width: 100px"
            :href="lineUrl"
            target="_blank"
          >{{ $t("link_acc") }}</v-btn>
        </div>
      </div>
    </div>
  </SettingLayout>
</template>

<script>
import SettingLayout from "@/components/farm/setting/SettingLayout.vue";

export default {
  components: { SettingLayout },

  data() {
    return {
      isEdit: false,
      name: "",
      mail: "",
      tel: "",
      currentPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
      user: { farm: {} }
    };
  },

  async mounted() {
    const { result } = await this.$http("get", "/users/profile");
    this.user = result.user;
  },

  methods: {
    save() {
      this.isEdit = false;
    }
  },

  computed: {
    lineUrl() {
      return process.env.VUE_APP_X_LINE_URL;
    },
    mobile() {
      return this.$vuetify.breakpoint.xs;
    }
  }
};
</script>

<style lang="scss" scoped>
.label {
  display: block;
  font-size: 12px;
  color: #777;

  &:not(:first-child) {
    margin-top: 16px;
  }
}

.text {
  padding: 13px 10px;
}
</style>
