<template>
  <article class="collection">
    <header class="collection__header">
      <div class="collection__back">
        <router-link to="/shop/collections" class="collection__backLink">
          <MstIcon type="chevron-left" size="24px" class="collection__backIcon" />
          戻る
        </router-link>
      </div>
      <div class="collection__mainVisualWrapper">
        <img v-if="collection.image" :src="collection.image" alt="" class="collection__mainVisual" />
      </div>
      <div class="collection__text">
        <h1 class="collection__title">{{ collection.title }}</h1>
        <p class="collection__description">{{ collection.description }}</p>
      </div>
    </header>

    <div class="collection__sections">
      <CollectionSections :sections="collection.sections" :flower-harvests="flowerHarvests" @click="handleClick" />

      <p class="collection__term color-gray-700 text-xs">コレクション掲載期間：{{ termString }}</p>
    </div>

    <aside class="collection__others">
      <h2 class="collection__othersTitle">他のコレクション</h2>
      <OtherCollections :collections="otherCollections" />
    </aside>

    <HarvestSheet v-model="isVisibleFlowerHarvests" :flower-harvests="flowerHarvests" />
  </article>
</template>

<script>
import { MstIcon } from "@/components/master";
import { CollectionSections, OtherCollections, HarvestSheet } from "@/components/domains/collections";

export default {
  components: { MstIcon, CollectionSections, OtherCollections, HarvestSheet },
  data() {
    return {
      collection: {
        title: "",
        description: "",
        image: "",
        startDate: null,
        endDate: null,
        sections: [],
      },
      flowerHarvests: [],
      initialized: false,
      isVisibleFlowerHarvests: false,
    };
  },
  computed: {
    termString() {
      const startDateString = this.$date.dateJp(this.collection.startDate, false);
      const endDateString = this.$date.dateJp(this.collection.endDate, false);
      return `${startDateString}〜${endDateString}`;
    },
    otherCollections() {
      return this.$store.getters["collections/collections"]
        .map(collection => ({
          ...collection,
        }))
        .filter(collection => collection.id !== Number(this.$route.params.collectionId));
    },
  },
  watch: {
    $route: {
      deep: true,
      async handler(after, before) {
        if (after.params.collectionId !== before.params.collectionId) {
          this.initialized = false;
          await this.initialize();
        }
      },
    },
  },
  async created() {
    await this.initialize();
  },
  methods: {
    async initialize() {
      await this.getCollection();
      await this.$store.dispatch("collections/fetchCollections");
      this.initialized = true;
    },
    async handleClick(flowerId) {
      this.flowerHarvests = await this.getFlowerHarvests(flowerId);
      this.isVisibleFlowerHarvests = true;
    },
    mapSection(section) {
      return {
        id: section.id,
        image: section.image,
        order: section.order,
        flowers: section.collection_section_flowers.map(sectionFlower => sectionFlower.flower),
      };
    },
    async getCollection() {
      const { result } = await this.$http2("get", `shop/collections/${this.$route.params.collectionId}`);
      this.collection.title = result.collection.title;
      this.collection.image = result.collection.image.image_url;
      this.collection.description = result.collection.description;
      this.collection.startDate = result.collection.start_date ? new Date(result.collection.start_date) : null;
      this.collection.endDate = result.collection.end_date ? new Date(result.collection.end_date) : null;
      this.collection.sections = [result.collection.main_section]
        .concat(result.collection.collection_sections)
        .map(section => this.mapSection(section))
        .filter(section => section.image || section.flowers.length)
        .map(section => ({
          ...section,
        }));
    },
    async getFlowerHarvests(flowerId) {
      const { result } = await this.$http2("get", `shop/flower_harvests/search?flower_id=${flowerId}`);
      return result.flower_harvests;
    },
    async addDraftOrder({ flowerHarvestId, count }) {
      await this.postDraftOrder(flowerHarvestId, count);
    },
    async postDraftOrder(flowerHarvestId, count) {
      class OrderItemCreateError extends Error {
        constructor(status, message) {
          super(message);
          this.status = status;
          this.name = "OrderItemCreateError";
        }
      }

      try {
        const res = await this.$http("post", "order_items", {
          order_items: [{ flower_harvest_id: flowerHarvestId, lot_count: count }],
        });
        if (res.status === 422) throw new OrderItemCreateError(422, "Failed to adding item into cart");
        if (res.status === 521) throw new OrderItemCreateError(521, "Failed to adding item into cart");
      } catch (e) {
        if (e.status === 521) {
          this.$store.dispatch("orders/notifyUnbuyableAreaError");
        } else {
          this.$store.dispatch("orders/notifyAddToCartError");
        }

        console.log(e);
      } finally {
        await this.$store.dispatch("orders/getDraftOrder");
      }
    },
    async updateDraftOrder({ flowerHarvestId, orderItemId, count }) {
      try {
        if (count <= 0) {
          await this.$http("delete", `order_items/${orderItemId}`);
        } else {
          await this.$http("put", `order_items/${orderItemId}`, {
            order_item: {
              flower_harvest_id: flowerHarvestId,
              lot_count: count,
            },
          });
        }
      } finally {
        await this.$store.dispatch("orders/getDraftOrder");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.collection {
  margin: 0 auto;
  padding: 0 15px 28px;
  max-width: 500px;
}

.collection__back {
  padding: 12px 0 10px;
}

.collection__backLink {
  position: relative;
  padding-left: 18px;
  font-size: variables.$font-size-lg;
  line-height: 1;
  color: variables.$color-black-500;
}

.collection__backIcon {
  position: absolute;
  top: 50%;
  left: -8px;
  margin-top: -13px;
}

.collection__mainVisualWrapper {
  margin: 0 -15px;
}

.collection__mainVisual {
  width: 100%;
  height: auto;
  vertical-align: top;
}

.collection__text {
  padding: 39px 0 27px;
  color: variables.$color-black-500;
}

.collection__title {
  font-size: 24px;
  line-height: 1.2;
}

.collection__description {
  margin: 11px 0 0;
}

.collection__sections {
  border-bottom: 1px solid variables.$color-gray-100;
  padding-bottom: 14px;
}

.collection__term {
  margin: 42px 0 0;
}

.collection__others {
  padding-top: 23px;
}

.collection__othersTitle {
  margin-bottom: 16px;
  font-size: 24px;
  line-height: 1;
  color: variables.$color-black-500;
}
</style>
