<template>
  <div class="home">
    <Landpage />
  </div>
</template>

<script>
import Landpage from "@/components/Landpage.vue";

export default {
  name: "home",
  components: {
    Landpage,
  },
};
</script>
