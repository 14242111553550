<template>
  <div class="page-root">
    <Header>
      <template v-slot:caption>ありがとうございました。<br>エリア対応の際は、いち早くご連絡をいたします。</template>
    </Header>
    <div class="page-contents">
      <v-row>
        <v-card href="https://platform.cavin.ooo/" target="_brank">
          <img class="fill" src="@/assets/cavin-lp.png" />
        </v-card>
      </v-row>
      <v-row>
        <v-card href="https://cavin.ooo/" target="_brank">
          <img class="fill" src="@/assets/cavin-corporate.png" />
        </v-card>
      </v-row>
    </div>
  </div>
</template>

<script>
import Header from "@/components/users/Header.vue";

export default {
  components: {
    Header,
  },
  data() {
    return {

    };
  },
  computed: {

  },
  created() {

  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
$primary-color: #0e4da4;

.page-contents {
  margin: 34px auto 0;
  display: grid;
  grid-template-columns: 400px;
  justify-content: center;

  input[type="tel"]:focus {
    border: 2px solid $primary-color;
    outline: 0;
  }

  .v-input--radio-group {
    width: 100%;
  }

  @media screen and (max-width: 1000px) {
    max-width: 600px;
    grid-template-columns: 1fr;
  }

  .row {
    margin: 1.2rem auto;
    width: 90%;
  }

  .v-card {
    width: 100%;
  }

  .fill {
    display: block;
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .v-subheader {
    font-size: 1rem;
    font-weight: bold;
  }

  .radio-tile-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .input-container {
      position: relative;
      height: 5rem;
      width: 7rem;
      margin: 1rem;

      .radio-button {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        margin: 0;
        cursor: pointer;
      }

      .radio-tile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border: 2px solid $primary-color;
        border-radius: 5px;
        padding: 1rem;
        transition: transform 300ms ease;
      }
      .icon svg {
        fill: $primary-color;
        width: 3rem;
        height: 3rem;
      }
      .radio-tile-label {
        text-align: center;
        font-size: 0.75rem;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $primary-color;
      }

      .radio-button:checked + .radio-tile {
        background-color: $primary-color;
        border: 2px solid $primary-color;
        color: white;
        transform: scale(1.1, 1.1);

        .icon svg {
          fill: white;
          background-color: $primary-color;
        }
        .radio-tile-label {
          color: white;
          background-color: $primary-color;
        }
      }
    }
  }
}
</style>
