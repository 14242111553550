<template>
  <div class="harvests-wrapper">
    <div v-for="(flowerHarvest, index) in flowerHarvests" :key="flowerHarvest.id">
      <div :class="index % 2 === 0 ? 'odd-background harvest' : 'harvest'">
        <div>
          <div class="delivery-date-and-stem-size">
            <div class="delivery-date">{{ getFormattedDate(flowerHarvest.delivery_date) }}</div>
            <div class="stem-size">{{ flowerHarvest.stem_size }}cm</div>
          </div>
          <div class="sales-price-and-min-count">
            1口{{ flowerHarvest.min_count }}本から<span class="sales-price">{{ flowerHarvest.sales_price_without_delivery_fee }}</span>
          <span>円</span>
        </div>
        </div>
        <div>
          <MstButton v-if="!isAddedCart(flowerHarvest.id)" width="90px" size="sm" @click="handleAddCart(flowerHarvest.id)">カート追加</MstButton>
          <MstCounterButton
            v-else
            :value="getOrderItem(flowerHarvest.id).lot_count"
            :max="getMaxCount(flowerHarvest.id)"
            size="sm"
            @change="handleCounterButton(flowerHarvest.id, $event)"
          />
          <div class="stock">
            残り<span class="stock-count">{{ getMaxCount(flowerHarvest.id) }}</span>ロ
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MstButton, MstCounterButton } from "@/components/master";

export default {
  name: "HarvestTable",
  components: { MstButton, MstCounterButton },
  props: {
    flowerHarvests: { type: Array, required: true },
    orderItems: { type: Array, required: true },
  },
  methods: {
    getFormattedDate(date) {
      const dateObj = new Date(date);
      return `${this.$date.MMDD(dateObj, "/")}（${this.$date.getDayOfWeek(dateObj)}）`;
    },
    isAddedCart(flowerHarvestId) {
      return this.orderItems.some(orderItem => orderItem.flower_harvest.id === flowerHarvestId);
    },
    getOrderItem(flowerHarvestId) {
      return this.orderItems.find(orderItem => orderItem.flower_harvest.id === flowerHarvestId);
    },
    getMaxCount(flowerHarvestId) {
      const flowerHarvest = this.flowerHarvests.find(harvest => harvest.id === flowerHarvestId);
      return Math.floor(flowerHarvest.stock_count / flowerHarvest.min_count);
    },
    handleAddCart(flowerHarvestId) {
      this.$emit("add:cart", {
        flowerHarvestId,
        count: 1,
      });
    },
    handleCounterButton(flowerHarvestId, newValue) {
      const orderItem = this.getOrderItem(flowerHarvestId);
      this.$emit("change:count", {
        flowerHarvestId,
        orderItemId: orderItem.id,
        count: newValue,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.harvest {
  padding: 15px 12px;
  display: flex;
  justify-content: space-between;
}

.delivery-date-and-stem-size {
  display: flex;
  margin-bottom: 9.5px;
}

.delivery-date {
  font-size: 16px;
  font-weight: 700;
  color: #191D38;
}

.odd-background {
  background: #F4F6F8;
}

.stem-size {
  margin: auto 0 auto 3px;
  display: flex;
  align-items: center;
  padding-left: 4px;
  padding-right: 4px;
  height: 14px;
  line-height: 14px;
  background-color: #4A8FCF;
  border-radius: 1px;
  font-size: 10px;
  color: white;
}

.sales-price-and-min-count {
  font-size: 14px;
  color: #191D38;
}

.sales-price {
  font-size: 20px;
  font-weight: 700;
}

.stock {
  color: #191D38;
  margin-top: 6.5px;
  text-align: right;
  font-size: 12px;
}

.stock-count {
  font-size: 16px;
  font-weight: 700;
}
</style>
