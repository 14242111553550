<template>
  <div class="body" :style="{ backgroundImage: `url(${require('@/assets/flower-background.png')})` }">
    <div class="backdrop" />
    <div class="content d-flex flex-column justify-center align-center">
      <img :src="require('@/assets/CAVIN_Logo_white.png')" class ="mt-3" width="200px" />

      <span class="mt-3 welcome">{{ $t("welcome_back") }}</span>

      <v-card v-if="!hasUsers" class="card d-flex flex-column justify-center align-center">
        <span class="card-title">{{ $t("login") }}</span>
        <p v-if="invalid" style="color: red;">{{ $t("login_error") }}</p>
        <div style="width: 100%;">
          <div class="grey--text text--darken-1 mt-2">ログインするアカウント種類を選択する<span class="red--text">*</span></div>
          <v-radio-group
            v-model="content.user_type"
            row
          >
            <v-radio
              v-for="userType in userTypes"
              :key="userType.value"
              :label="userType.text"
              :value="userType.value"
            ></v-radio>
          </v-radio-group>
        </div>
        <div class="mt-5" style="width: 100%;">
          <span class="label">{{ $t("email") }}</span>
          <v-text-field ref="email" type="text" v-model="content.email" @keyup="keyupHandler" :error="invalid" autofocus outlined />
        </div>

        <div class="mt-4" style="width: 100%;">
          <span class="label">{{ $t("password") }}</span>
          <v-text-field ref="password" :type="showPassword ? 'text' : 'password'" class="mb-0" v-model="content.password" @keyup="keyupHandler" outlined :error="invalid" />
        </div>

        <div class="checkbox">
          <v-checkbox :label="$t('show_pwd')" v-model="showPassword" class="ma-0" color="success" hide-details />
        </div>

        <div class="forgot_pwd">
          <span>{{ $t("forgot_pwd") }}</span>
          <router-link to="/forgot_pwd" class="link">{{ $t("here") }}</router-link>
        </div>

        <v-btn :class="`btn green`" @click="getUsers">{{ $t("login_btn") }}</v-btn>
      </v-card>
      <v-card v-else class="card d-flex flex-column justify-center align-center">
        <div class="mt-10 mb-10" style="width: 100%;">
          <v-autocomplete
            ref="user"
            v-model="content.user"
            :items="users"
            item-value="id"
            item-text="name"
            label="ログインするアカウントを選択する"
          />
        </div>

        <v-btn :class="`btn green`" @click="login">{{ $t("login_btn") }}</v-btn>
      </v-card>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPassword: false,
      invalid: false,
      content: {},
      userTypes: [
        {
          value: "Shop",
          text: "花屋"
        },
        {
          value: "Farm",
          text: "生産者"
        }
      ],
      users: []
    };
  },
  computed: {
    hasUsers() {
      return this.users.length > 0;
    }
  },
  watch: {
    content: {
      handler(e) {
        if (e.user_type) {
          this.$router.push(`/admin/login?user_type=${e.user_type}`);
        }
      },
      deep: true
    }
  },
  methods: {
    keyupHandler(e) {
      if (e.key === "Enter") this.login();
    },
    getUsers() {
      const { email, password } = this.content;
      const user_type = this.$route.query.user_type;

      if (!user_type) {
        this.$refs.user_type.focus();
        return;
      }

      if (!email) {
        this.$refs.email.focus();
        return;
      }

      if (!password) {
        this.$refs.password.focus();
        return;
      }

      this.$store.dispatch("admin/getUsers", { login: { email, password }, userType: user_type }).then(res => {
        if (res) {
          this.invalid = false;
          this.users = res;
        } else {
          this.invalid = true;
        }
      });
    },
    login() {
      const { user } = this.content;

      if (!user) {
        this.$refs.user.focus();
        return;
      }

      this.$store.dispatch("admin/loginUserById", { id: user }).then(res => {
        if (res) {
          this.invalid = false;
          this.$router.push(`/${res.toLowerCase()}`);
        } else {
          this.invalid = true;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.body {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100vw;
  height: 100vh;
}

.backdrop {
  background-color: #333333;
  opacity: 0.8;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 0;
}

.content {
  position: absolute;
  z-index: 1;
  width: 100vw;
  height: 100vh;
}

.welcome {
  font: Bold 1rem Helvetica;
  color: #ffffff;
}

@media only screen and (max-width: 600px) {
  $width: 90%;
  .card {
    padding: 2rem;
    width: $width;
  }
  .signup {
    width: $width;
  }

  .forgot_pwd {
    text-align: center;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }
}

@media only screen and (min-width: 600px) {
  $width: 40rem;
  .card {
    padding: 2rem 4rem 2rem 4rem;
    width: $width;
  }

  .signup {
    width: $width;
  }

  .forgot_pwd {
    text-align: left;
    margin-top: -1.5rem;
    margin-bottom: 2rem;
  }
}

.card {
  margin-top: 2.5rem;
}

.signup {
  font: Bold 1rem Helvetica;
  text-align: left;
  color: #ffffff;
}

.forgot_pwd {
  width: 100%;
  font: Regular 0.8rem Helvetica;
  color: #777777;
  display: none;
}

.card-title {
  font: Bold 1.5rem Helvetica;
  color: #333333;
}

.label {
  font: Medium 1rem Noto Sans CJK JP;
  color: #111111;
}

.link {
  cursor: pointer;
  color: #3794e3;
  text-decoration: underline;
}

.checkbox {
  margin-top: -30px;
  margin-left: auto;
  color: #979797;
  font: SemiBold 14px/18px Muli;
}

.btn {
  width: 200px;
}
</style>
