<template>
  <div class="img-root">
    <side-preview
      class="d-flex"
      v-if="sidePreview"
      @click.native="$refs.file.click()"
      @drop="dropFile"
    >
      <img :src="img" class="img-img" />

      <upload-card />
    </side-preview>

    <full-preview v-else-if="fullPreview" @click.native="$refs.file.click()" @drop="dropFile">
      <upload-card v-if="!img" />
      <img v-else :src="img" class="img-img" />
    </full-preview>

    <multi-preview v-else-if="multiPreview">
      <div class="slider">
        <transition-group tag="div" name="slide-fade">
          <div
            v-for="(img, idx) in imgs"
            :key="`${img.image_url}-${idx}`"
            v-show="idx === currentIndex"
          >
            <span v-if="isEdit" class="img-img-remove" @click="()=>removeImg(img)">&times;</span>
            <img class="slide-img" :src="img.image_url" :alt="'Slide ' + (idx + 1)">
          </div>
        </transition-group>

        <div class="buttons">
          <button :class="{ 'slide-button' : true, active: idx === currentIndex}" v-for="(image, idx) in imgs" :key="idx" @click="setSlide(idx)">
          </button>
        </div>
      </div>

      <div @drop.prevent="dropFile" @dragover.prevent >
        <upload-card :is-disable="isDisable" @click="$refs.file.click()" />
      </div>
    </multi-preview>

    <input type="file" style="display: none" :accept="allowedExt" ref="file" @change="inputHandler" />
  </div>
</template>

<script>
import SidePreview from "@/components/shared/ImgHandler/SidePreview.vue";
import FullPreview from "@/components/shared/ImgHandler/FullPreview.vue";
import MultiPreview from "@/components/shared/ImgHandler/MultiPreview.vue";
import UploadCard from "@/components/shared/ImgHandler/UploadCard.vue";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cropperjs/dist/cropper.css";

const allowedExt = ["image/png", "image/jpg", "image/jpeg"];
const maxSize = 2e6;

export default {
  components: {
    SidePreview,
    FullPreview,
    UploadCard,
    MultiPreview
  },
  props: {
    sidePreview: {
      type: Boolean,
      default: null
    },
    fullPreview: {
      type: Boolean,
      default: null
    },
    multiPreview: {
      type: Boolean,
      default: null
    },
    defaultValue: {
      type: Array,
      default: null
    },
    disableCropper: {
      type: Boolean,
      default: null
    },
    isEdit: {
      type: Boolean,
      default: null
    },
    isDisable: {
      type: Boolean,
      default: false
    },
    direct: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      img: null,
      imgs: [],
      crop: null,
      imgToCrop: null,
      cropperDialog: null,
      cropperKey: 0,
      currentIndex: 0,
    };
  },

  created() {
    if (this.defaultValue) {
      if (this.multiPreview) {
        this.imgs = this.defaultValue;
      } else {
        this.img = this.defaultValue;
      }
    }
  },

  methods: {
    dropFile(e) {
      this.fileHandler(e.dataTransfer.files[0]);
    },
    inputHandler(e) {
      this.fileHandler(e.target.files[0]);
    },
    fileHandler(file) {
      if (!file) return;

      if (!allowedExt.includes(file.type)) {
        this.$emit("finish", "WRONG_FORMAT");
        return;
      }

      const that = this;
      const r = new FileReader();

      r.onload = function() {
        const img = new Image();

        img.onload = function() {
          if (that.multiPreview) {
            const image = { id: null, image_url: r.result };
            if (that.direct) {
              image.file = file;
            }
            that.imgs.push(image);
            that.$emit("finish", that.imgs);
          } else {
            that.img = r.result;
            if (that.direct) {
              that.$emit("finish", { id: null, image_url: r.result, file });
            } else {
              that.$emit("finish", r.result);
            }
          }
        };

        img.src = r.result;
      };

      r.readAsDataURL(file);
    },
    cropImage() {
      const cropped = this.$refs.cropper
        .getCroppedCanvas()
        .toDataURL("image/jpeg", 0.5);
      this.crop = cropped;
    },
    saveCrop() {
      this.cropperDialog = false;
      if (this.crop.length > maxSize) {
        this.$emit("finish", "OVERSIZE");
      } else if (this.multiPreview) {
        this.imgs.push({ id: null, image_url: this.crop });
        this.$emit("finish", this.imgs);
        this.crop = null;
      } else {
        this.img = this.crop;
        this.$emit("finish", this.img);
        this.crop = null;
      }
    },
    async removeImg(img) {
      if (!img.id) {
        this.imgs = this.imgs.filter(x => x.image_url !== img.image_url);
      } else {
        const res = await this.$http("delete", `images/${img.id}`);
        if (res.status === 200) {
          this.imgs = this.imgs.filter(x => x.id !== img.id);
        }
      }
    },
    clearImage() {
      this.img = null;
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.imgs.length;
    },
    prevSlide() {
      this.currentIndex = (this.currentIndex + this.imgs.length - 1) % this.imgs.length;
    },
    setSlide(index) {
      this.currentIndex = index;
    },
  },
  computed: {
    allowedExt() {
      return allowedExt.join(", ");
    }
  }
};
</script>

<style lang="scss" scoped>
.img-root {
  width: 100%;
  height: 100%;
  position: relative;
  .slide-img {
    width: 100%;
    min-height: 200px;
  }
}

.img-img {
  max-width: 100%;
  max-height: 100%;
}

.img-img-side {
  max-width: 150px;
  max-height: 150px;
}

.img-img-multi {
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  padding: 3px;
  width: 200px;
}

.img-img-remove {
  position: absolute;
  cursor: pointer;
  font-size: 1.5rem;
  top: 5px;
  right: 10px;
  z-index: 1;
}

.slider {
  width: 100%;
  min-height: 200px;
  margin: -7px;
}
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: opacity .5s;
}
.slide-fade-enter, .slide-fade-leave-to {
  opacity: 0;
}
.buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0%);
}
.slide-button {
  background-color: rgba(255, 255, 255, 0.2);
  width: 13px;
  height: 13px;
  border-radius: 100%;
}
.slide-button.active {
  background-color: white;
}
</style>
