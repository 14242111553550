<template>
  <component
    :is="to ? 'router-link' : 'button'"
    :to="to"
    :disabled="disabled"
    :class="styledClasses"
    :style="styled"
    class="mstButton"
    @click="$emit('click', $event)"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: "MstButton",
  props: {
    to: { type: String },
    color: { type: String, default: "blue-700" },
    size: { type: String, validator: value => ["sm", "md"].includes(value), default: "md" },
    width: { type: String, default: "100%" },
    outlined: { type: Boolean },
    disabled: { type: Boolean },
  },
  computed: {
    styledClasses() {
      const classes = [];

      if (this.outlined) {
        classes.push("-outlined");
      } else {
        classes.push("-filled");
      }

      classes.push(`-${this.size}`);
      classes.push(`-${this.color}`);

      return classes;
    },
    styled() {
      const styled = {};
      styled.width = this.width;
      return styled;
    },
  },
};
</script>

<style lang="scss" scoped>
.mstButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  font-size: variables.$font-size-sm;
  font-weight: bold;

  &.-filled {
    color: variables.$color-white;
    transition: opacity 0.3s ease;

    &.-blue-900 {
      background: variables.$color-blue-900;
    }

    &.-blue-700 {
      background: variables.$color-blue-700;
    }

    &.-black-500 {
      background: variables.$color-black-500;
    }

    &:hover,
    &:focus-visible {
      opacity: 0.92;
    }

    &:disabled {
      background: variables.$color-gray-300;
      cursor: not-allowed;

      &:hover,
      &:focus-visible {
        opacity: 1;
      }
    }
  }

  &.-outlined {
    border: 1px solid currentColor;
    background: variables.$color-white;
    transition: background 0.3s ease;

    &.-blue-900 {
      color: variables.$color-blue-900;
    }

    &.-blue-700 {
      color: variables.$color-blue-700;
    }

    &.-black-500 {
      color: variables.$color-black-500;
    }

    &:hover,
    &:focus-visible {
      background: variables.$color-gray-50;
    }

    &:disabled {
      color: variables.$color-gray-300;
      cursor: not-allowed;

      &:hover,
      &:focus-visible {
        background: variables.$color-white;
      }
    }
  }

  &.-sm {
    height: 32px;
  }

  &.-md {
    height: 48px;
  }
}
</style>
