<template>
  <div class="page-container">
    <div class="non-table-container pa-4">
      <div class="d-flex align-center pointer" @click="onGoBack()">
        <ArrowLeftIcon size="12" color="black" />
        <h4 class="ml-2 font-weight-normal">{{ $t("back_short") }}</h4>
      </div>
      <h1 class="text-center my-6">{{ $t("listRegistredProducts") }}</h1>

      <div class="d-flex justify-end mb-4" v-if="!mobile">
        <MstButton width="140px" class="btn bg-secondary" to="/farm/flowers/new" color="balck-500">{{ $t("add_product") }}</MstButton>
      </div>

      <div class="mobile-add-button" v-if="mobile">
        <MstFloatingButton :label="$t('add_product')" icon="mdi-plus" color="#173169" labelPosition="outside" to="/farm/flowers/new" />
      </div>

      <div class="search-board">
        <div class="d-flex bg-back-gray search-container align-center">
          <SearchIcon color="gray" size="16" />
          <input class="search-button" placeholder="キーワードから探す" @input="onChangeSearch" :value="searchString" />
        </div>
        <div class="search-result" v-if="searchString !== ''">
          <div class="mx-4 pa-2 pointer" @click="onHandleTap(item.id)" v-for="item in searchResult" :key="item.id">
            <div class="my-2 d-flex justify-space-between align-center">
              <div class="d-flex">
                <span class="badge-gray mr-2">{{ item.category }}</span>
                <span>{{ flowerGenus.name }}</span>
              </div>
              <span class="bagde-red w-100px flex-shrink-0 flex-grow-0" v-if="!item.is_working">{{ $t('hidden') }}</span>
            </div>
            <div class="font-weight-bold text-overflow-dot">{{ item.specie_name }}</div>
          </div>
          <div v-if="searchResult.length === 0">
            <div class="mx-4 pa-2">{{ $t('no_products_matching') }}</div>
          </div>
        </div>
      </div>

      <div v-if="!loadingStatus" class="d-flex mt-4 justify-space-between align-center">
        <h2 class="w-100 text-overflow-dot">{{ flowerGenus.name }}</h2>
        <h2 class="flex-shrink-0 flex-grow-0">{{ flowerGenusCount }}<span class="text-md">{{ $t("piece") }}</span></h2>
      </div>
    </div>

    <div class="mb-4 table">
      <div class="d-flex pa-4">
        <div class="flex-shrink-1 flex-grow-1">{{ $t("specie") }}</div>
        <div class="w-100px">{{ $t("category") }}</div>
        <div class="table-action"> </div>
      </div>

      <div class="pa-4" v-if="loadingStatus">
        {{ $t("loading") }}
      </div>

      <template v-else-if="workingFlowers.length">
        <div class="d-flex pa-4 table-row pointer"  @click="onHandleTap(item.id)" v-for="item in workingFlowers" :key="item.id">
          <div class="flex-shrink-1 flex-grow-1 font-weight-bold text-overflow-dot"> {{ item.specie_name }} </div>
          <div class="w-100px flex-shrink-0 flex-shrink-0"> {{ item.category }} </div>
          <div class="table-action flex-shrink-0 flex-shrink-0"> <ArrowRightIcon size="12" color="black" /> </div>
        </div>
      </template>

      <div class="pa-4" v-else>
        {{ $t("no_working") }}
      </div>
    </div>

    <div class="d-flex color-secondary ma-4 align-center" @click="goToHiddenProduct()">
      <h3 class="font-weight-normal mr-3 pointer">{{ $t("not_working") }}</h3>
      <ArrowRightIcon size="16" color="#173169"/>
    </div>
  </div>
</template>

<script>
import SearchIcon from "@/components/icons/SearchIcon.vue";
import { MstFloatingButton, MstButton } from "@/components/master";
import ArrowRightIcon from "@/components/icons/ArrowRightIcon.vue";
import ArrowLeftIcon from "@/components/icons/ArrowLeftIcon.vue";

export default {
  components: { SearchIcon, MstFloatingButton, ArrowRightIcon, ArrowLeftIcon, MstButton },
  data() {
    return {
      mobile: this.$vuetify.breakpoint.xs,
      searchString: "",
      searchResult: [],
      flowerGenusId: this.$route.params.flower_genera_id,
      flowerGenus: {},
      flowerGenusCount: 0,
      items: [],
      loadingStatus: false,
    };
  },
  created() {
    this.loadingStatus = true;
    this.$api.get("/harvests/flower_genus").then(response => {
      this.flowerGenus = response.result.flower_genus.find(genus => genus.id.toString() === this.flowerGenusId);
      this.$store.dispatch("flowers/getFlowers").then(this.createTableData);
    });
  },
  methods: {
    async createTableData() {
      this.items = [];
      const { flowers } = this.$store.state;

      flowers.listArr.forEach(id => {
        const f = flowers.list[id];
        if (this.flowerGenusId === f.flower_specie.flower_genus.id.toString()) {
          this.items.push({
            id: f.id,
            category: f.category.name,
            genus_name: f.flower_specie.flower_genus.name,
            specie_name: f.flower_specie.name,
            flower_type: f.flower_type,
            is_working: f.is_working
          });
        }
      });
      this.flowerGenusCount = this.items.filter(item => item.is_working).length;
      this.loadingStatus = false;
    },
    onGoBack() {
      this.$router.go(-1);
    },
    onHandleTap(flower_id) {
      this.$router.push(`/farm/flower_genera/${this.flowerGenusId}/flowers/${flower_id}`);
    },
    goToHiddenProduct() {
      this.$router.push(`/farm/flower_genera/${this.flowerGenusId}/hidden_flowers`);
    },
    onChangeSearch(event) {
      this.searchString = event.target.value;
      this.searchResult = this.items.filter(item => item.specie_name.includes(this.searchString) || this.flowerGenus.name.includes(this.searchString));
    }
  },

  computed: {
    workingFlowers() {
      return this.items.filter(item => item.is_working);
    },
    notWorkingFlowers() {
      return this.items.filter(item => !item.is_working);
    }
  }
};
</script>

<style lang="scss">
</style>
