<template>
  <div :class="styledClasses" class="mstSelectBox">
    <div class="mstSelectBox__inner">
      <button
        ref="field"
        :id="componentId"
        :aria-expanded="isVisible"
        :aria-controls="`${componentId}-container`"
        :aria-describedby="[hintText ? `${componentId}-hint` : '', error ? `${componentId}-error` : ''].join(' ')"
        :style="styled"
        :disabled="disabled"
        type="button"
        aria-haspopup="listbox"
        class="mstSelectBox__field"
        @click="isVisible = !isVisible"
      >
        {{ displayLabel }}
        <MstIcon type="chevron-down" size="24px" class="mstSelectBox__icon" />
      </button>

      <transition name="container">
        <div
          v-if="isVisible"
          :id="`${componentId}-container`"
          :aria-hidden="!isVisible"
          class="mstSelectBox__container"
        >
          <ul
            :aria-activedescendant="value ? `${componentId}-option-${value}` : ''"
            role="listbox"
            class="mstSelectBox__options"
          >
            <li v-for="option in options" :key="option.value" role="presentation" class="mstSelectBox__item">
              <button
                ref="option"
                :id="`${componentId}-option-${option.value}`"
                :tabindex="option.value === value ? 0 : -1"
                :aria-selected="String(option.value === value)"
                type="button"
                role="option"
                class="mstSelectBox__option text-md"
                @click="handleClick(option.value)"
                @keydown="handleKeyDown"
              >
                {{ option.label }}
              </button>
            </li>
          </ul>
        </div>
      </transition>
    </div>

    <p v-if="hintText" :id="`${componentId}-description`" class="mstSelectBox__hint">{{ hintText }}</p>
    <p v-if="error" :id="`${componentId}-error`" role="alert" aria-live="assertive" class="mstSelectBox__error">
      {{ error }}
    </p>
  </div>
</template>

<script>
import { nanoid } from "nanoid";
import { MstIcon } from "@/components/master";

export default {
  name: "MstSelectBox",
  inheritAttrs: false,
  model: {
    prop: "value",
    event: "change",
  },
  components: { MstIcon },
  props: {
    value: { type: String, required: true },
    options: { type: Array, required: true },
    size: { type: String, validator: value => ["md", "lg"].includes(value), default: "md" },
    width: { type: String, default: "100%" },
    id: { type: String },
    error: { type: String },
    hintText: { type: String },
    placeholder: { type: String },
    disabled: { type: Boolean },
  },
  data() {
    return {
      isVisible: false,
      focusIndex: null,
    };
  },
  computed: {
    componentId() {
      return this.id ? this.id : `select-${nanoid()}`;
    },
    modelValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
    displayLabel() {
      if (!this.value) return this.placeholder || "";
      return this.options.find(option => option.value === this.value).label;
    },
    styledClasses() {
      const classes = [];
      classes.push(`-${this.size}`);
      if (!this.value) classes.push("-empty");
      return classes;
    },
    styled() {
      const styled = {};
      styled.width = this.width;
      return styled;
    },
  },
  watch: {
    isVisible(newValue) {
      if (newValue) {
        this.focusIndex = this.getFocusIndex(this.value);
        window.addEventListener("click", this.handleClickOutside);
        window.addEventListener("keydown", this.handleEscKey);
      } else {
        window.removeEventListener("click", this.handleClickOutside);
        window.removeEventListener("keydown", this.handleEscKey);
        this.$refs.field.focus();
        this.focusIndex = null;
      }
    },
    focusIndex(newValue) {
      if (newValue === null) return;
      this.$nextTick(() => {
        this.$refs.option[newValue].focus();
      });
    },
  },
  beforeDestroy() {
    window.removeEventListener("click", this.handleClickOutside);
    window.removeEventListener("keydown", this.handleEscKey);
  },
  methods: {
    getFocusIndex(value) {
      const index = this.options.findIndex(option => option.value === value);
      return index === -1 ? 0 : index;
    },
    handleClickOutside(event) {
      if (
        event.target.closest(`#${this.componentId}-container`)
        || event.target.closest(`#${this.componentId}`)
      ) return;
      this.isVisible = false;
    },
    handleEscKey(event) {
      if (event.key !== "Escape") return;
      this.isVisible = false;
    },
    handleKeyDown(event) {
      switch (event.key) {
        case "ArrowDown":
          this.focusIndex = this.focusIndex === this.options.length - 1 ? 0 : this.focusIndex + 1;
          break;
        case "ArrowUp":
          this.focusIndex = this.focusIndex === 0 ? this.options.length - 1 : this.focusIndex - 1;
          break;
        case "Home":
          event.preventDefault();
          this.focusIndex = 0;
          break;
        case "End":
          event.preventDefault();
          this.focusIndex = this.options.length - 1;
          break;
        case "Tab":
          event.preventDefault();
          this.isVisible = false;
          break;
        default:
          break;
      }
    },
    handleClick(value) {
      this.$emit("change", value);
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.mstSelectBox__inner {
  position: relative;
}

.mstSelectBox__field {
  position: relative;
  border-radius: 4px;
  border: 1px solid variables.$color-gray-300;
  font-size: 16px;
  line-height: 1.3;
  text-align: left;
  outline: none;
  color: variables.$color-black-500;
  transition: border 0.3s ease;

  &:focus {
    border-color: variables.$color-blue-900;
  }

  &:disabled {
    background: variables.$color-gray-50;
    cursor: not-allowed;
    color: variables.$color-gray-600
  }

  .-md & {
    padding: 7px 36px 7px 8px;
    height: 36px;
  }

  .-lg & {
    padding: 7px 29px 7px 15px;
    height: 40px;
  }

  .-empty & {
    color: variables.$color-gray-600;
  }
}

.mstSelectBox__icon {
  position: absolute;
  top: 50%;
  right: 8px;
  margin-top: -12px;
  max-width: 100%;
  color: variables.$color-gray-600;
}

.mstSelectBox__container {
  overflow-y: auto;
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  border-radius: 4px;
  border: 1px solid variables.$color-gray-300;
  padding: 4px 0;
  width: 100%;
  max-height: 202px;
  background: rgba(255, 255, 255, 0.95);
  z-index: 1;
}

.mstSelectBox__options {
  padding: 0;
  list-style-type: none;
}

.mstSelectBox__option {
  position: relative;
  padding: 8px 12px 8px 32px;
  width: 100%;
  outline: none;
  text-align: left;
  color: variables.$color-black-500;
  transition: background 0.3s ease;

  &[aria-selected="true"] {
    &::before {
      position: absolute;
      top: 50%;
      left: 8px;
      content: "\F012C";
      margin-top: -8px;
      font-family: "Material Design Icons";
      font-weight: normal;
      font-style: normal;
      font-size: 16px;
      line-height: 1;
    }
  }

  &:hover,
  &:focus-visible {
    background: variables.$color-gray-50;
  }
}

.mstSelectBox__hint {
  margin-top: 8px;
  font-size: variables.$font-size-xs;
  line-height: 1.4;
  color: variables.$color-gray-600;
}
.mstSelectBox__error {
  margin-top: 8px;
  font-size: variables.$font-size-xs;
  line-height: 1.4;
  color: variables.$color-red-500;
}

.container-enter-active,
.container-leave-active {
  transition: opacity 0.3s ease;
}

.container-enter,
.container-leave-to {
  opacity: 0;
}
</style>
