<template>
  <div class="image-container">
    <span v-if="caption" class="caption">{{ caption }}</span>
    <v-carousel v-model="value" hide-delimiter-background :hide-delimiters="imageUrls.length <= 1"
      :show-arrows="imageUrls.length > 1">
      <div v-for="(imgUrl, index) in imageUrls" :key="index">
        <v-carousel-item v-if="imgUrl" :src="imgUrl" />
      </div>
    </v-carousel>
  </div>
</template>

<script>
export default {
  props: {
    imageUrls: {
      type: Array,
      default: () => []
    },
    caption: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      value: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.image-container {
  position: relative;
  overflow: hidden;
  background-color: #000;

  &.border {
    border: none;
  }
}

.caption {
  position: absolute;
  top: 16px;
  left: 15px;
  z-index: 1;
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #fff;
  padding: 12px 16px;
  background: #173169;
  font-size: 16px !important;
  font-weight: bold;
  line-height: 1;
  color: #fff;
}

::v-deep .v-carousel__controls__item {
  &::before {
    opacity: 0;
  }

  &.v-btn {
    height: 28px !important;
  }

  & .v-icon {
    opacity: 1;
    color: rgba(255, 255, 255, 0.2);
  }

  &.v-item--active {
    & .v-icon {
      opacity: 1;
      color: #fff;
    }
  }
}

::v-deep .v-image__image--cover {
  background-size: contain;
}

::v-deep .v-window__next,
::v-deep .v-window__prev {
  & .v-btn {
    height: 50px !important;
    width: 50px !important;
    background-color: rgba(17, 17, 17, 0.7);
  }
}
</style>
