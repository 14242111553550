<template>
  <div>
    <h1 class="ma-4 text-center">{{ $t("farmOrderItems") }}</h1>

    <DatePickerV2 class="mt-3" :selected="selectedDate" :allowWdayList="allowWdayList" @change="dateHandler" />

    <div class="pa-4">
      <ul class="tab-list">
        <li
          v-for="(tab, index) in tabs"
          :key="index"
          class="tab-item"
          :class="{ active: tabId === index }"
        >
          <button @click="statusHandler(tab, index)">{{ $t(`${tab}`) }}</button>
        </li>
      </ul>

      <div class="ma-3" style="overflow-x: auto">
        <div v-if="loadingStatus">{{ $t("loading") }}</div>
        <div v-if="status === 'unconfirmed'">
          <div :key="index" v-for="(item, index) in items">
            <UnConfirmedCard @openConfirmModal="openModal(item.id, 'confirm')" @openCancelModal="openModal(item.id, 'cancel')" :id="item.id" :genusName="item.genus_name"
            :speciesName="item.flower_specie" :stemSize="item.stem_size" :lotCount="item.lot_count" :harvestMinCount="item.min_count" :price="item.unit_price"
            :totalPrice="item.subtotal_harvest_price_without_tax" :totalCount="item.subtotal_count" :shopName="item.shop_name" />
          </div>
          <div class="modal-mask" v-if="showConfirmModal || showCancelModal">
            <div class="modal-wrapper">
              <div class="modal-container">
                <div class="d-flex justify-center align-center">
                  <FlowerIcon size="56" :color="'#173169'" />
                </div>
                <div class="text-center my-4">
                  <span class="font-weight-bold">{{ $t('request_approval') }}</span>
                </div>
                <div class="text-center">
                  <span>{{ $t('cannot_be_undone_later') }}</span>
                </div>
                <div v-if="showConfirmModal" class="d-flex justify-space-between my-4">
                  <button class="btn-cancel" @click="showConfirmModal = false">{{ $t('cancel') }}</button>
                  <button class="btn-approve" @click="accept('forward')">{{ $t('approve') }}</button>
                </div>
                <div v-else class="d-flex justify-space-between my-4">
                  <button class="btn-cancel" @click="showCancelModal = false">{{ $t('cancel') }}</button>
                  <button class="btn-reject" @click="reject">{{ $t('reject_order') }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="status === 'confirmed'">
          <div :key="index" v-for="(item, index) in groupedItems">
            <ConfirmedCard :genusName="item.genus_name" :speciesName="item.flower_specie" :stemSize="item.stem_size" :totalPrice="item.subtotal_harvest_price_without_tax"
            :totalCount="item.subtotal_count" :transactions="groupedTransactions[item.harvest_id]" @openShipModal="openShipModal(groupedTransactions[item.harvest_id])" />
          </div>
          <div class="modal-mask" v-if="showShippedModal">
            <div class="modal-wrapper">
              <div class="modal-container">
                <div class="d-flex justify-center align-center">
                  <CarIcon size="56" :color="'#173169'" />
                </div>
                <div class="text-center my-4">
                  <span class="font-weight-bold">{{ $t('mark_as_shipped') }}</span>
                </div>
                <div class="text-center">
                  <span>{{ $t('cannot_be_undone_later') }}</span>
                </div>
                <div class="d-flex justify-center my-4">
                  <button class="btn-cancel" @click="showShippedModal = false">{{ $t('cancel') }}</button>
                  <!-- <button class="btn-shipped" @click="goShip">{{ $t('mark_shipped') }}</button> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div :key="index" v-for="(item, index) in items">
            <ShippedCard :id="item.id" :speciesName="item.flower_specie" :stemSize="item.stem_size" :lotCount="item.lot_count" :harvestMinCount="item.min_count"
            :price="item.unit_price" :totalPrice="item.subtotal_harvest_price_without_tax" :totalCount="item.subtotal_count" :shopName="item.shop_name"
            :genusName="item.genus_name" :status="item.status" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmedCard from "@/components/farm/orderItems/ConfirmedCard.vue";
import UnConfirmedCard from "@/components/farm/orderItems/UnConfirmedCard.vue";
import ShippedCard from "@/components/farm/orderItems/ShippedCard.vue";
import DatePickerV2 from "@/components/shop/DatePickerV2.vue";
import { formatDate } from "@/plugins/i18n";
import CarIcon from "@/components/icons/CarIcon.vue";
import FlowerIcon from "@/components/icons/FlowerIcon.vue";

export function toDateKey(date) {
  return formatDate(date, "yyyy-mm-dd");
}

export default {
  components: { ConfirmedCard, UnConfirmedCard, ShippedCard, DatePickerV2, CarIcon, FlowerIcon },
  data() {
    return {
      mobile: this.$vuetify.breakpoint.xs,
      status: "request",
      groupBy: "order_item",
      headers: [
        { text: this.$t("id"), value: "id", width: "60px" },
        { text: this.$t("min_count"), value: "min_count", width: "60px" },
        { text: this.$t("set_count"), value: "set_count", width: "60px" },
        { text: this.$t("unit_price"), value: "unit_price", width: "40px" },
        { text: this.$t("shop_name"), value: "shop_name", width: "80px" }
      ],
      items: [],
      selectedDate: null,
      selectedItems: [],
      currentDate: "",
      tabs: ["request", "unshipped", "shipped"],
      tabId: 0,
      showShippedModal: false,
      showConfirmModal: false,
      showCancelModal: false,
      loadingStatus: false,
    };
  },
  computed: {
    store() {
      return this.$store.getters["orderItems/getState"];
    },
    startDate() {
      return this.store.start_date
        ? this.$date.MMDD(this.store.start_date)
        : "-";
    },
    endDate() {
      return this.store.end_date ? this.$date.MMDD(this.store.end_date) : "-";
    },
    closestNextWorkingDate() {
      return this.store.closest_next_working_date;
    },
    isDisplayRequestLimit() {
      return this.status === "unconfirmed" && this.selectedDate;
    },
    requestLimitDate() {
      if (!this.selectedDate) {
        return false;
      }
      const selectedDate = new Date(this.selectedDate);
      const requestLimitDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() - 1);
      return formatDate(requestLimitDate, "yyyy年mm月dd日 15:30");
    },
    isGroupByShop() {
      return this.groupBy === "shop";
    },
    hashedItems() {
      const hashedItems = {};
      this.items.forEach(item => {
        if (Object.keys(hashedItems).includes(String(item.harvest_id))) {
          hashedItems[item.harvest_id].subtotal_count += item.subtotal_count;
          hashedItems[item.harvest_id].subtotal_harvest_price_without_tax += item.subtotal_harvest_price_without_tax;
        } else {
          hashedItems[item.harvest_id] = {
            harvest_id: item.harvest_id,
            subtotal_count: item.subtotal_count,
            subtotal_harvest_price_without_tax: item.subtotal_harvest_price_without_tax,
            flower_specie: item.flower_specie,
            genus_name: item.genus_name,
            stem_size: item.stem_size,
            note: item.note,
          };
        }
      });
      return hashedItems;
    },
    groupedItems() {
      const groupedItems = [];
      Object.keys(this.hashedItems).forEach(arr => {
        groupedItems.push(this.hashedItems[arr]);
      });
      return groupedItems;
    },
    groupedTransactions() {
      const groupedTransactions = {};
      this.items.forEach(item => {
        if (Object.keys(groupedTransactions).includes(String(item.harvest_id))) {
          groupedTransactions[item.harvest_id].push(item);
        } else {
          groupedTransactions[item.harvest_id] = [item];
        }
      });
      return groupedTransactions;
    },
    allowWdayList() {
      return this.$store.getters.workingWdays;
    },
  },
  async created() {
    const { query } = this.$route;
    const date = query.date || toDateKey(new Date());

    if (query.status) {
      this.status = query.status;
      if (this.status === "request") {
        this.status = "unconfirmed";
        this.tabId = 0;
      } else if (this.status === "unshipped") {
        this.status = "confirmed";
        this.tabId = 1;
      } else {
        this.status = "shipped";
        this.tabId = 2;
      }
    }

    await this.fetchData(date);

    this.selectedDate = this.closestNextWorkingDate;

    if (date !== this.selectedDate) {
      await this.fetchData(this.selectedDate);
    }

    this.currentDate = new Date(this.selectedDate);
    this.createTableData();
  },
  methods: {
    async fetchData(date) {
      this.loadingStatus = true;
      await this.$store.dispatch("orderItems/getOrderItems", {
        date,
        status: this.status
      });
      this.loadingStatus = false;
    },
    async statusHandler(status, index) {
      this.items = [];
      this.tabId = index;
      this.$router.push(`/farm/order_items?status=${status}`);
      if (status === "request") {
        this.status = "unconfirmed";
      } else if (status === "unshipped") {
        this.status = "confirmed";
      } else {
        this.status = "shipped";
      }

      if (this.currentDate) {
        const date = this.$date.YYYYMMDD(this.currentDate);

        await this.fetchData(date);

        this.selectedDate = this.closestNextWorkingDate;
        this.currentDate = new Date(this.selectedDate);

        if (date !== this.selectedDate) {
          await this.fetchData(this.selectedDate);
        }

        this.createTableData();
      }
    },
    async dateHandler(d) {
      this.selectedDate = d;
      this.currentDate = new Date(d);
      this.$router.push({ name: this.$route.name, query: { date: d } });

      await this.fetchData(d);

      this.createTableData();
    },
    async createTableData() {
      const { orderItems } = this.$store.state;

      this.items = [];

      const targetOrderItems = orderItems.listArr[this.status];

      if (this.status === "shipped") {
        orderItems.listArr.unloaded.map(id => targetOrderItems.push(id));
        orderItems.listArr.picked.map(id => targetOrderItems.push(id));
        orderItems.listArr.delivered.map(id => targetOrderItems.push(id));
      }

      targetOrderItems.forEach(id => {
        let storeOrderItems = this.store.list[this.status];
        if (this.status === "shipped") {
          storeOrderItems = {
            ...storeOrderItems,
            ...this.store.list.unloaded,
            ...this.store.list.picked,
            ...this.store.list.delivered
          };
        }

        const orderItem = storeOrderItems[id];

        if (
          this.$date.compareDates(this.currentDate, new Date(orderItem.flower_harvest.harvest_date))
        ) {
          if (this.isGroupByShop) {
            this.headers = [
              { text: this.$t("id"), value: "id", width: "60px" },
              { text: this.$t("min_count"), value: "min_count", width: "60px" },
              { text: this.$t("set_count"), value: "set_count", width: "60px" },
              { text: this.$t("unit_price"), value: "unit_price", width: "40px" },
              { text: this.$t("flower_specie"), value: "flower_specie", width: "80px" },
            ];
          } else if (this.groupBy === "order_item" && this.status === "confirmed") {
            this.headers = [
              { text: this.$t("flower_specie"), value: "flower_specie", width: "120px" },
              { text: this.$t("stem_size"), value: "stem_size", width: "80px" },
              { text: this.$t("subtotal_count"), value: "subtotal_count", width: "80px" },
              { text: this.$t("note"), value: "note", width: "500px" },
            ];
          } else {
            this.headers = [
              { text: this.$t("id"), value: "id", width: "60px" },
              { text: this.$t("min_count"), value: "min_count", width: "60px" },
              { text: this.$t("set_count"), value: "set_count", width: "60px" },
              { text: this.$t("unit_price"), value: "unit_price", width: "40px" },
              { text: this.$t("shop_name"), value: "shop_name", width: "80px" }
            ];
          }
          this.items.push({
            ...orderItem,
            shop_name: orderItem.shop.name,
            genus_name: orderItem.flower.flower_specie.flower_genus.name,
            flower_specie: orderItem.flower.flower_specie.name,
            unit_price: orderItem.harvest_price,
            min_count: orderItem.harvest_min_count,
            set_count: orderItem.lot_count,
            harvest_id: orderItem.flower_harvest.id,
            subtotal_count: orderItem.subtotal_count,
            note: orderItem.flower_harvest.note,
            stem_size: `${orderItem.flower_harvest.stem_size}cm`,
            group_by_id: this.isGroupByShop ? orderItem.shop.id : orderItem.flower_harvest.id
          });

          this.items.sort((a, b) => a.harvest_id - b.harvest_id);
        }
      });
    },
    clickHandler(item) {
      this.$router.push({
        path: `/farm/order_items/${item.id}`,
        query: { date: this.$date.YYYYMMDD(this.currentDate), status: this.status }
      });
    },
    accept(side) {
      const payload = {
        side,
        selectedItems: this.selectedItems,
        status: this.status
      };
      this.$store.dispatch("orderItems/postOrderItems", payload).then(res => {
        if (res) {
          this.$store
            .dispatch("orderItems/getOrderItems", {
              date: this.$date.YYYYMMDD(this.currentDate),
              status: this.status
            })
            .then(this.createTableData);
          this.$router.go({ path: this.$router.currentRoute.path, force: true });
        }
      });
    },
    reject() {
      const payload = {
        order_item_ids: this.selectedItems
      };
      this.$store.dispatch("orderItems/rejectRequest", payload).then(res => {
        if (res) {
          this.$store
            .dispatch("orderItems/getOrderItems", {
              date: this.$date.YYYYMMDD(this.currentDate),
              status: this.status
            })
            .then(this.createTableData);
          this.$router.go({ path: this.$router.currentRoute.path, force: true });
        }
      });
    },
    openShipModal(items) {
      this.selectedItems = [];
      this.showShippedModal = true;
      items.forEach(item => {
        this.selectedItems.push(item.id);
      });
    },
    openModal(id, modalName) {
      this.selectedItems = [];
      if (modalName === "confirm") {
        this.showConfirmModal = true;
      } else {
        this.showCancelModal = true;
      }
      this.selectedItems.push(id);
    },
    async goShip() {
      // const items = this.selectedItems;
      // await this.$store.dispatch("driverInformation/editStatus", {
      //   transitionType: "shipped",
      //   items,
      // });
      // this.createTableData();
      this.showShippedModal = false;
    }
  }
};
</script>

<style scoped>
.tab-item button {
  border: none;
  background: none;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
}
.tab-list {
  display: flex;
  list-style: none;
  padding: 0;
  border-bottom: 1px solid #ccc;
  justify-content: space-between;
}

.tab-list li {
  margin-right: 10px;
  font-weight: 700;
  color: gray;
  font-weight: 400;
  flex-grow: 1;
  flex-shrink: 1;
  text-align: center;
}

.tab-list li.active {
  border-bottom: 2px solid #173169;
  color: #173169;
}
.rounded-8 {
  border-radius: 8px;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  width: 344px;
  margin: 0px auto;
  padding: 20px 45px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.btn-cancel {
  border-radius: 4px;
  background-color: white !important;
  color: #173169 !important;
  border: 1px solid #173169 !important;
  width: 120px;
  height: 40px;
  font-weight: 400 !important;
}
.btn-shipped {
  border-radius: 4px;
  color: white !important;
  background-color: #173169 !important;
  font-weight: 400 !important;
  width: 120px;
  height: 40px;
}
.btn-approve {
  border-radius: 4px;
  color: white !important;
  background-color: #67A16D !important;
  font-weight: 400 !important;
  height: 40px;
  width: 120px;
}
.btn-reject {
  border-radius: 4px;
  color: white !important;
  background-color: #C75757 !important;
  font-weight: 400 !important;
  height: 40px;
  width: 120px;
}
.text-overflow-dot {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.no-wrap {
  white-space: nowrap;
}
</style>
