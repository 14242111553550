<template>
  <div>
    <v-alert
      v-model="alert"
      :close-text="$t('close')"
      text
      type="success"
      outlined
      dismissible
      class="ma-4"
    >
      <div class="text-subtitle-2">振り込み人名義の頭に<span class="font-weight-bold" style="color: #FF8C71">{{ userId }}</span>を追記してください。</div>
      {{ billingPeriodDate }}の¥{{ billingTotal }}の支払いが確定しました。支払い期限は{{ paymentDueDate }}迄です。
    </v-alert>
    <v-row class="my-1">
      <v-col sm="6" cols="12" class="pb-0 d-flex justify-center">
        <v-btn color="#4A8FCF" to="" style ="color: #FFF; width: 95%" class="d-flex justify-space-between" >
          <span class="invoice-period">{{ invoices && format(invoices[0].start_date) }} ~ {{ invoices && format(invoices[0].end_date) }}購入分（確定）</span>
          <span class="invoice-amount">{{ invoices && invoices[0].billing_amount_with_tax }}円</span>
        </v-btn>
      </v-col>
      <v-col sm="6" cols="12" class="pb-0 d-flex justify-center">
        <v-btn color="#4A8FCF" outlined to="" style ="width: 95%" class="d-flex justify-space-between">
          <span class="invoice-period">{{ format(invoices[1].start_date) }} ~ {{ format(invoices[1].end_date) }}購入分（未確定）</span>
          <span class="invoice-amount">{{ invoices[1].billing_amount_with_tax }}円</span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { calcFortnight, formatDate } from "@/plugins/i18n";

export default {
  props: {
    currentFortnight: {
      type: Number,
      default: null
    },
    previousFortnight: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      alert: true,
      invoices: [{}, {}],
      createdAt: null
    };
  },
  async created() {
    this.createdAt = new Date();
    if (!this.isDisplayBilling()) {
      this.alert = false;
    }
    const res = await this.$api2.get("shop/invoices/current_shop_invoices", this.axiosOptions);
    this.invoices = res.result.invoices;
  },
  methods: {
    calcFortnight,
    format(date) {
      const d = new Date(date);
      return formatDate(d, "m/d");
    },
    cFortnight(previousPeriod) {
      const date = new Date();

      if (previousPeriod) {
        date.setMonth(date.getMonth() - 1);
      }

      if (date.getDate() < 15) {
        const d = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        return formatDate(date, `yyyy/m/${d.getDate()}`);
      }

      date.setMonth(date.getMonth() + 1);
      return formatDate(date, "yyyy/m/15");
    },
    isDisplayBilling() {
      return this.nowDate % 15 === 1;
    },
    isFirstHalfPeriod() {
      return this.nowDate < 16;
    }
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    userId() {
      return this.$store.state.user.id;
    },
    now() {
      return this.createdAt;
    },
    nowDate() {
      return this.now.getDate();
    },
    billingPeriodDate() {
      return calcFortnight(true, false);
    },
    billingTotal() {
      return this.previousFortnight || 0;
    },
    paymentDueDate() {
      const paymentDueDate = this.isFirstHalfPeriod()
        ? new Date(this.now.getFullYear(), this.now.getMonth(), 15)
        : new Date(this.now.getFullYear(), this.now.getMonth() + 1, 0);
      return formatDate(paymentDueDate, "m/d");
    }
  }
};
</script>

<style lang="scss" scoped>
.invoice-period {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
}
.invoice-amount {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: right;
}
.product-header {
  &-payment-information {
    width: 100%;
    text-align: left;
    font-weight: bold;
  }

  &-left {
    text-align: center;

    &-top {
      font-weight: bold;
      font-size: 16px;
      color: #212121;
    }

    &-bottom {
      font-weight: 500;
      font-size: 15px;
      line-height: 12px;
      color: #212121;
      @media (max-width: 480px){
      font-size: 3.2vw;
      }
    }
  }

  &-right {
    text-align: center;
    &-top {
      font-weight: 500;
      font-size: 10px;
      color: #212121;
    }

    &-bottom {
      font-weight: bold;
      font-size: 16px;
      color: #212121;
      @media (max-width: 480px){
        font-size: 3.5vw;
      }
    }
  }

  &-chips {
    color: white;
    font-weight: 500;
    @media (max-width: 480px){
      font-size: 3vw;
    }
  }

  &-amount {
    color: #212121;
    font-weight: bold;
    font-size: 24px;
    @media (max-width: 480px){
        font-size: 5.5vw;
        font-weight: 900;
    }
    @media (max-width: 320px){
        font-size: 4.3vw;
    }
  }

  &-divider {
    border: 1px solid #00796b;
    transform: rotate(-63.43deg);
    width: 35px;
    height: 0;
    margin-top: 10px;
  }
}
</style>
