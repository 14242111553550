<template>
  <div>
    <Tab :tabs="tabs" @click="handleTabClick" class="tabs"></Tab>
    <PageContainer>
      <template v-if="(filteredOrdersByDeliveryDate && filteredOrdersByDeliveryDate.length > 0)">
        <OrderCard v-for="order in filteredOrdersByDeliveryDate" :orders-list="order" :key="order.id" />
      </template>
      <template v-else-if="(pagination.oldest_date_in_page && pagination.latest_date_in_page)">
        <div class="notify-message">
          <p>注文情報が見つかりませんでした</p>
          <p>
            {{ $date.YYYYMD(new Date(pagination.oldest_date_in_page), "/") }}〜{{ $date.YYYYMD(new
                Date(pagination.latest_date_in_page), "/")
            }}
          </p>
        </div>
      </template>
      <v-pagination class="pagination" v-model="page" :length="pagination.length"
        @input="handlePageChange"></v-pagination>
    </PageContainer>
  </div>
</template>

<script>
import PageContainer from "@/components/shop/PageContainer.vue";
import Tab from "@/components/shared/v2/Tab.vue";
import OrderCard from "@/components/shop/OrderCard.vue";

export default {
  components: {
    PageContainer,
    Tab,
    OrderCard
  },
  async created() {
    await this.loadOrders();
  },
  data() {
    return {
      tabs: [{
        id: "notDelivered",
        name: "未配送",
      },
      {
        id: "delivered",
        name: "配送済み",
      }],
      ordersGroupByDeliveryDate: [],
      isOnlyShipped: false,
      pagination: {},
      page: 1
    };
  },
  computed: {
    filteredOrdersByDeliveryDate() {
      const shippedStatuses = ["delivered"];
      return this.ordersGroupByDeliveryDate.map(ordersList => ({
        ...ordersList,
        orders: ordersList.orders.map(order => {
          const { order_items, ...res } = order;
          const orderItems = order.order_items.filter(order_item => shippedStatuses.includes(order_item.status) === this.isOnlyShipped);
          if (orderItems && orderItems.length > 0) res.order_items = orderItems;
          return res;
        })
      }));
    },
  },
  methods: {
    handleTabClick(e) {
      this.isOnlyShipped = e.id === "delivered";
    },
    async getOrders() {
      const response = await this.$http2(
        "get",
        "shop/order_items",
        {
          params: {
            page: this.page
          }
        }
      );
      return response;
    },
    async handlePageChange() {
      await this.loadOrders();
    },
    async loadOrders() {
      const response = await this.getOrders();
      const { pagination, orders_group_by_delivery_date } = response.result;
      this.pagination = pagination;
      this.page = pagination.current_page;
      this.ordersGroupByDeliveryDate = orders_group_by_delivery_date;
    }
  }
};
</script>

<style lang="scss" scoped>
.tabs {
  margin-bottom: 10px;
}

.notify-message {
  padding: 40px 0;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
}

::v-deep .v-pagination {
  & .v-pagination__item--active {
    background-color: #315198 !important;
  }

  & .v-pagination__navigation {
    background-color: #315198 !important;

    & .v-icon {
      color: #fff;
    }
  }
}
</style>
