<template>
  <div>
    <div class="d-flex align-center ma-3" @click="$emit('selectFlower', null)">
      <ArrowLeftIcon size="12" color="black" />
      <h4 class="ml-2 font-weight-normal">{{ $t("back_short") }}</h4>
    </div>
    <div class="ma-3">
      <div class="my-2 d-flex justify-space-between align-center">
        <div>
          <span v-if="!showSelect" class="badge-gray mr-2">{{ flower.category }}</span>
          <span class="genus-name">{{ flower.genus_name }}</span>
        </div>
      </div>
      <div class="font-weight-bold text-overflow-dot">{{ flower.specie_name }}</div>
    </div>
    <div class="mr-3 text-right">
      <h3 class="pointer color-secondary font-weight-normal underline" @click="setShowSelect()" >{{ showSelect ? $t("cancel") : $t("selection") }}</h3>
    </div>
    <div class="mobile-add-button" v-if="mobile && !showSelect">
      <MstFloatingButton :label="$t('add_harvest')" icon="mdi-plus" color="#173169" labelPosition="outside" to="/farm/flower_harvests/new" />
    </div>
    <div class="mobile-select-bar" v-if="mobile && showSelect && selectedItemIds.length > 0">
      <div>{{ selectedItemIds.length }}件の商品を選択中</div>
      <button @click="preStop">
        <img :src="require('@/assets/harvest_stop_btn.svg')" />
      </button>
    </div>
    <div class="table">
      <div class="d-flex pa-4 table-header-label">
        <div class="flex-shrink-1 flex-grow-1">
          <span>{{ $t("shipping_date") }}</span>
        </div>
        <div class="w-60px pl-1" @click="handleHeaderClick('stem_size')">
          <span>{{ $t("stem_size") }}</span>
          <img :src="require('@/assets/sort.svg')" class="table-header-sort"  :class="[sortObj['stem_size'].isDesc ? '' : 'flipped-sort']"/>
        </div>
        <div class="w-60px pl-1" @click="handleHeaderClick('price')">
          <span>{{ $t("price") }}</span>
          <img :src="require('@/assets/sort.svg')" class="table-header-sort" :class="[sortObj['price'].isDesc ? '' : 'flipped-sort']"/>
        </div>
        <div class="w-60px pl-1" @click="handleHeaderClick('stock_count')">
          <span>{{ $t("min_count_label") }}</span>
          <img :src="require('@/assets/sort.svg')" class="table-header-sort" :class="[sortObj['stock_count'].isDesc ? '' : 'flipped-sort']"/>
        </div>
        <div class="w-60px pl-1" @click="handleHeaderClick('min_count')">
          <span>{{ $t("set_count") }}</span>
          <img :src="require('@/assets/sort.svg')" class="table-header-sort" :class="[sortObj['min_count'].isDesc ? '' : 'flipped-sort']"/>
        </div>
        <div class="table-action">
          <input @click="onSelectedAll()" v-if="showSelect" class="checkbox" type="checkbox" :checked="allSelected" />
        </div>
      </div>
      <div class="d-flex px-4 py-3 table-row text-center" @click="!showSelect && onHandleTap(item.id)" v-for="item in showItems" :key="item.id">
        <div class="flex-shrink-1 flex-grow-1 mb-1 text-overflow-dot harvest-date"> {{ item.harvest_date }} </div>
        <div class="w-60px flex-shrink-0 flex-shrink-0"> <span class="table-value">{{ item.stem_size }}</span><span class="table-unit">{{ $t("size_label") }}</span> </div>
        <div class="w-60px flex-shrink-0 flex-shrink-0"> <span class="table-value">{{ item.price }}</span><span class="table-unit">{{ $t("yen") }}</span> </div>
        <div class="w-60px flex-shrink-0 flex-shrink-0"> <span class="table-value">{{ item.stock_count }}</span><span class="table-unit">{{ $t("stock_count_label") }}</span> </div>
        <div class="w-60px flex-shrink-0 flex-shrink-0"> <span class="table-value">{{ item.min_count }}</span><span class="table-unit">{{ $t("lot") }}</span> </div>
        <div class="table-action">
          <div v-if="showSelect" >
            <input @click="onSelect(item.id.toString())" class="checkbox" type="checkbox" :checked="selectedItemIds.includes(item.id.toString())" />
          </div>
          <div v-else >
            <ArrowRightIcon size="12" color="black" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="stopShow" class="modal">
      <div class="modal-content">
        <div class="card dialog-card">
          <img class="card-logo" :src="require('@/assets/harvest_stop.svg')" alt="Harvest Stop">
          <p class="dialog-header">{{ $t("harvest_stop_message_1") }}</p>
          <p class="dialog-text">{{ $t("harvest_stop_message_2") }}</p>
          <div class="flex-items">
            <MstButton :outlined="true" @click="cancelStop()">{{ $t("back_short") }}</MstButton>
            <MstButton @click="changeStatus()">{{ $t("stop_on_sale") }}</MstButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowLeftIcon from "@/components/icons/ArrowLeftIcon.vue";
import ArrowRightIcon from "@/components/icons/ArrowRightIcon.vue";
import { MstButton, MstFloatingButton } from "@/components/master";
import { formatDate } from "@/plugins/i18n";

export default {
  components: { ArrowLeftIcon, ArrowRightIcon, MstButton, MstFloatingButton },
  props: {
    flower: Object,
    flowerHarvests: Array,
  },
  data() {
    return {
      mobile: this.$vuetify.breakpoint.xs,
      showSelect: false,
      selectedItemIds: [],
      allSelected: false,
      sortObj: {
        stem_size: {
          selected: false,
          isDesc: true,
        },
        price: {
          selected: false,
          isDesc: true,
        },
        stock_count: {
          selected: false,
          isDesc: true,
        },
        min_count: {
          selected: false,
          isDesc: true,
        },
      },
      stopShow: false,
    };
  },
  methods: {
    onHandleTap(id) {
      this.$router.push(`/farm/flower_harvests/${id}`);
    },
    handleHeaderClick(item) {
      if (this.sortObj[item].selected) {
        this.sortObj[item].isDesc = !this.sortObj[item].isDesc;
      } else {
        this.initSortObj();
        this.sortObj[item].selected = true;
      }
    },
    initSortObj() {
      const keys = Object.keys(this.sortObj);
      keys.forEach(key => {
        this.sortObj[key].selected = false;
        this.sortObj[key].isDesc = true;
      });
    },
    setShowSelect() {
      this.showSelect = !this.showSelect;
    },
    onSelect(id) {
      if (!this.selectedItemIds.includes(id)) {
        this.selectedItemIds.push(id);
      } else {
        this.selectedItemIds = this.selectedItemIds.filter(item => item !== id);
      }
      this.allSelected = this.selectedItemIds.length === this.flowerHarvests.length;
    },
    onSelectedAll() {
      this.allSelected = !this.allSelected;
      if (this.allSelected) {
        const items = [...this.flowerHarvests];
        items.forEach(item => {
          if (!this.selectedItemIds.includes(item.id.toString())) {
            this.selectedItemIds.push(item.id.toString());
          }
        });
      } else {
        this.selectedItemIds.splice(0, this.selectedItemIds.length);
      }
    },
    changeStatus() {
      const payload = [];
      this.selectedItemIds.forEach(id => {
        const harvest = this.flowerHarvests.find(item => item.id.toString() === id);
        payload.push({
          id,
          status: harvest.status === "on_sale" ? "not_on_sale" : "on_sale"
        });
      });
      this.$store.dispatch("flowerHarvest/putHarvest", payload)
        .then(res => {
          if (res.status === 200) {
            this.$emit("selectFlower", this.flower);
            this.selectedItemIds = [];
            this.showSelect = false;
            this.stopShow = false;
          }
        });
    },
    preStop() {
      this.stopShow = true;
    },
    cancelStop() {
      this.stopShow = false;
    },
  },
  computed: {
    showItems() {
      const items = [...this.flowerHarvests];
      let sortItem = "id";
      let isSortDesc = true;
      Object.keys(this.sortObj).forEach(key => {
        if (this.sortObj[key].selected) {
          sortItem = key;
          isSortDesc = this.sortObj[key].isDesc;
        }
      });
      return items?.map(item => {
        item.harvest_date = formatDate(item.harvest_date, "mm/dd");
        return item;
      }).sort((a, b) => (isSortDesc ? -1 : 1) * (a[sortItem] > b[sortItem] ? 1 : -1));
    }
  }
};
</script>

<style lang="scss" scoped>
.table {
  font-family: sans-serif;
  font-style: normal;
  text-align: left;
  .table-row:nth-of-type(even) {
    background-color: #f5f5f5;
  }
  .w-60px {
    width: 60px;
  }
  .table-action {
    width: 20px;
    text-align: right;
    margin: auto;
  }
}
.table-header-label {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #111111;
}
.table-header-sort {
  margin: 2px 0 2px 4px;
}
.table-value {
  font-family: sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 19.36px;
  text-align: center;
  color: #173169;
}
.table-unit {
  font-family: sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 14.52px;
  text-align: center;
  color: #173169;
}
.text-overflow-dot {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.mobile-add-button {
  position: fixed;
  bottom: 100px;
  right: 15px;
  z-index: 100;
}
.mobile-select-bar {
  position: fixed;
  bottom: 75px;
  border-top: 1px solid #EAEAEA;
  z-index: 1;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  padding: 14px;
  background-color: white;
}
.badge-gray {
  font-weight: 400;
  font-size: 10px;
  height: 16px;
  background-color: #D7E4EB;
  border-radius: 100px;
  padding: 2px 10px 2px 10px;
}
.genus-name {
  font-size: 14px;
}
.flipped-sort {
  transform: rotate(180deg);
}
.modal {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}
.modal-content {
  background-color: #fefefe;
  margin: auto;
  border: 0 solid #888;
  border-radius: 8px;
  max-width: 344px;
}
.dialog-card {
  padding: 25px 52px;
  display: flex;
  flex-direction: column;
  height: 264px;
  width: 344px;
  margin: auto;
}
.card-logo {
  width: 42px;
  height: 46.67px;
  margin: auto;
  margin-top: 12px;
  margin-bottom: 12px;
}
.dialog-header {
  margin: auto;
  margin-top: 0;
  margin-bottom: 12px;
  font-weight: bold;
}
.dialog-text {
  margin-bottom: 12px;
  font-size: 14px;
}
.flex-items {
  display: flex;
  gap: 10px;
}
.harvest-date {
  font-family: sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #191D38;
}
.checkbox {
  width: 18px;
  height: 18px;
}

.text-center {
  text-align: center;
}
</style>
