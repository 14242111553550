<template>
  <div class="invoice-list">
    <p class="title my-0 ml-4">お支払い情報 (税込み表示)</p>

    <v-row class="my-2 mx-4">
      <v-col cols="6">
        <v-select
          v-model="targetYear"
          :items="targetYearOptions"
          style="width: 200px"
          solo
          hide-details
        />
      </v-col>
      <v-col cols="6" class="d-flex justify-end align-center">
        <Button
          v-if="targetYear"
          :click="getInvoicePdfList"
          :loading="loading"
          loadingText="ダウンロード中です"
          backgroundColor="#FF8C71"
          small
        >
        <template v-slot:label>
          {{ targetYear }}年分の請求書を<br />まとめてダウンロード
        </template>
        </Button>
      </v-col>
    </v-row>

    <v-list
      three-line
      class="pa-0"
    >
      <template
        v-for="(invoice, index) in invoices"
      >
        <v-list-item
          :key="index"
          class="invoice-list-item"
        >
          <v-list-item-content class="mx-4">
            <div class="item-content d-flex justify-space-between">
              <div class="left">
                <v-list-item-title class="mb-2">{{ format(invoice.start_date) }} ~ {{ format(invoice.end_date) }}</v-list-item-title>
                <v-list-item-subtitle><span class="price">¥ {{ $n(invoice.total_sales_price_with_tax) }}（内送料¥ {{ $n(invoice.total_delivery_fee) }}）</span></v-list-item-subtitle>
              </div>
              <div class="middle">
                <checked-icon v-if="invoice.paid" color="green" align-content="center"/>
                <v-chip
                  v-else-if="invoice.billing_amount_with_tax === 0"
                  x-small
                  label
                  color="grey lighten-1"
                  text-color="white"
                  class="pa-1"
                >
                  取引なし
                </v-chip>
              </div>
              <div class="right">
                <v-btn @click="toPdf(invoice.start_date, invoice.end_date)" :disabled="invoice.total_sales_price_with_tax === 0"
                height="30px !important" x-small outlined color="secondary">請求書を見る</v-btn>
                <v-btn v-if="invoice.paid"
                  @click="toReceipt(invoice.start_date, invoice.end_date)"
                  :style="mobile || tablet ? 'margin-top: 10px' : 'margin-left: 10px'"
                  height="30px !important"
                  x-small
                  outlined
                  color="primary"
                >
                  領収書を見る
                </v-btn>
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </div>
</template>

<script>
import Button from "@/components/shared/Button.vue";
import CheckedIcon from "@/components/icons/CheckedIcon.vue";
import { formatDate } from "@/plugins/i18n";

export default {
  components: {
    Button,
    CheckedIcon
  },
  data() {
    return {
      params: "",
      targetYear: 0,
      targetYearOptions: [],
      invoices: [],
      loading: false
    };
  },

  watch: {
    targetYear() {
      this.getInvoiceList();
    }
  },

  async created() {
    const d = new Date();
    const year = d.getFullYear();

    this.targetYear = year;
    this.getInvoiceList();
  },

  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    tablet() {
      return this.$vuetify.breakpoint.sm;
    }
  },

  methods: {
    format(date) {
      const d = new Date(date);
      return formatDate(d, "m月d日");
    },
    async toPdf(start_date, end_date) {
      await this.$api2.get(`/shop/invoices/invoice_pdf.pdf?start_date=${start_date}&end_date=${end_date}`, { responseType: "blob" }).then(response => {
        const blob = new Blob([response], { type: "application/pdf" });
        const objectUrl = URL.createObjectURL(blob);
        this.pdfsrc = objectUrl;
        window.location.href = this.pdfsrc;
      });
    },
    async toReceipt(start_date, end_date) {
      await this.$api2.get(`/shop/invoices/receipt_pdf.pdf?start_date=${start_date}&end_date=${end_date}`, { responseType: "blob" }).then(response => {
        const blob = new Blob([response], { type: "application/pdf" });
        const objectUrl = URL.createObjectURL(blob);
        this.pdfsrc = objectUrl;
        window.location.href = this.pdfsrc;
      });
    },
    async getInvoiceList() {
      const res = await this.$http2("get", `shop/invoices?year=${this.targetYear}`);

      const options = [];
      res.result.year_options.forEach(year => {
        options.push({
          text: `${year}年`,
          value: year
        });
      });

      this.invoices = res.result.invoice_list;
      this.targetYearOptions = options;
    },
    async getInvoicePdfList() {
      this.loading = true;
      await this.$api2.get(`/shop/invoices/invoice_pdf_list?year=${this.targetYear}`, { responseType: "blob" }).then(response => {
        const blob = new Blob([response], { type: "application/zip" });
        const objectUrl = URL.createObjectURL(blob);
        this.zipsrc = objectUrl;
        window.location.href = this.zipsrc;
        this.loading = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  text-align: left;
  font-weight: bold;
  font-size: 16px !important;
}

.invoice-list {
  &-item {
    height: 4rem;
    font: Bold 1rem Helvetica Neue;
    letter-spacing: 0.38px;
    color: #333333;
    margin: 0 auto;
    border-bottom: 1px solid #bbb;

    &:last-child {
      border-bottom: none;
    }
  }

  .item-content {
    width: 100%;

    .left {
      width: 50%;
    }

    .middle {
      width: 20%;
      padding: 20px 0;
    }

    .right {
      width: 30%;
      text-align: center;
      margin: auto;
    }

    .price {
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
    }
  }
}
</style>
