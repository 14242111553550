let lang = "ja";

const list = {
  welcome_landpage: { ja: "CAVIN（キャビン）へようこそ", en: "Welcome" },
  welcome: { ja: "ようこそ", en: "Welcome" },
  welcome_back: { ja: "おかえりなさい。", en: "Welcome back" },
  click_here_farm: { ja: "農家/農園の方はこちら", en: "Click here for farmers" },
  click_here_shop: { ja: "花屋の方はこちら", en: "Click here for shops" },
  click_here_driver: { ja: "配送業者の方はこちら", en: "Click here for delivery service provider" },
  flower_registration_pricing: { ja: "お花の登録や料金設定", en: "Flower registration and pricing" },
  purchase_flowers_feedback: { ja: "お花の購入やフィードバック", en: "Purchase flowers and feedback" },
  delivery_status_check: { ja: "配送状況確認", en: "Delivery status check" },
  login: { ja: "ログイン", en: "Login" },
  login_is: { ja: "ログインは", en: "Login" },
  login_btn: { ja: "ログインする", en: "Login" },
  login_error: { ja: "メールアドレスまたはパスワードが違います。" },
  login_limit_message1: { ja: "未払いのお取引があるため、現在アカウントを制限しております。" },
  login_limit_message2: { ja: "以下のボタンよりCAVIN公式LINEにてお問い合せください。" },
  logout: { ja: "ログアウト", en: "Logout" },
  email: { ja: "メールアドレス", en: "Email" },
  pwd: { ja: "パスワード", en: "Password" },
  password: { ja: "パスワード（半角英数6文字以上）", en: "Password" },
  confirm_password: { ja: "パスワード（再入力）", en: "Confirm Password" },
  new_pwd: { ja: "新しいパスワード", en: "New password" },
  confirm_new_pwd: { ja: "新しいパスワード（確認用）", en: "Confirm new password" },
  pwd_mismatch: { ja: "パスワードが一致しません", en: "The passwords do not match" },
  forgot_pwd: { ja: "パスワードをお忘れの方は", en: "Forgot password" },
  show_pwd: { ja: "パスワード表示", en: "Show password" },
  email_already_registered: { ja: "すでに登録された電子メール", en: "Email already registered" },
  here: { ja: "こちら", en: "here" },
  signup: { ja: "新規会員登録は", en: "Signup" },
  for_admin: { ja: "CAVINスタッフは", en: "Admin" },
  new_member_registration: { ja: "新規会員登録", en: "New member registration" },
  company_business_name: { ja: "企業名または事業屋号", en: "Company or business's name" },
  settings: { ja: "マイページ", en: "Settings" },
  sama: { ja: "様", en: " " },
  onchu: { ja: "御中", en: " " },
  home: { ja: "ホーム", en: "Home" },
  farmDashboard: { ja: "トップ", en: "Dashboard" },
  farmHarvest: { ja: "出品", en: "Harvests" },
  farmFlowersGenera: { ja: "商品管理", en: "Flowers" },
  farmOrderItems: { ja: "出荷", en: "Orders" },
  farmConversations: { ja: "メッセージ", en: "Message" },
  farmSaleStatement: { ja: "売上明細書", en: "Farm Sales Statement" },
  farmReceipt: { ja: "受領証", en: "Farm Receipt" },
  shopOrders: { ja: "取引履歴", en: "Order history" },
  shopProducts: { ja: "商品一覧", en: "Product's list" },
  search_by_farms: { ja: "生産者から探す", en: "Search by farms" },
  send_message_to_farm: { ja: "メッセージを送る", en: "Send Message to farm" },
  shop_invoice: { ja: "請求書", en: "Shop Invoice" },
  listRegistredProducts: { ja: "商品登録", en: "List of products" },
  add_product: { ja: "商品を追加", en: "Add product" },
  edit_product: { ja: "商品を編集", en: "Edit product" },
  help: { ja: "ヘルプ", en: "Help" },
  line_support: { ja: "LINE公式アカウント", en: "Line Support" },
  line_contact: { ja: "LINEでお問い合わせ", en: "Contact us via LINE" },
  message: { ja: "メッセージ", en: "Message" },
  shopCollections: { ja: "コレクション", en: "Collection" },
  edit: { ja: "入力する", en: "Edit" },
  quick_edit: { ja: "クイック編集", en: "Edit" },
  save: { ja: "保存", en: "Save" },
  save_flower_harvest: { ja: "出品する", en: "Save" },
  save_changes: { ja: "変更を保存", en: "Save" },
  back: { ja: "一覧に戻る", en: "Back" },
  back_short: { ja: "戻る", en: "Back" },
  customer: { ja: "注文花屋者", en: "Customer" },
  id: { ja: "注文番号" },
  order: { ja: "取引番号", en: "Order" },
  order_datetime: { ja: "注文日時", en: "Order datetime" },
  shipping_date: { ja: "出荷日", en: "Shipping date" },
  delivery_date: { ja: "納品日", en: "Delivery date" },
  expected_date: { ja: "お届け予定日 ", en: "Delivery date" },
  please_select: { ja: "選択してください ", en: "Please select" },
  delete: { ja: "削除", en: "Delete" },
  upload_image: { ja: "画像をアップロード", en: "Upload image" },
  confirm: { ja: "確定", en: "Confirm" },
  unconfirm: { ja: "未確定", en: "Unconfirm" },
  payment_deadline: { ja: "お支払い期限" },
  until_15: { ja: "今月15日迄" },
  read: { ja: "既読" },
  tax_included: { ja: "税込" },
  tax_excluded: { ja: "税抜" },
  kana: { ja: "フリガナ" },
  kana_input: { ja: "フリガナを入力してください", en: "Please enter furigana" },
  kana_error: { ja: "フリガナはカタカナで入力してください。", en: "Please enter furigana in katakana." },
  species_input: { ja: "品種名を入力してください", en: "Please enter species name." },
  only_kana: { ja: "カタカナで入力してください" },
  driverInformation: { ja: "配送情報", en: "Information" },
  driverHistory: { ja: "履歴検索", en: "History search" },
  driverLabel: { ja: "物流ラベル発行", en: "label" },
  driverList: { ja: "ドライバー情報", en: "Driver list" },
  previous_month: { ja: "前の月", en: "Prev Month" },
  next_month: { ja: "次の月", en: "Next Month" },
  selection: { ja: "選択", en: "Selection" },
  loading: { ja: "読み込み中", en: "Loading" },

  // Harvests
  image_add_edit: { ja: "画像を追加・編集" },
  price_unit: { ja: "単価（1本）" },
  recommend_price: { ja: "おすすめの価格" },
  product_description: { ja: "商品説明" },
  harvest_edit: { ja: "編集" },
  harvest_confirm_message_1: { ja: "この写真・内容で出品しますか？" },
  harvest_confirm_message_2: { ja: "正確な情報を入力することで花屋さんが安心して購入できます。" },
  harvest_success_message_1: { ja: "ありがとうございます" },
  harvest_success_message_2: { ja: "出品しました！" },
  harvest_stop_message_1: { ja: "出品停止しますか？" },
  harvest_stop_message_2: { ja: "出品停止するとこの商品は花屋から見えなくなります。" },
  harvest_reopen_message_1: { ja: "出品を再開しますか？" },
  harvest_reopen_message_2: { ja: "出品を再開すると花屋はこの商品を購入できるようになります。" },
  harvest_number_error_msg: { ja: "マイナスの値が入力できないです" },
  harvest_date_error_msg: { ja: "過去の日付が選択できないです" },
  re_save_flower_harvest: { ja: "出品を再開する" },
  harvest_image_edit: { ja: "画像の追加・編集" },
  harvest_image_edit_desc: { ja: "画像の変更は品目・品種・規格が同じ全ての商品に反映されます。" },
  harvest_image_add: { ja: "画像を追加" },
  add_harvest: { ja: "新規出品", en: "Add harvest" },
  genus_specie: { ja: "品目/品種", en: "Genus/Specie" },
  min_count_label: { ja: "ロット", en: "Lot" },
  stock_count_label: { ja: "本/口", en: "Stock" },
  farmFlowerHarvest: { ja: "出品", en: "Harvests" },
  reopen_on_stop: { ja: "再開", en: "Reopen" },

  // Flower types
  single: { ja: "シングル", en: "Single" },
  spray: { ja: "スプレー", en: "Spray" },

  // Flowers
  is_working: { ja: "出品画面への表示", en: "View" },
  working: { ja: "表示", en: "View" },
  no_working: { ja: "表示中の商品はありません。", en: "No working flowers" },
  not_working: { ja: "非表示にした商品", en: "View to Hidden Products" },
  no_not_working_flowers: { ja: "非表示中の商品はありません。", en: "No not working flowers" },
  hide_product: { ja: "商品を非表示にする", en: "Hide Product" },
  display_product: { ja: "商品を表示する", en: "Display Product" },
  hidden_list: { ja: "非表示リスト", en: "Hidden List" },
  no_products_matching: { ja: "キーワードに一致する商品がありません", en: "There are no products matching the keyword" },
  hidden: { ja: "非表示中", en: "hidden" },
  not_found_species: { ja: "お探しの品種は見つかりませんでした", en: "There are no species matching the keyword" },
  not_found_genus: { ja: "お探しの品目は見つかりませんでした", en: "There are no genus matching the keyword" },
  display_selected_product: { ja: "選択した商品を表示", en: "Display selected product" },

  product_info: { ja: "商品情報", en: "Product information" },
  flower_type: { ja: "タイプ", en: "Type" },
  flower_count: { ja: "登録数", en: "Count" },
  chevron_right: { ja: " ", en: "" },
  flower_harvest_count: { ja: "出品数", en: "Count" },
  base_price: { ja: "基準価格", en: "Base price" },
  total_price: { ja: "合計金額", en: "Total price" },
  total: { ja: "合計", en: "Total" },
  average_price: { ja: "平均価格", en: "Average price" },
  lowest_price: { ja: "最低価格", en: "Lowest price" },
  average_price_label: { ja: "平均価格", en: "Average price" },
  asc_price: { ja: "値段の安い順" },
  desc_price: { ja: "値段の高い順" },
  image: { ja: "画像", en: "Image" },
  img_limit_size: { ja: "※最大10MBまで", en: "*max 10MB" },
  drag_image_to_upload: { ja: "画像をドラッグしてアップロードしてください。", en: "Drag image to upload" },
  image_size_over: { ja: "画像サイズがオーバーしています", en: " " },
  image_wrong_format: { ja: "JPG、JPEG、PNGのいずれかの画像ファイルのみです", en: " " },
  set_pwd: { ja: "パスワード設定", en: "Set password" },
  set: { ja: "設定する", en: "Save" },
  any: { ja: "任意" },
  external_app: { ja: "各種サービス連携" },
  line_app: { ja: "LINEアカウント連携" },
  click_for_details: { ja: "詳細はこちらから" },
  linked: { ja: "連携済み" },
  link_acc: { ja: "連携する" },
  error_confirmed_count: { ja: "総本数（ロット本数×口数）は10桁未満に設定してください。", en: "The total number (lot count x number of units) must be less than 10 digits." },
  harvest_over_int_error_msg: { ja: "10桁未満に設定してください。", en: "Must be less than 10 digits." },
  harvest_over_float_error_msg: { ja: "7桁未満に設定してください。", en: "Must be less than 7 digits." },
  monday: { ja: "月曜", en: "" },
  tuesday: { ja: "火曜日", en: "" },
  wednesday: { ja: "水曜日", en: "" },
  thursday: { ja: "木曜日", en: "" },
  friday: { ja: "金曜日", en: "" },
  saturday: { ja: "土曜日", en: "" },
  sunday: { ja: "日曜日", en: "" },
  short_monday: { ja: "月", en: "" },
  short_tuesday: { ja: "火", en: "" },
  short_wednesday: { ja: "水", en: "" },
  short_thursday: { ja: "木", en: "" },
  short_friday: { ja: "金", en: "" },
  short_saturday: { ja: "土", en: "" },
  short_sunday: { ja: "日", en: "" },

  item: { ja: "品目" },
  category: { ja: "カテゴリ" },
  genus_name: { ja: "品目名" },
  flower_specie: { ja: "品種名" },
  search_by_flower_specie: { ja: "品種名で検索" },
  search_by_delivery_date: { ja: "納品日で検索" },
  select_category: { ja: "カテゴリを選択してください" },
  select_flower_genus: { ja: "品目を選択してください" },
  select_flower_specie: { ja: "品種を選択する" },
  select_flower: { ja: "商品を選択する" },
  color: { ja: "色" },
  price: { ja: "価格" },
  unit_price: { ja: "単価" },
  date: { ja: "収穫日" },
  order_id: { ja: "注文番号" },
  shop_name: { ja: "注文花屋" },
  subtotal_count: { ja: "本数" },
  remarks: { ja: "備考" },
  confirmed_count: { ja: "出品総本数" },
  sales_rate: { ja: "販売率" },
  min_count: { ja: "ロット本数" },
  set_count: { ja: "口数" },
  default_min_count: { ja: "最小ロット本数" },
  todays_sale: { ja: "今日の売上：" },
  reject_order: { ja: "断る" },
  confirm_order: { ja: "確認済にする" },
  cancel: { ja: "キャンセル" },
  approve: { ja: "承認する" },
  approval: { ja: "承認" },
  mark_shipped: { ja: "発送済にする" },
  view_by_transaction: { ja: "取引ごとに見る" },
  not_picked_up: { ja: "未集荷" },
  request_approval: { ja: "リクエストの承認" },
  cannot_be_undone_later: { ja: "この操作は後で取り消すことができないのでご注意ください。" },
  mark_as_shipped: { ja: "発送済にしますか？" },
  editable: { ja: "編集可能" },
  editable_flower_harvest: { ja: "編集可能" },
  not_editable_flower_harvest: { ja: "編集不可" },
  flower_harvest_status: { ja: "出品状況" },
  on_sale: { ja: "出品中" },
  stop_on_sale: { ja: "出品停止" },
  not_on_sale: { ja: "未出品" },
  stem_size: { ja: "全長" },
  size_label: { ja: "cm" },
  about_size_label: { ja: "cm程度" },
  invalid_zip: { ja: "7桁の数字を入力してください" },
  invalid_phone: { ja: "9〜11桁の数字を入力してください" },
  invalid_municipality: { ja: "空白なしで入力してください" },
  invalid_address: { ja: "空白なしで入力してください" },
  required: { ja: "入力していない項目があります" },

  unconfirmed: { ja: "未確定" },
  confirmed: { ja: "確定" },
  request: { ja: "リクエスト" },
  unshipped: { ja: "未発送" },
  purchase: { ja: "購入分" },
  yen: { ja: "円" },
  lot: { ja: "口" },
  volumn: { ja: "本" },
  from: { ja: "から" },
  include_shipping: { ja: "うち送料" },
  piece: { ja: "件" },
  request_only: { ja: "リクエスト可能" },
  order_title: { ja: "並び順を指定する" },
  recommended_order: { ja: " おすすめ順" },
  low_price_order: { ja: "価格が安い順" },
  high_price_order: { ja: "価格が高い順" },
  min_count_per_unit: { ja: count => `1口${count}本から` },
  delivery_fee: { ja: fee => `(うち送料${fee}円)` },
  stock_order: { ja: "在庫あり" },
  packed: { ja: "梱包完了" },
  shipped: { ja: "発送済" },
  delivered: { ja: "納品完了" },
  canceled: { ja: "キャンセル済み" },
  rollback: { ja: "キャンセル処理" },
  return_unconfirmed: { ja: "未確認にする" },
  return_confirmed: { ja: "確認済みにする" },
  return_packing: { ja: "梱包完了に戻す" },
  complete_packing: { ja: "梱包完了にする" },
  complete_shipping: { ja: "発送完了にする" },
  order_status_unloaded: { ja: "拠点着" },
  order_status_confirmed: { ja: "未集荷" },
  order_status_shipped: { ja: "集荷済" },
  order_status_picked: { ja: "配送中" },
  order_status_delivered: { ja: "納品済" },
  order_status_canceled: { ja: "キャンセル" },

  free_shipping: { ja: "送料無料" },
  delivery_address: { ja: "納品先" },
  delivery_this_address: { ja: "この住所にお届け" },
  shipping_address: { ja: "配送先住所" },
  add_shipping_address: { ja: "配送先を追加" },
  del_shipping_address: { ja: "この配送先を削除" },

  deadline: { ja: "出品締め切り時刻：", en: "Deadline" },
  add_to_cart: { ja: "カートに入れる", en: "Add to cart" },
  purchace_now: { ja: "いますぐ購入する", en: "1 click purchace" },
  purchace_now_after_confirm: { ja: "内容を確認をして今すぐ購入する", en: "1 click purchace" },
  complete_order: { ja: "内容を確定する", en: "Complete Order" },
  complete_purchace: { ja: "完了しました！", en: "Complete Purchace and Request Order" },
  added_to_cart: {
    ja: [
      "生産者さんの想いを乗せてお届けさせていただきます",
      "まだ注文完了していません、購入手続きはカートをクリック!",
      "注文完了まであと少し💨",
      "カートにお花が入りました✨カートをクリックしてお花を受け取りましょう💐"
    ],
    en: "Added to cart"
  },
  add_to_cart_error: { ja: "カートへの追加に失敗しました" },
  unbuyable_area_error: { ja: "購入ができない地域の商品です" },
  purchase_unbuyable_area_error: { ja: "は購入ができない地域の商品です。カートから削除してください。" },
  purchase_error: { ja: "購入に失敗しました。" },
  change_count: { ja: "購入本数を変更しました" },
  change_count_error: { ja: "数量の変更に失敗しました" },
  remove_from_list: { ja: "一覧から削除" },
  order_total_amount_plain: { ja: "注文合計金額", en: "" },
  schedule_ship_today: { ja: count => `本日出荷予定：${count}件` },
  new_orders: { ja: orders => `新規注文：${orders}件` },
  new_reservations: { ja: orders => `新規リクエスト：${orders}件` },
  new_messages: { ja: count => `新しいメッセージが${count}件あります。` },
  show_shipping_results: { ja: "出荷実績" },
  download_report: { ja: "売上金額" },
  view_cart: { ja: count => `カートの中を見る（<strong>${count}</strong>）` },
  shopCart: { ja: "カート" },
  shopConversations: { ja: "メッセージ" },
  shipped_by: { ja: (date, hour) => `${date}${hour}までに発送する商品になります` },
  order_total_amount: { ja: amount => `注文合計金額${amount}円` },
  order_unconfirmed: { ja: count => ` リクエスト（${count}）` },
  order_confirmed: { ja: count => `確定（${count}）` },
  order_packed: { ja: count => `梱包完了（${count}）` },
  order_shipped: { ja: count => `発送済（${count}）` },
  order_delivered: { ja: count => `納品（${count}）` },
  order_canceled: { ja: count => `cancelled（${count}）` },
  order_rollback: { ja: count => `rollback（${count}）` },
  sales_of_month: { ja: (start, end) => `今月の売上（${start}〜${end}）：` },
  up_to: { ja: (from, to) => `（※${from}〜${to}本まで）` },
  normal_order_count: { ja: "通常取引口数" },
  request_count: { ja: "リクエスト口数" },
  request_unconfirmed: { ja: "リクエスト中" },
  request_rejected: { ja: "リクエスト不成立" },
  add: { ja: "追加する" },
  added: { ja: "" },
  add_image: { ja: "画像を追加", en: "Add image" },
  add_another_standard: { ja: "カスタム出品", en: "Add another standard" },
  custom_harvest_date: { ja: "出品日をカスタマイズ", en: "Add custom harvest date" },
  unspecified: { ja: "指定なし" },
  search_by_name: { ja: "品目名で検索" },
  search: { ja: "絞り込み" },
  search_for: { ja: "検索する" },
  clear: { ja: "クリア" },
  farm: { ja: "生産者" },
  farm_info: { ja: "農園情報" },
  producing_area: { ja: "生産地" },
  account_info: { ja: "アカウント情報" },
  florist_info: { ja: "花屋情報" },
  store_name: { ja: "店舗名" },
  representative_name: { ja: "代表者または担当者名" },
  view_payment_info: { ja: "お支払い情報を見る" },
  phone_number: { ja: "電話番号" },
  q_running_farm: { ja: "どういった農園をお持ちですか？" },
  in_door: { ja: "室内" },
  out_door: { ja: "屋内" },
  q_answer_the_questions: { ja: "以下の質問にご回答ください。" },
  q_vinyl_house_count: { ja: "ビニールハウスの個数はいくつですか？" },
  q_size_of_farm: { ja: "農園の広さ（合計）はどれくらいですか？" },
  number_of_vinyl_house: { ja: "ビニールハウスの個数" },
  size_of_farm: { ja: "農園の広さ（合計）" },
  close_toast: { ja: "" },
  updated: { ja: "" },
  note: { ja: "備考" },
  select: { ja: "選択する" },
  bulk_select: { ja: "一括選択" },
  send: { ja: "送信する" },
  upload_files: { ja: "ファイルをアップロードする" },
  enter_msg: { ja: "メッセージを入力" },
  copy: { ja: "前回コピー" },
  copy_all: { ja: "前回と同じ数値を入力する" },
  items_per_page: { ja: "表示件数" },
  all: { ja: "全て" },
  transfer_amount: { ja: "お振り込み金額（税込）" },
  next_payment_amount: { ja: "次回お支払い予定金額" },
  your_order: { ja: "納品分" },
  no_data: { ja: "注文情報がありません" },
  no_product_found: { ja: "お探しのキーワードを含む商品がございませんでした" },
  earliest_delivery_date: { ja: "最短納品日" },
  earliest_delivery_date_label: { ja: "最短納品日" },
  only_request: { ja: "リクエスト可能" },
  group_by_order_item: { ja: "規格ごとに見る" },
  group_by_shop: { ja: "花屋ごとに見る" },
  standard: { ja: "規格" },
  area_required: { ja: "商品を閲覧するにはエリアの設定が必要です。CAVINへお問い合わせは" },

  date_date: { ja: "yyyy/m/d" },
  date_datetime: { ja: "yyyy/m/d h:MM" },
  date_shortDate: { ja: "m/d" },
  date_shortDateTime: { ja: "m/d h:MM" },
  date_day: { ja: "ddd" },
  date_select: { ja: "m/d (ddd)" },
  date_select_full: { ja: "yyyy/mm/dd (ddd)" },
  date_fortnight: { ja: "m/d" },
  date_fortnight_deadline: { ja: "m/d迄" },
  date_normalDate: { ja: "m月d日" },
  delivery: { ja: "お届け分" },
  specie: { ja: "品種" },
  choose_genus: { ja: "品目を選択" },
  choose_specie: { ja: "品種を選択" },
  choose_farm: { ja: "生産者を選択" },
  filter_criteria: { ja: "絞り込み条件" },
  unselected: { ja: "未選択" },
  all_1: { ja: "すべて" }
};

export function setLang(newLang) {
  lang = newLang;
}

export default function t(word) {
  return list[word] && list[word][lang] ? list[word][lang] : `::${word}::`;
}

const dayNames = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

export function formatDate(date, format) {
  if (typeof date === "string") {
    date = new Date(date);
  }

  return format.replace(/dddd|ddd|yyyy|mm|m|dd|d|hh|h|MM|M/g, word => {
    switch (word) {
      case "dddd":
        return t(dayNames[date.getDay()]);
      case "ddd":
        return t(`short_${dayNames[date.getDay()]}`);
      case "yy":
        return String(date.getFullYear()).substr(2, 4);
      case "yyyy":
        return date.getFullYear();
      case "mm":
        return String(date.getMonth() + 1).padStart(2, "0");
      case "m":
        return date.getMonth() + 1;
      case "dd":
        return String(date.getDate()).padStart(2, "0");
      case "d":
        return date.getDate();
      case "hh":
        return String(date.getHours()).padStart(2, "0");
      case "h":
        return date.getHours();
      case "MM":
        return String(date.getMinutes()).padStart(2, "0");
      case "M":
        return date.getMinutes();
      default:
        return "";
    }
  });
}

export function currency(number) {
  return new Intl.NumberFormat("ja-JP", {
    style: "currency",
    currency: "JPY",
  }).format(number);
}

export function calcFortnight(previousPeriod, isDeadline) {
  const d1 = new Date();
  let d2 = new Date();

  if (d1.getDate() < 16) {
    if (previousPeriod) {
      d1.setDate(16);
      d1.setMonth(d1.getMonth() - 1);
      d2 = new Date(d2.getFullYear(), d2.getMonth(), 0);
    } else {
      d1.setDate(1);
      d2.setDate(15);
    }
  } else if (previousPeriod) {
    d1.setDate(1);
    d2.setDate(15);
  } else {
    d1.setDate(16);
    d2 = new Date(d2.getFullYear(), d2.getMonth() + 1, 0);
  }

  if (isDeadline) {
    if (d1.getDate() < 16) {
      d2 = new Date(d2.getFullYear(), d2.getMonth() + 1, 0);
    } else {
      d2.setDate(d2.getDate() + 15);
    }

    return formatDate(d2, t("date_fortnight_deadline"));
  }

  return `${formatDate(d1, t("date_fortnight"))}~${formatDate(d2, t("date_fortnight"))} ${t("your_order")}`;
}

export function localeDate(date, format) {
  return formatDate(date, t(`date_${format}`));
}

export function localeNumber(num) {
  return num ? num.toLocaleString(lang) : 0;
}
