import getRandomInt from "../helpers/getRandomInt";

export default {
  namespaced: true,
  state: {
    draft: null,
    groupedDraft: null
  },
  mutations: {
    setDraft(state, payload) {
      state.draft = payload;
    },
    setDraftGroupByDeliveryDate(state, payload) {
      state.groupedDraft = payload;
    }
  },
  actions: {
    async getDraftOrder({ commit }) {
      const res = await this._vm.$http("get", "orders/draft");
      if (res.status === 200) {
        commit("setDraft", res.result.order);
      }
    },
    async getDraftOrdersGroupByDeliveryDate({ commit }) {
      const res = await this._vm.$http2("get", "shop/orders/draft");
      if (res.status === 200) {
        commit("setDraftGroupByDeliveryDate", res.result.order);
      }
    },
    async notifyAddCart({ dispatch }) {
      dispatch("setSnackBar", { msg: this._vm.$t("added_to_cart")[getRandomInt(0, this._vm.$t("added_to_cart").length)], color: "green" }, { root: true });
      dispatch("snackOn", {}, { root: true });
    },
    async notifyChangeCount({ dispatch }) {
      dispatch("setSnackBar", { msg: this._vm.$t("change_count"), color: "green" }, { root: true });
      dispatch("snackOn", {}, { root: true });
    },
    async notifyPurchaceComplete({ dispatch }) {
      dispatch("setSnackBar", { msg: this._vm.$t("complete_purchace"), color: "green" }, { root: true });
      dispatch("snackOn", {}, { root: true });
    },
    async notifyAddToCartError({ dispatch }) {
      dispatch("setSnackBar", { msg: this._vm.$t("add_to_cart_error"), color: "red" }, { root: true });
      dispatch("snackOn", {}, { root: true });
    },
    async notifyUnbuyableAreaError({ dispatch }) {
      dispatch("setSnackBar", { msg: this._vm.$t("unbuyable_area_error"), color: "red" }, { root: true });
      dispatch("snackOn", {}, { root: true });
    },
    async notifyPurchaseError({ dispatch }) {
      dispatch("setSnackBar", { msg: this._vm.$t("purchase_error"), color: "red" }, { root: true });
      dispatch("snackOn", {}, { root: true });
    },
    async notifyPurchaseUnbuyableAreaError({ dispatch }, specie_list) {
      const error_msg = specie_list.join(", ") + this._vm.$t("purchase_unbuyable_area_error");
      dispatch("setSnackBar", { msg: error_msg, color: "red" }, { root: true });
      dispatch("snackOn", {}, { root: true });
    },
    async notifyChangeCountError({ dispatch }) {
      dispatch("setSnackBar", { msg: this._vm.$t("change_count_error"), color: "red" }, { root: true });
      dispatch("snackOn", {}, { root: true });
    },
    async changeLocation({ state, commit }, location_id) {
      const res = await this._vm.$http("put", "orders/draft", { order: { location_id } });

      if (res.status === 200) {
        const newState = {
          ...state.draft,
          location: { ...state.draft.location, id: location_id }
        };

        commit("setDraft", newState);
      }
    },
    async changeCount({ state, commit }, { id, lotCount, subtotalCount, flowerHarvestId }) {
      const data = {
        order_item: { lot_count: lotCount }
      };

      if (flowerHarvestId) {
        data.order_item.flower_harvest_id = flowerHarvestId;
      }

      const res = await this._vm.$http("put", `order_items/${id}`, data);

      if (res.status === 200) {
        const newDraft = { ...state.draft };
        const idx = newDraft.order_items.findIndex(x => x.id === id);
        newDraft.order_items[idx].lot_count = lotCount;
        newDraft.order_items[idx].subtotal_count = subtotalCount;
        newDraft.order_items[idx].subtotal_sales_price_without_tax = subtotalCount * newDraft.order_items[idx].flower_harvest.sales_price;

        newDraft.not_request_subtotal_sales_price_without_tax = newDraft.order_items.reduce((acc, order_item) => {
          if (order_item.order_item_type !== "request") {
            return acc + order_item.subtotal_sales_price_without_tax;
          }
          return acc;
        }, 0);

        newDraft.request_subtotal_sales_price_without_tax = newDraft.order_items.reduce((acc, order_item) => {
          if (order_item.order_item_type === "request") {
            return acc + order_item.subtotal_sales_price_without_tax;
          }
          return acc;
        }, 0);

        newDraft.subtotal_sales_price_without_tax = newDraft.order_items.reduce((acc, order_item) => acc + order_item.subtotal_sales_price_without_tax, 0);

        commit("setDraft", newDraft);
      }

      return res.status;
    },
    async deleteOrderItem({ dispatch }, orderItem) {
      await this._vm.$http("delete", `order_items/${orderItem.id}`);
      dispatch("getDraftOrdersGroupByDeliveryDate");
      dispatch("getDraftOrder");
    },
  },
  getters: {
    draftCount(state) {
      return state.draft ? state.draft.order_items.length : 0;
    },
    draftOrder(state) {
      return state.draft;
    },
    groupedDraftOrder(state) {
      return state.groupedDraft;
    },
    harvestIsInCart: state => flower_harvest_id => (
      state.draft && state.draft.order_items.some(item => item.flower_harvest.id === Number(flower_harvest_id) && item.order_item_type !== "request")
    ),
    normalOrderItem: state => flower_harvest_id => {
      if (!state.draft || !state.draft.order_items || !state.draft.order_items.length) return false;
      const orderItem = state.draft.order_items.find(item => item.flower_harvest.id === Number(flower_harvest_id) && item.order_item_type !== "request");
      return orderItem;
    },
  }
};
