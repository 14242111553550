<template>
  <div class="page-root">
    <div class="header">
      <img class="logo" src="@/assets/CAVIN_Logo_black.png" height="67" />

      <div v-if="step === 1 && user.user_type === 'Shop'" class="logo-caption">店舗情報を<br>教えてください</div>
      <div v-if="step === 1 && user.user_type === 'Farm'" class="logo-caption">生産者情報を<br>教えてください</div>
      <div v-if="step === 2" class="logo-caption">支払い情報を<br>教えてください</div>
      <div v-if="step === 3 && user.user_type === 'Shop'" class="logo-caption">納品先情報を<br>教えてください</div>
      <div v-if="step === 3 && user.user_type === 'Farm'" class="logo-caption">集荷情報を<br>教えてください</div>
    </div>

    <v-stepper alt-labels v-model="step">
      <v-stepper-header>
        <v-stepper-step step="1">
          店舗情報
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="2">
          支払い情報
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3">
          配送情報
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <form class="card">
            <template v-if="user.user_type === 'Farm'">
              <div class="label required">農家・農園名</div>
              <input class="input" type="text" maxlength="50" required placeholder="CAVINフラワー農園" v-model="name" />
            </template>
            <template v-if="user.user_type === 'Shop'">
              <div class="label required">店舗名</div>
              <input class="input" type="text" maxlength="50" required placeholder="CAVINフラワーショップ" v-model="name" />
            </template>

            <div class="label required">代表者名</div>
            <input class="input" type="text" maxlength="50" required placeholder="佐藤花子" v-model="representativeName" />

            <div class="label required">電話番号</div>
            <the-mask class="input" :mask="['###-####-####']" placeholder="090-1234-5678" type="tel"  v-model="phone"/>

            <template v-if="user.user_type === 'Shop'">
              <div class="label">営業時間</div>
              <input class="input" type="text" maxlength="50" placeholder="10:00~19:00" v-model="businessHours" />
            </template>

            <template v-if="user.user_type === 'Farm'">
              <div class="label required">年間出荷額（円）</div>
              <input class="input" type="tel" maxlength="50" required placeholder="4000000" v-model="annualShipmentAmount" />
            </template>
            <template v-if="user.user_type === 'Shop'">
              <div class="label required">年間仕入額（円）</div>
              <input class="input" type="tel" maxlength="50" required placeholder="4000000" v-model="annualPurchaseAmount" />
            </template>

            <button class="button" type="button" @click="step = 2" :disabled="!validStep1">支払い情報を入力する →</button>
          </form>
        </v-stepper-content>
      </v-stepper-items>

      <v-stepper-items>
        <v-stepper-content step="2">
          <form class="card">
            <div class="label required">口座情報</div>
            <input class="input" type="text" maxlength="50" required placeholder="◯◯銀行◯◯支店普通口座1111111" v-model="bankAccounts" />

            <div class="label required">口座名義人</div>
            <input class="input" type="text" maxlength="50" required placeholder="佐藤花子" v-model="bankAccountHolderName" />

            <button class="button" type="button" @click="step = 3" :disabled="!validStep2">
              <span v-if="user.user_type === 'Shop'">配送情報を入力する →</span>
              <span v-if="user.user_type === 'Farm'">集荷情報を入力する →</span>
            </button>
            <v-divider></v-divider>
            <v-btn
              class="back-button"
              color="grey"
              text
              @click="step = 1"
            >
              戻る
            </v-btn>
          </form>
        </v-stepper-content>
      </v-stepper-items>

      <v-stepper-items>
        <v-stepper-content step="3">

          <form class="card" @submit.prevent="submit">
            <div class="label required">郵便番号</div>
            <the-mask class="input" size="8" maxlength="8" :mask="['###-####']" placeholder="810-0006" type="tel" v-model="postalCode" />

            <div class="label required">都道府県名</div>
            <v-select
              class="select"
              v-if="prefectures"
              :items="prefectures"
              item-value="id"
              item-text="name"
              v-model="prefectureId"
              outlined
            />

            <div class="label required">市区町村名</div>
            <input class="input" type="text" maxlength="50" required placeholder="福岡市" v-model="municipality" />

            <div class="label required">住所</div>
            <input class="input" type="text" maxlength="50" required placeholder="中央区白金45-2" v-model="address" />

            <div class="label">建物名</div>
            <input class="input" type="text" maxlength="50" placeholder="CAVINビル 202号" v-model="buildingName" />

            <template v-if="user.user_type === 'Shop'">
              <div class="label">不在時の対応</div>
              <input class="input" type="text" maxlength="50" placeholder="入口前の階段に置いてください" v-model="whenAbsent" />
            </template>
            <template v-if="user.user_type === 'Farm'">
              <div class="label">不在時の対応</div>
              <input class="input" type="text" maxlength="50" placeholder="農園入口前の荷物を集荷してください" v-model="whenAbsent" />
            </template>

            <template v-if="user.user_type === 'Shop'">
              <div class="label">配送時の駐車場所</div>
              <input class="input" type="text" maxlength="50" placeholder="店舗より10m先の空き地前" v-model="parkingPlace" />
            </template>
            <template v-if="user.user_type === 'Farm'">
              <div class="label">集荷先の駐車場所</div>
              <input class="input" type="text" maxlength="50" placeholder="農園より10m先の空き地前" v-model="parkingPlace" />
            </template>

            <button class="button" type="submit" :disabled="!validStep3">登録を完了する →</button>
            <v-divider></v-divider>
            <v-btn
              class="back-button"
              color="grey"
              text
              @click="step = 2"
            >
              戻る
            </v-btn>
          </form>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";

export default {
  components: {
    TheMask
  },
  props: {
    access_token: String
  },
  data() {
    return {
      step: 1,

      name: "",
      representativeName: "",
      phone: "",
      businessHours: "",
      annualShipmentAmount: "",
      annualPurchaseAmount: "",

      bankAccounts: "",
      bankAccountHolderName: "",

      postalCode: "",
      prefecture: "",
      prefectures: [],
      prefectureId: null,
      municipality: "",
      address: "",
      buildingName: "",
      whenAbsent: "",
      parkingPlace: "",

      confirmationToken: this.$route.query.confirmation_token,
      user: {},
      axiosOptions: null,
      password: "",

      hasGreenhouse: "",
      hasOutdoorFarm: "",

      // prefectures: PREFECTURES,
      prefectureError: false,
    };
  },

  async mounted() {
    const base_address = this.$store.state.unuseableAreaUsers;

    console.log("base_address");
    console.log(base_address);

    this.postalCode = base_address.postal_code;
    this.prefecture = base_address.prefecture;
    this.municipality = base_address.municipality;
    this.address = base_address.address;

    this.axiosOptions = {
      headers: {
        Authorization: this.access_token
      }
    };

    const res = await this.$api.get("users/profile", this.axiosOptions);
    const result = await this.$http2("get", "prefectures", null, false);
    this.prefectures = result.result.prefectures;

    if (res.errors) this.$router.push("/users/error");

    this.user = res.result.user;

    if (!base_address.postal_code) {
      console.log("!base_address true");
      const location = (res.result.user.user_type === "Farm") ? res.result.user.farm.locations[0] : res.result.user.shop.locations[0];

      this.postalCode = location.postal_code;
      this.prefecture = location.prefecture;
      this.municipality = location.municipality;
      this.address = location.address;
    }
  },
  computed: {
    validStep1() {
      if (this.user.user_type === "Farm") {
        if (!this.name || !this.representativeName || !this.phone || !this.annualShipmentAmount) return false;
      }
      if (this.user.user_type === "Shop") {
        if (!this.name || !this.representativeName || !this.phone || !this.annualPurchaseAmount) return false;
      }

      return true;
    },
    validStep2() {
      if (!this.bankAccounts || !this.bankAccountHolderName) return false;

      return true;
    },
    validStep3() {
      if (!this.postalCode || !this.prefectureId || !this.municipality || !this.address) return false;

      return true;
    },
  },
  methods: {
    async submit() {
      console.log("submit");

      if (this.step !== 3) {
        console.log("return");
        return;
      }

      this.prefectureError = false;
      if (!this.prefectureId) {
        this.prefectureError = true;
        return;
      }

      let url;
      let content;
      let locationId;
      const location = {
        location: {
          name: this.name,
          address: this.address,
          phone: this.phone,
          postal_code: this.postalCode,
          municipality: this.municipality,
          prefecture_id: this.prefectureId,
          building_name: this.buildingName,
          when_absent: this.whenAbsent,
          parking_place: this.parkingPlace,
        }
      };

      const locRes = await this.$api.get("users/profile", this.axiosOptions);

      if (this.user.user_type === "Farm") {
        locationId = locRes.result.user.farm.locations[0].id;

        url = `/farms/${this.user.farm.id}`;
        content = {
          farm: {
            bank_accounts: this.bankAccounts,
            bank_account_holder_name: this.bankAccountHolderName,
            indoor_area_range: 0,
            outdoor_area_range: 0,
            quit_reason: "",
            name: this.name,
            representative_name: this.representativeName,
            phone: this.phone,
            annual_shipment_amount: this.annualShipmentAmount
          }
        };
      } else {
        locationId = locRes.result.user.shop.locations[0].id;

        url = `/shops/${this.user.shop.id}`;
        content = {
          shop: {
            bank_accounts: this.bankAccounts,
            bank_account_holder_name: this.bankAccountHolderName,
            quit_reason: "",
            name: this.name,
            representative_name: this.representativeName,
            phone: this.phone,
            business_hours: this.businessHours,
            annual_purchase_amount: this.annualPurchaseAmount
          }
        };
      }

      await this.$api.put(url, content, this.axiosOptions);
      await this.$api.put(
        `/locations/${locationId}`,
        location,
        this.axiosOptions
      );

      await this.$api.get(
        "/users/notify_slack",
        this.axiosOptions
      );

      this.$store.commit("setData", {
        id: this.user.id,
        email: this.user.email,
        name: this.name,
        user_type: this.user.user_type,
      });

      if (this.user.is_confirmed) {
        this.$router.push(this.user.user_type === "Farm" ? "/farm" : "/shop");
      } else {
        this.$router.push({
          path: "/users/wait_confirmed",
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  margin-bottom: 40px;

  .logo {
    display: block;
    margin: 0 auto;
  }

  .logo-caption {
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }
}

.content {
  max-width: 600px;
  margin: 0 auto;
}

.card {
  padding: 30px 20px 50px;
  background-color: #ffffff;
  border-radius: 10px;
  color: #333;
}

.card-title {
  font-size: 26px;
  font-weight: bold;
  text-align: center;
}

.label {
  margin: 40px auto 0;
  max-width: 450px;
  font-size: 16px;

  &.required::after {
    content: "*";
    color: #c85c45;
    margin-left: 4px;
  }
}

.input {
  margin: 0 auto;
  display: block;
  max-width: 450px;
}

.select {
  margin: 0 auto;
  max-width: 450px;
  height: 55px;
}

.radio-group {
  margin: 4px auto 0;
  max-width: 450px;

  .radio {
    margin-right: 40px;
  }

  .icon {
    margin-right: 4px;
  }
}

.button {
  margin: 40px auto 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 50px;
  background-color: #007e57;
  border-radius: 3px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  outline: none;
}

.button:disabled {
  background: #ccc;
}

.back-button {
  margin: 0 auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: underline;
}

.v-stepper {
  background-color: #eee;
  box-shadow: none;
}
</style>
