<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="s-ion-icon">
    <path
      :fill="color"
      d="M385.1 230.2c-26.7 0-60.1 6.9-86.3 13.5-.9-3.2-2.1-6.2-3.7-9.1 23.2-13.8 51.7-32.5 70.5-51.4 36.7-36.7 48.3-63.6 37.6-74.3-2.6-2.6-6-3.8-10.3-3.8-13.8 0-36 13.4-64 41.4-18.8 18.8-37.6 47.2-51.4 70.4-2.8-1.6-5.9-2.8-9.1-3.8 6.7-26.2 13.5-59.5 13.5-86.1 0-51.9-10.8-79.1-26-79.1s-26 27.2-26 79.1c0 26.6 6.8 60 13.5 86.1-3.2.9-6.2 2.2-9.1 3.8-13.8-23.2-32.5-51.6-51.4-70.4-28-28-50.3-41.4-64-41.4-4.3 0-7.7 1.3-10.3 3.8-10.8 10.8.8 37.6 37.6 74.3 18.9 18.9 47.3 37.6 70.5 51.4-1.5 2.8-2.8 5.9-3.7 9.1-26.2-6.7-59.6-13.5-86.3-13.5-51.8 0-78.7 10.6-78.7 25.8s26.9 26.2 78.9 26.2c26.7 0 60.2-6.9 86.4-13.6.9 3.1 2.2 6.2 3.8 9-23.3 13.8-51.8 32.6-70.7 51.5-36.7 36.7-48.3 63.6-37.6 74.3 2.6 2.6 6 3.8 10.3 3.8 13.8 0 36-13.4 64-41.4 18.9-18.9 37.8-47.5 51.6-70.8 2.8 1.5 5.8 2.8 9 3.7-6.7 26.2-13.6 59.8-13.6 86.5 0 51.9 10.8 78.6 26 78.6s26-26.7 26-78.6c0-26.8-6.9-60.3-13.6-86.5 3.1-.9 6.1-2.1 9-3.7 13.8 23.3 32.6 51.9 51.6 70.8 28 28 50.3 41.4 64 41.4 4.3 0 7.7-1.3 10.3-3.8 10.8-10.8-.8-37.6-37.6-74.3-18.9-18.9-47.5-37.7-70.7-51.5 1.6-2.8 2.8-5.8 3.8-9 26.2 6.7 59.7 13.6 86.4 13.6 51.9 0 78.9-10.8 78.9-26-.2-15.2-27.1-26-79.1-26z"
    />

    <path
      :fill="color"
      d="M318.4 376.4c-8.5-8.5-16.9-18.7-24.8-29.5 2 13.2 3.3 26.4 3.3 38.4 0 11.7-.5 22.5-1.6 32.1 12.3 21.8 24.3 32.7 34 32.7 1.6 0 3.2-.3 4.7-.9 11-4.5 13.3-23.3 4.5-54.3-6.4-5.3-13.1-11.5-20.1-18.5zM193.8 136.1c8.4 8.4 16.7 18.5 24.6 29.2-2-13.2-3.2-26.3-3.2-38.2 0-11.7.5-22.5 1.6-32.1-12.3-21.8-24.3-32.7-34-32.7-1.6 0-3.2.3-4.7.9-11 4.5-13.3 23.3-4.5 54.3 6.4 5.4 13.2 11.6 20.2 18.6zM135.8 318.6c8.5-8.5 18.7-16.9 29.5-24.8-13.2 2-26.4 3.3-38.4 3.3-11.7 0-22.5-.5-32.1-1.6-25.5 14.4-36.1 28.2-31.8 38.7 2.6 6.4 10.1 9.9 22.1 9.9 8.5 0 19.3-1.7 32.2-5.4 5.4-6.4 11.5-13.1 18.5-20.1zM376.2 193.8c-8.5 8.5-18.7 16.9-29.5 24.8 13.2-2 26.4-3.3 38.4-3.3 11.7 0 22.5.5 32.1 1.6 25.5-14.4 36.1-28.2 31.8-38.7-2.6-6.4-10.1-9.9-22.1-9.9-8.5 0-19.3 1.7-32.2 5.4-5.4 6.5-11.5 13.2-18.5 20.1zM193.6 376.4c-8.3 8.3-16.3 15.6-23.8 21.6-7.8 28.2-5.5 45.4 4.9 49.9 1.6.7 3.2 1 4.9 1 10.3 0 23.2-12 36.7-36.2-.7-8.4-1.1-17.5-1.1-27.3 0-12 1.3-25.2 3.3-38.4-8 10.7-16.4 20.9-24.9 29.4zM318.4 136.2c8.3-8.3 16.2-15.5 23.7-21.5 7.9-28.4 5.7-45.8-4.8-50.2-1.6-.7-3.2-1-4.9-1-10.3 0-23.2 12-36.7 36.2.7 8.4 1.1 17.5 1.1 27.3 0 12.1-1.3 25.3-3.3 38.7 8-10.7 16.4-21 24.9-29.5zM99.6 216.5c8.4-.7 17.5-1.1 27.3-1.1 12 0 25.1 1.3 38.3 3.3-10.7-7.9-20.9-16.3-29.4-24.8-8.3-8.3-15.6-16.3-21.7-23.9-11.3-3.1-20.9-4.6-28.6-4.6-11.4 0-18.6 3.3-21.2 9.5-4.6 10.9 7.1 25.9 35.3 41.6zM412.4 296c-8.4.7-17.5 1.1-27.3 1.1-12 0-25.2-1.3-38.4-3.3 10.8 7.9 21 16.4 29.5 24.8 8.3 8.3 15.5 16.3 21.6 23.8 11.4 3.2 20.9 4.7 28.6 4.7 11.4 0 18.6-3.3 21.2-9.5 4.7-11-7-25.9-35.2-41.6z"
    />
  </svg>
  <!-- eslint-enable -->
</template>

<script>
export default {
  props: {
    color: String
  }
};
</script>

<style>
</style>
