<template>
  <div class="product-image" :class="{ border, list }">
    <div v-if="src">
      <div v-if="isString">
        <div v-if="aspectRatio" :style="`padding-top: ${aspectRatio * 100}%`" />
        <img class="fill" :src="src" />
      </div>

      <div v-else-if="isArray">
        <v-carousel height="auto" hide-delimiter-background show-arrows-on-hover>
          <div v-for="(img, i) in src" :key="i">
            <v-carousel-item v-if="img.image_url" :src="img.image_url" />
          </div>
        </v-carousel>
      </div>

      <div v-else-if="isObject">
        <div v-if="aspectRatio" :style="`padding-top: ${aspectRatio * 100}%`" />
        <img class="fill" :src="src.image_url" />
      </div>
    </div>
    <div v-else style="width: 100px; height: 100px">
      <v-icon class="fill">mdi-flower</v-icon>
    </div>
    <div v-if="not_in_stock" class="request-only">{{ $t('request_only') }}</div>
  </div>
</template>

<script>
export default {
  props: {
    border: {
      type: Boolean,
      default: false
    },
    aspectRatio: {
      type: Number,
      default: 1.15
    },
    src: {
      type: [String, Array, Object],
      default: ""
    },
    not_in_stock: {
      type: Boolean,
      default: false
    },
    list: {
      type: Boolean,
    },
  },

  computed: {
    isString() {
      return typeof this.src === "string";
    },
    isArray() {
      return Array.isArray(this.src);
    },
    isObject() {
      return typeof this.src === "object";
    }
  }
};
</script>

<style lang="scss" scoped>
.product-image {
  position: relative;
  overflow: hidden;
  text-align: center;
  color: white;

  &.border {
    border: 1px solid #cccccc;
    border-radius: 4px;
  }

  .request-only {
    position: absolute;
    top: 6px;
    left: 8px;
    border-radius: 2px;
    border: 1px solid #fff;
    padding: 4px 8px 2px;
    background: #173169;
    font-size: 10px;
    line-height: 1.2;
    color: #fff;
  }
}

.fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.list {
  .request-only {
    top: 4px;
    left: 2px;
    white-space: nowrap;
    transform: scale(0.5);
    transform-origin: 0 0;
  }
}
</style>
