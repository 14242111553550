import Vue from "vue";
import firebase from "firebase/app";
import createGtm from "vue-gtm";
import PortalVue from "portal-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n, { localeNumber, localeDate } from "./plugins/i18n";
import vuetify from "./plugins/vuetify";
import http from "./plugins/http";
import dateFormatter from "./plugins/dateFormatter";
import GtmDataLayer from "./plugins/gtm-datalayer";
import api from "./plugins/api";
import db from "./plugins/db";
import scroll from "./plugins/vue-scroll";
import windowSize from "./plugins/windowSize";
import kanaSortObj from "./helpers/kanaSortObj";

import "./assets/style/common.scss";
import "./assets/style/master.scss";

import "./registerServiceWorker";

Vue.config.productionTip = false;

const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID
};
firebase.initializeApp(config);

Vue.prototype.$db = db;
Vue.prototype.$t = i18n;
Vue.prototype.$n = localeNumber;
Vue.prototype.$d = localeDate;
Vue.prototype.$sortKana = kanaSortObj;

Vue.use(dateFormatter);
Vue.use(GtmDataLayer);
Vue.use(PortalVue);
Vue.use(api);

Vue.use(
  createGtm, {
    id: process.env.VUE_APP_GTM_ID,
    vueRouter: router
  }
);

Vue.use(windowSize);

new Vue({
  router,
  store,
  vuetify,
  scroll,
  render: h => h(App),
  created() {
    Vue.use(http, this.$store);
    document.documentElement.setAttribute("lang", "ja");
  }
}).$mount("#app");
