<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="order-card d-flex align-center">
    <ProductImage class="image" :src="item.flower_image" />

    <div class="info-container">
      <div class="upper-row d-flex justify-space-between align-center">
        <div class="left d-flex justify-space-around align-center">
          <div>
            <DeliveryStatus :status="item.status"/>
          </div>
          <div>{{ item.id }}</div>
        </div>
        <div class="right">
          <div v-if="showFarmName(item.type)" class="farm-name">{{ item.farm_name }}</div>
          <div class="specie-name">{{ item.specie_name }}</div>
          <div class="genus-name">{{ item.genus_name }}</div>
        </div>
      </div>
      <div class="lower-row">
        <div class="d-flex align-center">
          <div class="font-weight-bold small-gray mr-4">
            本数: {{ item.subtotal_count }}本
          </div>
          <div class="font-weight-bold small-gray">
            全長: {{ item.flower_harvest.stem_size }}cm
          </div>
        </div>
        <div v-if="showAreaName(item.type)" class="font-weight-bold small-gray">
          配送エリア: {{ item.area_name }}
        </div>
        <div class="font-weight-normal small-gray">
          {{ item.flower_harvest.note }}
        </div>
        <div class="font-weight-normal small-gray">
          不在時の置き場所: {{ item.when_absent }}
        </div>
      </div>
    </div>
    <div class="check">
      <input type="checkbox" v-if="isSelectable && isShowCheckBox || item.is_qr" v-bind:checked="item.is_checked" v-model="item.is_checked" @change="updateCheck(item)"/>
      <span v-else></span>
    </div>
  </div>
</template>

<script>
import ProductImage from "@/components/shop/products/ProductImage.vue";
import DeliveryStatus from "@/components/driver/information/DeliveryStatus.vue";

export default {
  components: {
    ProductImage,
    DeliveryStatus
  },

  props: {
    title: String,
    type: String,
    item: {
      type: Object,
      required: true
    },
    isShowCheckBox: Boolean,
    isSelectable: Boolean
  },
  computed: {
    driverType() {
      return this.$store.state.user.driver && this.$store.state.user.driver.driver_type;
    },
    isShippingDriver() {
      return this.driverType === "shipping";
    },
    isDeliveryDriver() {
      return this.driverType === "delivery";
    },
    qrReadType() {
      return this.$store.state.driverInformation.qrReadType;
    },
    deliveryType() {
      return this.$store.state.driverInformation.deliveryType;
    },
  },
  methods: {
    showFarmName(type) {
      if (this.isShippingDriver && type === "to") {
        return true;
      }
      if (this.isDeliveryDriver) {
        return true;
      }
      return false;
    },
    showAreaName(type) {
      if (this.isShippingDriver && type === "from") {
        return true;
      }
      return false;
    },
    updateCheck(item) {
      const selectedOrderList = { ...this.$store.state.driverInformation.selectedOrderList };
      if (selectedOrderList[item.id.toString()]) {
        selectedOrderList[item.id.toString()].checked = !selectedOrderList[item.id.toString()].checked;
      } else {
        selectedOrderList[item.id.toString()] = { checked: true, is_qr: false };
      }
      if (selectedOrderList[item.id.toString()].checked && this.deliveryType === "") {
        this.$store.dispatch("driverInformation/setSelectedOrderItem", { orderItem: selectedOrderList, type: this.type });
      } else {
        this.$store.dispatch("driverInformation/setSelectedOrderItem", { orderItem: selectedOrderList });
      }
    },
    setDeliveryType(delivery_type) {
      this.$store.dispatch("driverInformation/updateDeliveryType", { qrReadType: delivery_type });
    },
  }
};
</script>

<style lang="scss" scoped>
.order-card {
  border-bottom: #d3d3d3 1px solid;
}

.image {
  width: 30%;
  height: auto;
  border-radius: 5px;
  object-fit: cover;
}

.info-container {
  width: 70%;
  padding-right: .5rem;

  .upper-row {
    .left {
      width: 45%;
    }

    .right {
      width: 50%;

      .farm-name {
        color: #777;
        font-size: 12px;
        font-weight: bold;
      }

      .specie-name {
        font-size: 14px;
        font-weight: bold;
      }

      .genus-name {
        font-size: 12px;
      }
    }
  }

  .lower-row {
    margin-top: 4px;
    margin-left: 4px;
  }

  .small-gray {
    color: #bdbdbd;
    font-size: 12px;
  }
}
.check {
  width: 35px;
}

</style>
