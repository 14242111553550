<template>
  <div class="shop-layout">
    <Header class="header" />
    <div class="main">
      <router-view class="page mb-10" />
    </div>
    <MobileFooter v-if="mobile || tablet" class="footer" />
    <Footer v-else class="footer" />
  </div>
</template>

<script>
import Header from "@/components/shop/Header.vue";
import Footer from "@/components/shared/Footer.vue";
import MobileFooter from "@/components/shop/MobileFooter.vue";
import getDefaultLocation from "@/util/getDefaultLocation";

export default {
  components: {
    Header,
    Footer,
    MobileFooter
  },
  data() {
    return {
      confirmationToken: "",
      axiosOptions: {},
      user: {}
    };
  },
  async mounted() {
    const res = await this.$api.get("users/profile", this.axiosOptions);

    this.$store.commit("setData", {
      is_confirmed: res.result.user.is_confirmed,
    });

    const obj = {
      user: res.result.user,
      defaultLocation: getDefaultLocation(res.result.user.shop.locations)
    };

    this.$store.commit("setProfile", obj);

    if (!res.result.user.is_confirmed) {
      this.$router.push({
        path: "/users/wait_confirmed",
        query: {
          type: res.result.user.user_type,
          email: res.result.user.email
        }
      });
    }
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    tablet() {
      return this.$vuetify.breakpoint.sm;
    }
  }
};
</script>

<style lang="scss" scoped>
.shop-layout {
  display: grid;
  grid-template-rows: auto 1fr auto;
  color: #0c1624;
  min-height: 100vh;
  background-color: #fff;
}

.header {
  position: sticky;
  top: 0;
  z-index: 4;
}

.main {
  position: relative;
  min-width: 0;
  // z-index: 0;
}

.footer {
  z-index: 3;
}
.page {
  padding-bottom: 60px;
  min-height: 100%;

  @media screen and (max-width: 960px) {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
</style>
