<template>
  <footer :class="`footer-root ${shopUser ? 'bg-color-primary': 'bg-color-farm'}`" v-if="!mobile">
    <img class="image" src="@/assets/CAVIN_Logo_white.png" width="196">
    <div class="nav-list">
      <a class="nav" :href="officialLineUrl" target="_blank">{{ $t("line_support") }}<v-icon color="white" small>mdi-open-in-new</v-icon></a>
      <a class="nav" href="https://cavin.ooo/terms-1" target="_blank">利用規約<v-icon color="white" small>mdi-open-in-new</v-icon></a>
      <a class="nav" href="https://cavin.ooo/commerce" target="_blank">特定商取引法に基づく表記<v-icon color="white" small>mdi-open-in-new</v-icon></a>
      <a class="nav" href="https://cavin.ooo/privacypolicy" target="_blank">プライバシーポリシー<v-icon color="white" small>mdi-open-in-new</v-icon></a>
      <a class="nav" href="https://cavin.ooo/about" target="_blank">運営元<v-icon color="white" small>mdi-open-in-new</v-icon></a>
      <a class="nav" href="https://www.wantedly.com/companies/company_6193267" target="_blank">採用情報<v-icon color="white" small>mdi-open-in-new</v-icon></a>
    </div>
    <div class="copyright">CAVIN Inc. All Rights Reserved.</div>
  </footer>
</template>

<script>
export default {
  computed: {
    officialLineUrl() {
      return process.env.VUE_APP_X_OFFICIAL_LINE_URL;
    },
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    shopUser() {
      return this.$store.state.auth.user_type === "Shop";
    }
  }
};
</script>

<style lang="scss" scoped>
.footer-root {
  padding: 32px;
  text-align: center;
  color: #fff;
  .image {
    @media screen and (max-width:600px){
      width: 100px;
    }
  }
}

.bg-color-farm {
  background-color: #173169;
}

.nav-list {
  margin: 36px 0;
  @media screen and (max-width:600px){
    display:none;
  }
}

.nav {
  display: inline-block;
  margin: 12px 24px;
  color: inherit;
}

.copyright {
  @media screen and (max-width:600px){
    font-size: 10px;
  }
}
</style>
