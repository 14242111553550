<template>
  <div class="menu pb-6">
    <div v-if="mobile" class="pa-3 d-flex align-center justify-end">
      <div @click="closeMobileMenu">
        <CloseIcon size="48" color="white" />
      </div>
    </div>

    <div v-for="item in menus" :key="item.name">
      <div v-if="item.name == 'driverLabel'" @click="openPdf()">
        <div v-if="mobile" class="line" />
          <div :class="{selected: selectedMenu === item.name}">
            <v-list-item link>
              <v-list-item-icon class="mr-1">
                <document-icon color="white" />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="menu-title">{{ $t(item.name) }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <ChevronIcon color="white" size="30" />
              </v-list-item-action>
            </v-list-item>
          </div>
      </div>

      <router-link v-else :to="item.path" @click.native="closeMobileMenu">
        <div v-if="mobile" class="line" />

        <div :class="{selected: selectedMenu === item.name}">
          <v-list-item link>
            <v-list-item-icon class="mr-1">
              <tile-icon class="mt-1" color="white" v-if="item.name === 'driverInformation'" />
              <binder-icon color="white" v-if="item.name === 'driverHistory'" />
              <user-circle-icon color="white" v-if="item.name === 'driverList'" />
              <document-icon color="white" v-if="item.name === 'driverLabel'" />
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="menu-title">{{ $t(item.name) }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <ChevronIcon color="white" size="30" />
            </v-list-item-action>
          </v-list-item>
        </div>
      </router-link>
    </div>

    <div v-if="mobile">
      <div class="line" />

      <v-container fluid>
        <v-row>
          <v-col sm="6">
            <v-btn
              class="mobile-menu-btn"
              color="white"
              outlined
              block
              to="/driver/settings"
            >
              {{ $t("settings") }}
            </v-btn>
          </v-col>
          <v-col sm="6">
            <v-btn
              class="mobile-menu-btn"
              color="white"
              outlined
              block
              :href="officialLineUrl"
            >
              {{ $t("line_support") }}
            </v-btn>
          </v-col>
        </v-row>

        <v-btn
          class="mobile-menu-btn"
          color="white"
          @click="logout"
          outlined
          block
        >{{ $t("logout") }}</v-btn>
      </v-container>

      <div class="d-flex flex-column mt-1 ml-3">
        <a
          href="https://cavin.ooo/terms-1"
          class="menu-subitem mt-3 ml-3"
          target="_blank"
        >利用規約
        <v-icon color="white" small>mdi-open-in-new</v-icon>
        </a>
        <a
          href="https://cavin.ooo/privacypolicy"
          class="menu-subitem mt-3 ml-3"
          target="_blank"
        >プライバシーポリシー
        <v-icon color="white" small>mdi-open-in-new</v-icon>
        </a>
        <a
          href="https://cavin.ooo/commerce"
          class="menu-subitem mt-3 ml-3"
          target="_blank"
        >特定商取引法に基づく表記
        <v-icon color="white" small>mdi-open-in-new</v-icon>
        </a>
        <a
          href="https://cavin.ooo/about"
          class="menu-subitem mt-3 ml-3"
          target="_blank"
        >運営元<v-icon color="white" small>mdi-open-in-new</v-icon>
        </a>
        <a
          href="https://www.wantedly.com/companies/company_6193267"
          class="menu-subitem mt-3 ml-3"
          target="_blank"
        >採用情報<v-icon color="white" small>mdi-open-in-new</v-icon>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import TileIcon from "@/components/icons/TileIcon.vue";
import DocumentIcon from "@/components/icons/DocumentIcon.vue";
import BinderIcon from "@/components/icons/BinderIcon.vue";
import UserCircleIcon from "@/components/icons/UserCircleIcon.vue";
import ChevronIcon from "@/components/icons/ChevronIcon.vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";
import { formatDate } from "@/plugins/i18n";

export default {
  components: {
    TileIcon,
    DocumentIcon,
    BinderIcon,
    UserCircleIcon,
    ChevronIcon,
    CloseIcon
  },
  data() {
    return {
      mobile: this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm,
      selectedMenu: "",
      selectedArea: 0,
    };
  },
  computed: {
    menus() {
      const menus = [
        { path: "/driver/information", name: "driverInformation" }
      ];
      if (this.$store.state.user.driver && this.isShippingDriver) {
        menus.push({ path: "", name: "driverLabel" });
      }
      return menus;
    },
    driverType() {
      return this.$store.state.user.driver && this.$store.state.user.driver.driver_type;
    },
    isShippingDriver() {
      return this.driverType === "shipping";
    },
    list() {
      const list = [...this.$store.state.driverInformation.list];
      if (this.selectedArea === 0) {
        return list.sort(
          (a, b) => {
            let r = 0;
            if (a.farm.id < b.farm.id) { r = -1; } else if (a.farm.id > b.farm.id) { r = 1; }

            return r;
          },
        );
      }
      return list.filter(f => f.area_id === this.selectedArea);
    },
    officialLineUrl() {
      return process.env.VUE_APP_X_OFFICIAL_LINE_URL;
    }
  },
  watch: {
    $route(to) {
      this.selectedMenu = to.name;
    }
  },
  mounted() {
    this.selectedMenu = this.$route.name;
  },
  methods: {
    getLatestHarvestDateItems(order_items) {
      order_items.sort((order_item1, order_item2) => {
        if (order_item1.flower_harvest.harvest_date < order_item2.flower_harvest.harvest_date) return -1;
        if (order_item1.flower_harvest.harvest_date > order_item2.flower_harvest.harvest_date) return 1;
        return 0;
      });
      const latest_harvest_date = new Date(order_items[0].flower_harvest.harvest_date);
      return order_items.filter(order_item => new Date(order_item.flower_harvest.harvest_date).getTime() === latest_harvest_date.getTime());
    },
    closeMobileMenu() {
      this.$store.dispatch("setMobileMenuOpen", false);
    },
    logout() {
      this.$store.dispatch("logout");
    },
    async openPdf() {
      const order_items = await this.getLogisticsPdfOrderItems();
      if (!order_items || !order_items.length) return;
      await this.$store.dispatch("drivers/getAreas");

      const latest_harvest_date_order_items = this.getLatestHarvestDateItems(order_items);
      const order_item_ids = latest_harvest_date_order_items.map(order_item => order_item.id);

      await this.$api.get(`/delivery/logistics_label_pdf.pdf?order_item_id=${order_item_ids.join()}`, { responseType: "blob" }).then(response => {
        const blob = new Blob([response], { type: "application/pdf" });
        const objectUrl = URL.createObjectURL(blob);
        this.pdfsrc = objectUrl;

        window.location.href = this.pdfsrc;
      });
    },
    getLogisticsPdfOrderItems() {
      const today = new Date();
      return this.$store.dispatch("driverInformation/getLogisticsPdfOrderItems", {
        dateFrom: formatDate(today, "yyyy-mm-dd"),
        dateTo: formatDate(this.addDate(today, 7), "yyyy-mm-dd"),
      });
    },
    addDate(date, addDay) {
      return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + addDay
      );
    },
  }
};
</script>

<style scoped>
.menu {
  background-color: #00512e;
  height: 100vh;
  overflow-y: auto;
  min-width: 230px;
}

.selected {
  background: #48ae84;
}

.menu-title {
  padding: 5px;
  font: Bold 1rem Helvetica;
  color: #ffffff;
}

.line {
  margin: 5px 0;
  height: 1px;
  background: repeating-linear-gradient(
    to right,
    #cccccc 0,
    #cccccc 5px,
    transparent 5px,
    transparent 7px
  );
}

.menu-subitem {
  font-size: 13px;
  color: white;
}

.mobile-menu-btn {
  height: 53px !important;
}
</style>
