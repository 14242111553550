<template>
  <MstBottomSheet v-model="isVisibleModel">
    <div class="harvestSheet">
      <header class="harvestSheet__header">
        <h2 class="harvestSheet__title text-lg color-gray-900">カートに入れる</h2>
        <router-link to="/shop/cart" class="harvestSheet__link text-sm color-blue-900">
          カートを見る
          <MstIcon type="chevron-right" size="22px" class="harvestSheet__linkIcon" />
        </router-link>
      </header>
      <div class="harvestSheet__content">
        <HarvestTable
          v-if="flowerHarvests.length"
          :flower-harvests="flowerHarvests"
          :order-items="orderItems"
          class="harvestSheet__table"
          @add:cart="addDraftOrder"
          @change:count="updateDraftOrder"
        />
        <p v-else class="harvestSheet__empty text-sm">現在取引可能な商品はありません</p>
      </div>
    </div>
  </MstBottomSheet>
</template>

<script>
import { MstBottomSheet, MstIcon } from "@/components/master";
import { HarvestTable } from "..";

export default {
  name: "HarvestSheet",
  model: {
    prop: "isVisible",
    event: "update:isVisible",
  },
  components: { MstBottomSheet, MstIcon, HarvestTable },
  props: {
    isVisible: { type: Boolean, required: true },
    flowerHarvests: { type: Array, required: true },
  },
  computed: {
    isVisibleModel: {
      get() { return this.isVisible; },
      set(value) { this.$emit("update:isVisible", value); },
    },
    orderItems() {
      const draftOrder = this.$store.getters["orders/draftOrder"];
      if (!draftOrder) return [];
      return draftOrder.order_items;
    },
  },
  methods: {
    async addDraftOrder({ flowerHarvestId, count }) {
      await this.postDraftOrder(flowerHarvestId, count);
    },
    async updateDraftOrder({ flowerHarvestId, orderItemId, count }) {
      try {
        if (count <= 0) {
          await this.$http("delete", `order_items/${orderItemId}`);
        } else {
          await this.$http("put", `order_items/${orderItemId}`, {
            order_item: {
              flower_harvest_id: flowerHarvestId,
              lot_count: count,
            },
          });
        }
      } finally {
        await this.$store.dispatch("orders/getDraftOrder");
      }
    },
    async postDraftOrder(flowerHarvestId, count) {
      class OrderItemCreateError extends Error {
        constructor(status, message) {
          super(message);
          this.status = status;
          this.name = "OrderItemCreateError";
        }
      }

      try {
        const res = await this.$http("post", "order_items", {
          order_items: [{ flower_harvest_id: flowerHarvestId, lot_count: count }],
        });
        if (res.status === 422) throw new OrderItemCreateError(422, "Failed to adding item into cart");
        if (res.status === 521) throw new OrderItemCreateError(521, "Failed to adding item into cart");
      } catch (e) {
        if (e.status === 521) {
          this.$store.dispatch("orders/notifyUnbuyableAreaError");
        } else {
          this.$store.dispatch("orders/notifyAddToCartError");
        }

        console.log(e);
      } finally {
        await this.$store.dispatch("orders/getDraftOrder");
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.harvestSheet__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.harvestSheet__link {
  position: relative;
  right: -6px;
  display: flex;
  align-items: center;
}

.harvestSheet__content {
  margin-top: 25px;
}

.harvestSheet__table {
  margin: 0 -15px;
}

.harvestSheet__empty {
  text-align: center;
}
</style>
