<template>
  <div>
    <div style="text-align: center" id="top">
      <small>{{ $t("welcome") }}</small>、
      <strong>{{ shop.name }}：{{ shop.representative_name }}</strong>
      <small>{{ $t("sama") }}</small>
    </div>

    <PageContainer>
      <v-divider />
      <div>
        <div class="text-subtitle-2"><span class="text-title-">{{ flower_genus.length }}</span>件</div>
      </div>
      <div>
        <FlowerGenusList :list="flower_genus" />
      </div>
    </PageContainer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PageContainer from "@/components/shop/PageContainer.vue";
import FlowerGenusList from "@/components/shop/flower_genus/FlowerGenusList.vue";

export default {
  components: {
    PageContainer,
    FlowerGenusList
  },
  computed: {
    ...mapGetters(["getUserName"]),
    shop() {
      return this.$store.state.user.shop;
    },
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    farms() {
      return this.$store.state.searchQuery.available_farms;
    },
    categories() {
      return this.$store.state.searchQuery.categories;
    },
  },

  data() {
    return {
      flower_genus: [],
      page: null,
      pagination: {},
      available: 0
    };
  },

  created() {
    this.fetchHome();
  },
  watch: {
    flower_genus: {
      handler() {},
      deep: true
    }
  },

  methods: {
    async fetchHome () {
      const { status, result } = await this.$http("get", "/flower_genus");

      if (status === 200) { this.flower_genus = result.flower_genuses; }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
