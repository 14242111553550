<template>
  <div class="cart-price-card">
    <div>
      <div class="detail">
        <div>購入：</div>
        <div class="sub-total">
          {{ $n(order && order.not_request_subtotal_sales_price_without_tax_and_delivery_fee) }}
          <span class="yen">円</span>
        </div>
      </div>
      <div class="detail">
        <div>送料：</div>
        <div class="sub-total">
          {{ $n(order && order.not_request_subtotal_delivery_fee) }}
          <span class="yen">円</span>
        </div>
      </div>
      <div class="detail">
        <div>リクエスト：</div>
        <div class="sub-total">
          {{ $n(order && order.request_subtotal_sales_price_without_tax_and_delivery_fee) }}
          <span class="yen">円</span>
        </div>
      </div>
      <div class="detail">
        <div>リクエスト送料：</div>
        <div class="sub-total">
          {{ $n(order && order.request_subtotal_delivery_fee) }}
          <span class="yen">円</span>
        </div>
    </div>
      <small class="request-answer-deadline-description">
        ※ 生産者さんが承認した場合のみ、「注文確定」となります。ご了承ください。
        <!-- 生産者さんが出荷できないと判断した場合は取引履歴に「不成立」と表示されます。(TODO: 取引履歴に実装後) -->
      </small>
    </div>
    <div class="total-price-wrapper align-baseline">
      <div class="unit">合計金額：</div>
      <div class="total_price">
        {{ $n(order && order.subtotal_sales_price_without_tax) }}
        <span class="yen">円</span>
      </div>
    </div>
    <Button heavy :disabled="isDisabled" :to="buttonTo" @click="$emit('button-click')"
      v-bind:class="{ 'line-order_complete': $t('complete_order') === buttonText }" class="button-to">
      {{ buttonText }}
    </Button>
  </div>
</template>

<script>
import Button from "@/components/shared/v2/Button.vue";

export default {
  components: {
    Button
  },

  props: {
    order: {
      type: Object,
      default: null
    },
    buttonText: {
      type: String,
      default: ""
    },
    buttonTo: {
      type: String,
      default: ""
    }
  },

  computed: {
    isDisabled() {
      if (!this.order) return true;

      if (this.$route.path === "/shop/cart") return this.isGroupedOrdersInvalid;
      return this.isOrderItemInvalid(this.order.order_items);
    },
    isGroupedOrdersInvalid() {
      if (!this.order.order_items_group_by_delivery_date || !this.order.order_items_group_by_delivery_date.length) return true;

      for (let i = 0; i < this.order.order_items_group_by_delivery_date.length; i++) {
        if (this.isOrderItemInvalid(this.order.order_items_group_by_delivery_date[i]).order_items) return true;
      }
      return false;
    }
  },
  methods: {
    isOrderItemInvalid(order_items) {
      if (!order_items || !order_items.length) return true;

      const now = new Date();

      for (let i = 0; i < order_items.length; i++) {
        const orderItem = order_items[i];
        const deliveryDate = new Date(orderItem.delivery_date);

        if (deliveryDate < now) return true;
      }
      return false;
    },
  }
};
</script>

<style lang="scss" scoped>
.cart-price-card {
  background-color: #fff;
  border: #ccc 1px solid;
  border-radius: 5px;
  padding: 16px;

  .yen {
    font-size: 14px;
    line-height: 17px;
    font-weight: normal;
  }
}

.detail {
  display: flex;
  justify-content: space-between;

  .sub-total {
    color: #191D38;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    justify-self: end;
  }
}

.total-price-wrapper {
  margin-top: 8px;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  font-weight: bold;

  .unit {
    font-size: 18px;
    line-height: 22px;
    color: #646464;
  }
  .total_price {
    font-size: 20px;
    line-height: 24px;
    color: #DD5055;
  }

}

.button-to {
  margin: 12px 0 0;
  width: 100%;
}

.request-answer-deadline-description {
  margin: 6px 0 0;
  padding: 8px 15px;
  display: block;
  grid-column: 1/3;

  background-color: #F5F5F5;
  color: #111111;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
}
</style>
