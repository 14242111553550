<template>
  <div v-if="!busy">
    <div class="d-flex align-center ma-3" @click="onGoBack()">
      <LeftArrowIcon size="12" color="black" />
      <h4 class="ml-2 font-weight-normal">{{ $t("back") }}</h4>
    </div>
    <div v-if="flowerHarvest.id">
      <img-handler direct :default-value="images" @finish="imageHandler" multi-preview :is-edit="!!flowerHarvest.flower_id"/>

      <div class="status-title mx-7">
        <LeftArrowIcon size="24" color="black" />
        <span class="status-label">{{ $t(flowerHarvest.status) }}</span>
        <RightArrowIcon size="24" color="black" />
      </div>

      <HarvestForm
        :is-edit="true"
        :flower-harvest="flowerHarvest"
        :genus-options="genusOptions"
        :flower-options="flowerOptions"
        :flower-options-hash="flowerOptionsHash"
        @on-change="handleChange"
      />
      <div class="px-8 color-red my-2">
        <span>{{ error_confirmed_count }}</span>
      </div>
      <div class="mx-7 flex-items">
        <MstButton outlined :to="detailLink">{{ $t("cancel") }}</MstButton>
        <MstButton :disabled="!canSave()" @click="save()">{{ $t("save") }}</MstButton>
      </div>
    </div>
  </div>
</template>
<script>
import LeftArrowIcon from "@/components/icons/LeftArrowIcon.vue";
import RightArrowIcon from "@/components/icons/RightArrowIcon.vue";
import ImgHandler from "@/components/shared/ImgHandler/index.vue";
import HarvestForm from "@/components/farm/flower_harvests/common/HarvestForm.vue";
import { MstButton } from "@/components/master";
import { formattedDate, nextDateKey } from "@/util/date";

export default {
  components: {
    LeftArrowIcon,
    RightArrowIcon,
    ImgHandler,
    HarvestForm,
    MstButton
  },
  data() {
    return {
      busy: false,
      genusOptions: [],
      flowerOptions: [],
      flowerOptionsHash: {},
      flowerHarvest: {
        id: null,
        flower_genus_id: "",
        recommended_min_price: "",
        recommended_max_price: "",
        flower_id: "",
        specie_id: "",
        category_id: "",
        flower_specie: "",
        min_count: "",
        set_count: "",
        price: "",
        harvest_date: nextDateKey("", 1, this.$store.getters.workingWdays),
        stem_size: "",
        note: "",
        status: "not_on_sale",
        is_status_editable: false,
      },
      flower_harvest_id: "",
      error_confirmed_count: "",
    };
  },
  async created() {
    this.busy = true;
    this.flower_harvest_id = this.$route.params.flower_harvest_id;
    const responses = [];
    responses.push(this.$store.dispatch("flowerHarvest/getHarvest", { flower_harvest_id: this.flower_harvest_id }));
    responses.push(this.$store.dispatch("flowers/getFlowers"));
    await Promise.all(responses);
    const response = await this.$http2("get", "harvests/flower_genus");
    this.genusOptions = response.result.flower_genus.map(genus => {
      genus.label = genus.name;
      genus.value = genus.id.toString();
      return genus;
    });
    const { flowers, flowerHarvest } = await this.$store.state;
    const flowerHash = {};
    flowers.listArr.forEach(id => {
      const f = flowers.list[id];
      f.label = f.flower_specie.name;
      f.value = f.id.toString();
      const genusId = f.flower_specie.flower_genus.id.toString();
      if (flowerHash[genusId]) {
        flowerHash[genusId].push(f);
      } else {
        flowerHash[genusId] = [f];
      }
      this.flowerOptionsHash = flowerHash;
    });
    const selectedHarvest = flowerHarvest.list?.find(harvest => harvest?.id.toString() === this.flower_harvest_id.toString());
    if (selectedHarvest.id) {
      this.flowerHarvest = {
        id: selectedHarvest.id,
        flower_genus_id: selectedHarvest.flower.flower_specie.flower_genus.id.toString(),
        flower_specie: selectedHarvest.flower.flower_specie.flower_genus.name_furigana,
        flower_id: selectedHarvest.flower_id.toString(),
        min_count: selectedHarvest.min_count.toString(),
        set_count: selectedHarvest.set_count.toString(),
        stem_size: selectedHarvest.stem_size.toString(),
        note: selectedHarvest.note.toString(),
        price: selectedHarvest.price.toString(),
        harvest_date: selectedHarvest.harvest_date.toString(),
        status: selectedHarvest.status,
        is_editable: selectedHarvest.is_editable,
        is_status_editable: selectedHarvest.is_status_editable
      };
    }
    this.flowerOptions = this.flowerOptionsHash[this.flowerHarvest.flower_genus_id];
    this.busy = false;
  },
  methods: {
    async imageHandler(e) {
      this.busy = true;
      const payload = {
        categoryId: this.flowerHarvest.category_id,
        genusId: this.flowerHarvest.flower_genus,
        specieId: this.flowerHarvest.specie_id,
        imgs: e
      };

      switch (e) {
        case "OVERSIZE":
          this.$store.dispatch("setSnackBar", { msg: this.$t("image_size_over"), color: "red" }, { root: true });
          this.$store.dispatch("snackOn", {}, { root: true });
          break;

        case "WRONG_FORMAT":
          this.$store.dispatch("setSnackBar", { msg: this.$t("image_wrong_format"), color: "red" }, { root: true });
          this.$store.dispatch("snackOn", {}, { root: true });
          break;

        default:
          await this.$store.dispatch("flowers/putFlower", {
            flower_id: this.flowerHarvest.flower_id,
            data: payload
          });
          await this.$store.dispatch("flowers/getFlowers", this.workingFlowerIds);
          await this.getFlowers();
          break;
      }
      this.busy = false;
    },
    onGoBack() {
      this.$router.push("/farm/flower_harvests");
    },
    handleChange(e, key) {
      if (key === "flower_genus_id") {
        this.flowerOptions = this.flowerOptionsHash[e];
        this.flowerHarvest.flower_id = "";
        this.flowerHarvest.specie_id = "";
        this.flowerHarvest.category_id = "";
        this.flowerHarvest.flower_specie = "";
        this.flowerHarvest.recommended_min_price = this.genusOptions.find(item => item.value === e).recommended_min_price;
        this.flowerHarvest.recommended_max_price = this.genusOptions.find(item => item.value === e).recommended_max_price;
      }
      this.flowerHarvest[key] = e.toString();
    },
    save() {
      this.error_confirmed_count = "";
      const payload = [];
      let harvest = {};
      const confirmed_count = this.flowerHarvest.set_count * this.flowerHarvest.min_count;
      const harvest_date = {
        start: this.flowerHarvest.harvest_date,
        end: this.flowerHarvest.harvest_date,
        weekdays: null
      };
      harvest = {
        id: this.flowerHarvest.id,
        confirmed_count,
        min_count: Number(this.flowerHarvest.min_count),
        flower_id: this.flowerHarvest.flower_id,
        harvest_date,
        stem_size: Number(this.flowerHarvest.stem_size),
        price: Number(this.flowerHarvest.price),
        note: this.flowerHarvest.note
      };
      if (confirmed_count > 10 ** 10 - 1) {
        this.error_confirmed_count = this.$t("error_confirmed_count");
      } else {
        payload.push(harvest);
        this.putFlowerHarvest(payload);
      }
    },
    putFlowerHarvest(payload) {
      this.$store.dispatch("flowerHarvest/putHarvest", payload)
        .then(res => {
          if (res.status === 200) {
            this.$store.dispatch("setMstSnackBar", { msg: "変更内容を保存しました。", color: "#21920F" }, { root: true });
            this.$store.dispatch("mstSnackOn", {}, { root: true });
            this.$router.push(`/farm/flower_harvests/${this.flowerHarvest.id}`);
          }
        });
    },
    canSave() {
      return this.flowerHarvest.flower_id
        && this.flowerHarvest.flower_genus_id
        && Number(this.flowerHarvest.min_count) > 0
        && Number(this.flowerHarvest.set_count) >= 0
        && Number(this.flowerHarvest.price) >= 0
        && Number(this.flowerHarvest.stem_size) >= 0
        && new Date(formattedDate(this.flowerHarvest.harvest_date)) >= new Date(formattedDate());
    },
    async getFlowers() {
      const flowers = await this.$store.state.flowers;
      const flowerHash = {};
      flowers.listArr.forEach(id => {
        const f = flowers.list[id];
        f.label = f.flower_specie.name;
        f.value = id.toString();
        const genusId = f.flower_specie.flower_genus.id.toString();
        if (flowerHash[genusId]) {
          flowerHash[genusId].push(f);
        } else {
          flowerHash[genusId] = [f];
        }
      });
      this.flowerOptionsHash = flowerHash;
      if (this.flowerHarvest.flower_genus_id) {
        this.flowerOptions = this.flowerOptionsHash[this.flowerHarvest.flower_genus_id];
      }
    },
  },
  computed: {
    workingFlowerIds() {
      return this.$store.state.flowers.listArr.filter(id => this.$store.state.flowers.list[id].is_working);
    },
    genusName() {
      return this.genusOptions[this.genusOptions.findIndex(item => item.value === this.flowerHarvest.flower_genus_id)].label;
    },
    specieName() {
      return this.flowerOptions[this.flowerOptions.findIndex(item => item.value === this.flowerHarvest.flower_id)].flower_specie.name;
    },
    images() {
      let imgs = [];
      if (this.flowerOptions.length > 0 && this.flowerHarvest.flower_id) {
        imgs = this.flowerOptions.find(option => option.value === this.flowerHarvest.flower_id).images;
      }
      return imgs;
    },
    detailLink() {
      return `/farm/flower_harvests/${this.flower_harvest_id}`;
    }
  }
};
</script>
<style lang="scss" scoped>
.status-title {
  display: flex;
  justify-content: space-between;
  margin: 0 12px 12px 12px;
}
.status-label {
  color: #818691;
  font-weight: bold;
}
.flex-items {
  display: flex;
  gap: 10px;
}
.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}
.modal-content {
  background-color: #fefefe;
  margin: auto;
  border: 0 solid #888;
  border-radius: 8px;
  max-width: 344px;
}
</style>
