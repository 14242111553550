/**
 * @typedef FlowerHarvest
 * @type {object}
 * @property {number} id
 * @property {string} created_at
 * @property {string} updated_at
 * @property {string} deleted_at
 * @property {number} confirmed_count
 * @property {number} min_count
 * @property {string} harvest_date
 * @property {number} price
 * @property {number} flower_id
 */

export default {
  namespaced: true,
  state: {
    /** @type {FlowerHarvest} */
    list: [],
    listArr: [],
    harvestWdays: [],
    isShowSuccessModal: false,
  },
  mutations: {
    setData(state, payload) {
      state.list = payload;
      state.listArr = Object.keys(state.list)
        .map(Number)
        .sort((a, b) => a - b);
    },
    setHarvestWdays(state, payload) {
      state.harvestWdays = payload;
    },
    setIsShowSuccessModal(state, payload) {
      state.isShowSuccessModal = payload;
    }
  },
  actions: {
    async getFlowerHarvest({ commit, state, dispatch, rootState }, { date, flowerGenusId = null }) {
      const { flowers } = rootState;
      const flowersToLoad = [];
      const url = flowerGenusId ? `harvests?date=${date}&flower_genus_id=${flowerGenusId}` : `harvests?date=${date}`;
      const res = await this._vm.$http("get", url);
      if (res.status === 200) {
        let newData = state.list;
        newData = [];
        res.result.flower_harvests.forEach((value, index) => {
          const harvest = value;
          const harvest_date = harvest.harvest_date ? new Date(harvest.harvest_date) : null;
          const set_count = harvest.confirmed_count / harvest.min_count;

          newData[index] = {
            ...harvest,
            harvest_date,
            set_count
          };

          if (!flowers.list[harvest.flower_id]) {
            flowersToLoad.push(harvest.flower_id);
          }
        });

        commit("setData", newData);
        commit("setHarvestWdays", res.result.harvest_wdays);

        if (flowersToLoad.length) {
          await dispatch("flowers/getFlowers", flowersToLoad, { root: true });
        }
      }

      return true;
    },
    putFlowerHarvest({ commit, dispatch, state }, data) {
      return new Promise(resolve => {
        this._vm.$http("put", "harvests", { flower_harvests: data }).then(res => {
          if (res.status === 200) {
            const newData = { ...state.list };

            Object.keys(data).forEach(id => {
              newData[id] = {
                ...newData[id],
                ...data[id]
              };
            });

            commit("setData", newData);
            dispatch("setToast", { type: "success", msg: "更新しました" }, { root: true });
            resolve(true);
          } else if (res.status === 500) {
            dispatch("setToast", { type: "error", msg: res.result.errors[0] }, { root: true });
            resolve(false);
          }
        });
      });
    },
    putHarvest({ commit, dispatch, state }, data) {
      return new Promise(resolve => {
        this._vm.$http("put", "harvests", { flower_harvests: data }).then(res => {
          if (res.status === 200) {
            if (res.result.new_flower_harvests.length > 0) {
              const newData = { ...state.list };
              const harvest = res.result.new_flower_harvests[0];
              const harvest_date = harvest.harvest_date ? new Date(harvest.harvest_date) : null;
              const set_count = harvest.confirmed_count / harvest.min_count;
              newData[state.listArr.length] = {
                ...harvest,
                harvest_date,
                set_count
              };

              commit("setData", newData);
            }
            resolve(res);
          } else if (res.status === 500) {
            dispatch("setToast", { type: "error", msg: res.result.errors[0] }, { root: true });
            resolve(false);
          }
        });
      });
    },
    getHarvest({ commit, dispatch, state }, data) {
      let url = "";
      if (data.flower_harvest_id) {
        url = `harvests/harvests?flower_harvest_id=${data.flower_harvest_id}`;
      } else if (data.flower_id) {
        url = `harvests/harvests?flower_id=${data.flower_id}`;
      }
      return new Promise(resolve => {
        this._vm.$http2("get", url).then(res => {
          if (res.status === 200) {
            let newData = state.list;
            newData = [];
            const harvests = res.result.data;
            harvests.forEach((value, index) => {
              const harvest = value;
              const harvest_date = harvest.harvest_date ? new Date(harvest.harvest_date) : null;
              const set_count = harvest.confirmed_count / harvest.min_count;

              newData[index] = {
                ...harvest,
                harvest_date,
                set_count
              };
            });
            commit("setData", newData);
            resolve(harvests);
          } else if (res.status === 500) {
            dispatch("setToast", { type: "error", msg: res.result.errors[0] }, { root: true });
            resolve(false);
          }
        });
      });
    },
    setIsShowSuccessModal({ commit }, data) {
      commit("setIsShowSuccessModal", data.isShowSuccessModal);
    }
  },
  getters: {}
};
