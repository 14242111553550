<template>
  <div v-if="flowerHarvest">
    <ImageSlider class="flex-shrink-0 flower-image" :image-urls="productImages" :caption="caption" />

    <PageContainer class="detail-container">
      <ProductDetailCard class="detail-card flex-grow-1" :flower-harvest="flowerHarvest" :is-add-cart-disabled="isAddCartDisabled"
        :show-conversation="showConversation" :isPurchaseDeadlineExpired="flowerHarvest.purchase_deadline_expired" :add-draft-order="addDraftOrder" />
      <p class="description">※写真はイメージです。 予告なくパッケージ、商品名、産地等が変更になる場合がございます。予めご了承ください。 （実際にお届けする商品と掲載内容が異なる場合がございます）</p>
      <RequestOrder v-if="flowerHarvest.stock_count <= 0" :currentCartCount="currentCartCount" :value="requestCount"
        :requestAnswerDeadline="flowerHarvest.request_answer_deadline" @input="handleChangeRequestCount"
        @add="handleClickAdd" :isPurchaseDeadlineExpired="flowerHarvest.purchase_deadline_expired" />
      <h1 class="title">商品説明</h1>
      <p class="description">{{ flowerHarvest.note || "なし" }}</p>
      <Pulldown class="pulldown">
        <template v-slot:title>
          お届けした商品に問題があった場合
        </template>
        <template v-slot:content>
          <span class="notify-content">
            <p>お届け翌日までにCAVINカスタマーサポートまで、下記を添えてご連絡ください。</p>
            <ul class="requirement-list">
              <li>状態がわかる写真</li>
              <li>品目ー品種</li>
              <li>生産者名</li>
            </ul>
            <p>ご連絡先：CAVIN公式LINE</p>
            <a href='https://lin.ee/eOfvy9T'>https://lin.ee/eOfvy9T</a>
          </span>
        </template>
      </Pulldown>
      <Pulldown>
        <template v-slot:title>
          商品のキャンセルについて
        </template>
        <template v-slot:content>
          <span class="notify-content">
            <p>生産者さんとの合意が取れている場合に限り、キャンセルができます。</p>
            <p>まずは、キャンセルの希望について、生産者さんに直接メッセージでお知らせください。</p>
          </span>
        </template>
      </Pulldown>
    </PageContainer>
  </div>
</template>

<script>
import Pulldown from "@/components/shop/products/Pulldown.vue";
import ImageSlider from "@/components/shared/ImageSlider.vue";
import ProductDetailCard from "@/components/shop/products/ProductDetailCard.vue";
import PageContainer from "@/components/shop/PageContainer.vue";
import RequestOrder from "@/components/shop/products/RequestOrder.vue";

export default {
  components: {
    Pulldown,
    ImageSlider,
    PageContainer,
    ProductDetailCard,
    RequestOrder
  },

  data() {
    return {
      flowerHarvest: null,
      requestCount: 0,
    };
  },

  async created() {
    const { result, status } = await this.$http(
      "get",
      `harvests/${this.$route.params.productId}`
    );

    if (status === 404) {
      this.$store.dispatch("setSnackBar", { msg: "この商品は存在しません。", color: "red" }, { root: true });
      this.$store.dispatch("snackOn", {}, { root: true });
    } else if (result.flower_harvest.status !== "on_sale") {
      this.$store.dispatch("setSnackBar", { msg: "この商品は現在、出品されていません。", color: "red" }, { root: true });
      this.$store.dispatch("snackOn", {}, { root: true });
    } else {
      this.flowerHarvest = result.flower_harvest;
    }
    const draftOrder = this.$store.getters["orders/draftOrder"];
    if (!draftOrder) await this.$store.dispatch("orders/getDraftOrder");
  },

  methods: {
    async addDraftOrder(orderItemType) {
      await this.post("order_items", this.createRequestBody(orderItemType));
      await this.$store.dispatch("orders/notifyAddCart");
    },

    async showConversation() {
      const res = await this.$http("post", "conversations", { farm: { id: this.flowerHarvest.flower.farm.id } });
      console.log(this.flowerHarvest);
      await this.$http("post", `conversations/${res.result.conversation.id}/messages`, {
        message: {
          body: `[${this.flowerHarvest.flower.flower_specie.flower_genus.name}] ${this.flowerHarvest.flower.flower_specie.name}についての問い合わせです。`,
        },
      });
      this.$router.push(`/shop/conversations/${res.result.conversation.id}`);
    },

    createRequestBody(orderItemType) {
      const requestBody = {
        order_items: [{
          flower_harvest_id: this.$route.params.productId,
        }]
      };
      if (orderItemType === "request") {
        requestBody.order_items[0].lot_count = this.getRequestPurchaseCount();
        requestBody.order_items[0].order_item_type = "request";
      } else {
        requestBody.order_items[0].lot_count = this.getPurchaseCount();
      }
      return requestBody;
    },

    getPurchaseCount() {
      if (!this.draftOrder || !this.draftOrder.order_items || this.draftOrder.order_items.length === 0) return 1;
      const orderItem = this.draftOrder.order_items.find(item => item.flower_harvest.id === Number(this.$route.params.productId) && item.order_item_type === "");

      return orderItem ? orderItem.lot_count : 1;
    },
    getRequestPurchaseCount() {
      return this.requestCount;
    },
    async post(url = "/order_items", data) {
      class OrderItemCreateError extends Error {
        constructor(status, message) {
          super(message);
          this.status = status;
          this.name = "OrderItemCreateError";
        }
      }

      try {
        const resOrderItem = await this.$http("post", url, data);
        if (resOrderItem.status === 422) throw new OrderItemCreateError(422, "Failed to adding item into cart");
        if (resOrderItem.status === 521) throw new OrderItemCreateError(521, "Failed to adding item into cart");

        this.harvestId = null;
      } catch (e) {
        if (e.status === 521) {
          this.$store.dispatch("orders/notifyUnbuyableAreaError");
        } else {
          this.$store.dispatch("orders/notifyAddToCartError");
        }

        console.log(e);
      } finally {
        await this.$store.dispatch("orders/getDraftOrder");
      }
    },
    handleChangeRequestCount(value) {
      this.requestCount = value;
    },
    handleClickAdd() {
      this.addDraftOrder("request");
    }
  },

  computed: {
    productImages() {
      return this.flowerHarvest.flower.images.map(image => image.image_url);
    },
    draftOrder() {
      return this.$store.getters["orders/draftOrder"] || [];
    },
    isAddCartDisabled() {
      if (this.flowerHarvest.stock_count <= 0) return true;
      if (!this.draftOrder || !this.draftOrder.order_items || !this.draftOrder.order_items.length) return false;
      const orderItem = this.draftOrder.order_items.find(item => item.flower_harvest.id === Number(this.$route.params.productId) && item.order_item_type !== "request");
      if (!orderItem) return false;
      return orderItem.subtotal_count >= this.flowerHarvest.stock_count;
    },
    caption() {
      return this.flowerHarvest.stock_count <= 0 ? this.$t("request_only") : "";
    },
    currentCartCount() {
      if (!this.draftOrder || !this.draftOrder.order_items || !this.draftOrder.order_items.length) return 0;
      const orderItem = this.draftOrder.order_items.find(item => item.flower_harvest.id === Number(this.$route.params.productId) && item.order_item_type === "request");
      if (!orderItem) return 0;
      return orderItem.lot_count;
    }
  }
};
</script>

<style lang="scss" scoped>
.detail-container {
  padding: 15px !important;
}

.detail-card {
  margin-bottom: 12px;
}

.flower-image {
  max-width: 1000px;
  margin: 0 auto;
}

.title {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 16px;
}

.description {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.6;
}

.pulldown {
  margin-bottom: 5px;
}

.notify-content {
  p {
    margin-bottom: 0;
  }

  ul {
    margin-bottom: 1rem;
  }

  &>.requirement-list {
    padding-left: 1rem;
  }
}
</style>
