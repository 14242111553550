<template>
  <div>
    <Header :current-fortnight="currentFortnight" :previous-fortnight="previousFortnight"
      class="ga-shop-products-header" />
    <v-alert v-if="isWhenAbsent" color="#2A3B4D" dark icon="mdi-firework" dense>
      不在時の納品場所を追加してください。
      <v-text-field class="mt-2" v-model="whenAbsent" solo label="例) 店舗前においてください。" outlined dense></v-text-field>
      <div class="d-flex justify-end">
        <v-btn color="primary" @click="submit">
          登録する
        </v-btn>
      </div>
    </v-alert>
    <alert v-if="!agreeTerms">
      <template v-slot:contents>
        <div class="text-body-1">
          2022年4月15日、<u><a class="d-inline-flex align-baseline" href="https://cavin.ooo/terms-1" target="_blank">
              利用規約<v-icon color="#2196f3" small>mdi-open-in-new</v-icon></a></u>を改定いたしました。
        </div>
        <div class="text-body-1">変更後の内容をご確認いただき、同意される場合は、チェックボックスにチェックを入れて「利用する」ボタンを押してください。</div>
      </template>
      <template v-slot:actions>
        <div class="d-flex flex-column">
          <v-checkbox label="利用規約の内容を確認しました。" v-model="agree" class="ma-0" hide-details />
          <v-btn :disabled="!agree" color="info" outlined class="mt-4" @click="agreetment">利用する</v-btn>
        </div>
      </template>
    </alert>

    <!-- <v-container class="invoice">
      <v-row>
        <v-col cols="12" md="6">
          <InvoiceAmount :startDate=confirmedStartDate :endDate=confirmedEndDate :isConfirmed="true"
            :invoiceCount=confirmedInvoiceCount />
        </v-col>
        <v-col cols="12" md="6">
          <InvoiceAmount :startDate=unconfirmedStartDate :endDate=unconfirmedEndDate :isConfirmed="false"
            :invoiceCount=unconfirmedInvoiceCount />
        </v-col>
      </v-row>
    </v-container> -->

    <DatePickerV2 class="mt-3" :selected="selectedDate" :allowWdayList="allowWdayList" @change="dateHandler" />

    <product-filter-mobile @click="onClickSearchHandler" ref="productFilterMobile" :farms="farms"
      :categories="categories" :list="flowers" :selectedDate="selectedDate" />

    <!-- <div style="text-align: center" id="top">
      <small>{{ $t("welcome") }}</small>、
      <strong>{{ shop.name }}：{{ shop.representative_name }}</strong>
      <small>{{ $t("sama") }}</small>
    </div> -->

    <ProductContainer id="top">
      <div class="page-title d-flex justify-space-between align-center">
        <div class="product-count">
          {{ pagination.total }}<span style="font-size: 12px; font-weight: 400;">{{ $t("piece") }}</span>
        </div>
        <div class="d-flex">
          <v-btn :color="listView ? '#111111' : '#CCCCCC'" @click="() => changeView('grid')" small fab outlined
            class="ga-shop-products-switch-grid-view list-view">
            <v-icon>mdi-view-grid-outline</v-icon>
          </v-btn>
          <v-btn class="ml-2 ga-shop-products-switch-list-view card-view" :color="listView ? '#CCCCCC' : '#111111'"
            @click="() => changeView('list')" small fab outlined>
            <v-icon>mdi-format-list-bulleted-square</v-icon>
          </v-btn>
          <div class="ml-2">
            <v-menu transition="slide-y-transition" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" small color="white">
                  {{ sort_name }}<v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item-group v-model="sort">
                  <v-list-item value="outside_region" @click="setSortName(0)">
                    <v-list-item-title class="ga-shop-products-order-by-low-price">
                      <v-icon v-if="(sort_selection === 0)" class="check-icon">mdi-check mdi-18px</v-icon>
                      <v-icon v-else class="check-icon">mdi-blank mdi-18px</v-icon>
                      {{ $t('recommended_order') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item value="sales_price_asc" @click="setSortName(1)">
                    <v-list-item-title class="ga-shop-products-order-by-low-price">
                      <v-icon v-if="(sort_selection === 1)" class="check-icon">mdi-check mdi-18px</v-icon>
                      <v-icon v-else class="check-icon">mdi-blank mdi-18px</v-icon>
                      {{ $t('low_price_order') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item value="sales_price_desc" @click="setSortName(2)">
                    <v-list-item-title class="ga-shop-products-order-by-high-price">
                      <v-icon v-if="(sort_selection === 2)" class="check-icon">mdi-check mdi-18px</v-icon>
                      <v-icon v-else class="check-icon">mdi-blank mdi-18px</v-icon>
                      {{ $t('high_price_order') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item value="stock_count" @click="setSortName(3)">
                    <v-list-item-title class="ga-shop-products-order-by-stock">
                      <v-icon v-if="(sort_selection === 3)" class="check-icon">mdi-check mdi-18px</v-icon>
                      <v-icon v-else class="check-icon">mdi-blank mdi-18px</v-icon>
                      {{ $t('stock_order') }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
      <!-- <v-divider />
      <div>
        <div class="text-subtitle-2"><span class="text-title-">{{ pagination.total }}</span>件</div>
      </div> -->

      <div v-if="listView" class="product-list">
        <ProductListItem v-for="flowerHarvest in flowers" :key="flowerHarvest.id"
        :flowerHarvest="flowerHarvest"/>
      </div>
      <div v-else class="card-product">
        <ProductCard v-for="flowerHarvest in flowers" :key="flowerHarvest.id"
        :flowerHarvest="flowerHarvest" />
      </div>
      <div class="no-product" v-if="!flowers.length">{{ $t("no_product_found") }}</div>
      <div class="text-center">
        <v-pagination class="custom-pagination" v-model="page" color="#315198" :length="pagination.length"
          :total-visible="6" @input="paginationHandler" v-scroll-to="'#top'"></v-pagination>
      </div>
    </ProductContainer>
    <NoAreaNotation v-if="shop_default_area_blank" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProductContainer from "@/components/shop/products/ProductContainer.vue";
import ProductCard from "@/components/shop/products/ProductCard.vue";
import ProductListItem from "@/components/shop/products/ProductListItem.vue";
import NoAreaNotation from "@/components/shop/products/NoAreaNotation.vue";
import DatePickerV2 from "@/components/shop/DatePickerV2.vue";
import ProductFilterMobile from "@/components/shop/products/FilterMobile/index.vue";
import Header from "@/components/shop/products/Header.vue";
import Alert from "@/components/shared/Alert.vue";
import { formatDate } from "@/plugins/i18n";
import sanitizeQueryObject from "@/helpers/sanitizeQueryObject";

const watchQuery = ["category_id", "flower_genus_id", "flower_specie_id", "farm_id", "delivery_date", "page", "stem_size", "available", "sort"];

export function getConfirmedStartDate() {
  const today = new Date();
  if (today.getDate() < 16) {
    today.setMonth(today.getMonth() - 1);
    today.setDate(16);
  } else {
    today.setDate(1);
  }
  return formatDate(today, "m/d");
}

export function getConfirmedEndDate() {
  const today = new Date();
  if (today.getDate() < 16) {
    today.setDate(0);
  } else {
    today.setDate(15);
  }
  return formatDate(today, "m/d");
}

export function getCurrentStartDate() {
  const today = new Date();
  if (today.getDate() < 16) {
    today.setDate(1);
  } else {
    today.setDate(16);
  }
  return formatDate(today, "m/d");
}

export function getCurrentEndDate() {
  const today = new Date();
  if (today.getDate() < 16) {
    today.setDate(15);
  } else {
    today.setMonth(today.getMonth() + 1);
    today.setDate(0);
  }
  return formatDate(today, "m/d");
}

export default {
  components: {
    ProductContainer,
    ProductCard,
    ProductListItem,
    Header,
    Alert,
    NoAreaNotation,
    ProductFilterMobile,
    DatePickerV2,
  },
  watchQuery,
  computed: {
    ...mapGetters(["getUserName"]),
    shop() {
      return this.$store.state.user.shop;
    },
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    farms() {
      return this.$store.state.searchQuery.available_farms;
    },
    categories() {
      return this.$store.state.searchQuery.categories;
    },
    isWhenAbsent() {
      const whenAbsent = this.$store.state.user.shop.locations?.filter(location => location.default === true)[0].when_absent;
      return !whenAbsent || whenAbsent === "";
    },
    agreeTerms() {
      return this.$store.state.user.agree_terms;
    },
    allowWdayList() {
      return this.$store.getters.workingWdays;
    },
  },
  data() {
    return {
      // dateKey: this.$route.query.date || nextDateKey(),
      alert: true,
      flowers: [],
      currentFortnight: 0,
      previousFortnight: 0,
      shop_default_area_blank: false,
      listView: false,
      page: null,
      pagination: {},
      whenAbsent: "",
      available: 0,
      agree: false,
      sort: null,
      confirmedStartDate: getConfirmedStartDate(),
      confirmedEndDate: getConfirmedEndDate(),
      unconfirmedStartDate: getCurrentStartDate(),
      unconfirmedEndDate: getCurrentEndDate(),
      confirmedInvoiceCount: 345736,
      unconfirmedInvoiceCount: 154321,
      productCount: 236,
      selectedDate: null,
      sort_selection: -1,
      sort_name: this.$t("order_title"),
      sortItems: [
        "outside_region",
        "sales_price_asc",
        "sales_price_desc",
        "stock_count"
      ]
    };
  },

  async beforeMount() {
    this.listView = await this.$db("shopProductViewType");
  },

  mounted() {
    this.page = this.$route.query.page ? JSON.parse(decodeURIComponent(this.$route.query.page)) : 1;
    this.available = this.$route.query.available ? JSON.parse(decodeURIComponent(this.$route.query.available)) : 0;
  },
  created() {
    let date = new Date();
    if (this.$route.query.harvest_date) { date = new Date(this.$route.query.harvest_date); }
    if (this.$route.query.delivery_date) { date = new Date(this.$route.query.delivery_date); }
    const wdays = this.allowWdayList && this.allowWdayList.length > 0 ? this.allowWdayList : [0, 1, 2, 3, 4, 5, 6];
    while (!wdays.includes(date.getDay())) {
      date.setDate(date.getDate() + 1);
    }
    this.dateHandler(formatDate(date, "yyyy-mm-dd"));

    this.$store.dispatch("getSearchQuery")
      .then(() => {
        this.initializeProductFilterMobile();

        const index = this.sortItems.indexOf(this.$route.query.sort);

        if (this.$route.query.sort && index >= 0) {
          this.setSortName(index);
          this.sort = this.$route.query.sort;
        } else {
          this.sort = "outside_region";
          this.sort_selection = 0;
          this.sort_name = this.$t("recommended_order");
        }
        this.fetchHome();
      });
  },
  watch: {
    flowers: {
      handler() { },
      deep: true
    },
    available() {
      const newQuery = { ...this.$route.query, available: this.available };
      this.$router.push({ query: newQuery });
      this.fetchHome();
    },
    sort() {
      const newQuery = { ...this.$route.query, sort: this.sort };
      this.$router.push({ query: newQuery });
      this.fetchHome();
    },
    selectedDate() {
      return this.selectedDate;
    }
  },
  methods: {
    setSortName(value) {
      this.sort_selection = value;
      if (value === 0) {
        this.sort_name = this.$t("recommended_order");
      } else if (value === 1) {
        this.sort_name = this.$t("low_price_order");
      } else if (value === 2) {
        this.sort_name = this.$t("high_price_order");
      } else if (value === 3) {
        this.sort_name = this.$t("stock_order");
      }
    },
    onClickSearchHandler(q) {
      this.page = 1;
      const params = { page: this.page, sort: this.sort };
      if (q.category) params.category_id = q.category.id;
      if (q.genusIds.length > 0) params.flower_genus_id = q.genusIds;
      if (q.farmIds.length > 0) params.farm_id = q.farmIds;
      if (q.date) params.delivery_date = q.date;
      if (q.specie) params.flower_specie_id = q.specie.id;
      if (q.stem_size) params.stem_size = q.stem_size;

      params.available = this.available;

      const query = sanitizeQueryObject(params, watchQuery);
      this.$router.push({ query });
      this.fetchHome();
    },
    paginationHandler(number) {
      const query = this.$route.query;
      const newQuery = { ...query, page: number };
      this.$router.push({ query: newQuery });
      this.fetchHome();
    },
    formatDate(d) {
      return formatDate(d, "yyyy-mm-dd");
    },
    formatDateMD(md) {
      return formatDate(md, "m/d");
    },
    async fetchHome() {
      // const { status, result } = await this.$http("get", "/home", { params: this.$route.query });
      const { status, result } = await this.$http2("get", "shop/flower_harvests", { params: this.$route.query });

      if (status === 200) {
        this.flowers = result.flowers;
        this.pagination = result.pagination;
        this.previousFortnight = result.previous_fortnight_total_sales_price_with_tax;
        this.currentFortnight = result.current_fortnight_total_sales_price_with_tax;
        this.shop_default_area_blank = result.shop_default_area_blank;
        this.dateHandler(result.delivery_date);
      }
    },
    async agreetment() {
      const res = await this.$http("put", "users/agree_terms");
      if (res.status === 200) { this.$store.state.user.agree_terms = true; }
    },
    changeView(type) {
      this.listView = type === "list";
      this.$db("shopProductViewType", this.listView);
    },
    initializeProductFilterMobile() {
      this.$refs.productFilterMobile.initialize();
    },
    async submit() {
      if (this.whenAbsent) {
        const defaultLocation = this.$store.state.user.shop.locations.find(location => location.default === true);
        await this.$store.dispatch("putLocation", {
          id: defaultLocation.id,
          content: { when_absent: this.whenAbsent }
        }).then(() => {
          this.$store.dispatch("setSnackBar", { msg: "不在時の配送場所を登録しました", color: "green" }, { root: true });
          this.$store.dispatch("snackOn", {}, { root: true });
        });
      }
    },
    dateHandler(date) {
      this.selectedDate = date;
    },
    updateHarvestCountValue(flowerHarvest, value) {
      this.$store.getters["orders/normalOrderItem"](flowerHarvest.id).lot_count = value;
      // if (this.timeoutId) clearTimeout(this.timeoutId);
      // this.timeoutId = setTimeout(async () => {
      //   if (value === 0) {
      //     this.$store.dispatch("orders/deleteOrderItem", this.normalOrderItem);
      //   } else {
      //     await this.editCountHandler(value);
      //     this.$store.dispatch("orders/getDraftOrder");
      //   }
      // }, 800);
    },
  }
};
</script>

<style lang="scss" scoped>
.page-title {
  color: #333;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 10px;
  margin: 0px 10px 0px 15px;
}

.product-count {
  font-size: 18px;
  font-weight: 700;
}

.card-product {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  row-gap: 40px;

  @media only screen and (max-width: 960px) {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
}

.product-total {
  margin: 10px 10px 10px 10px;
}

.container {
  padding: 0px 15px;

  .row {
    margin: 0;
  }

  .col-12 {
    padding: 15px 5px;

    @media only screen and (max-width: 960px) {
      padding: 5px 0 0 0;
    }
  }
}

.check-icon {
  width: 18px;
  height: 18px;
}

.custom-pagination::v-deep {

  button.v-pagination__item,
  button.v-pagination__navigation,
  .v-pagination__more {
    height: 40px;
    margin: 2px;
    color: #315198;
  }

  button.v-pagination__item--active {
    color: #FFFFFF;
  }

  button.v-pagination__navigation {
    background-color: #315198;

    i {
      color: #FFFFFF;
    }
  }
}

.no-product {
  text-align: center;
  margin: 24px;
}
</style>
