<template>
  <div>
    <v-container fluid>
      <alert v-if="!agreeTerms">
        <template v-slot:contents>
          <div class="text-body-1">
            2022年4月15日、<u><a class="d-inline-flex align-baseline" href="https://cavin.ooo/terms-1" target="_blank">
            利用規約<v-icon color="#2196f3" small>mdi-open-in-new</v-icon></a></u>を改定いたしました。
          </div>
          <div class="text-body-1">変更後の内容をご確認いただき、同意される場合は、チェックボックスにチェックを入れて「利用する」ボタンを押してください。</div>
        </template>
        <template v-slot:actions>
          <div class="d-flex flex-column">
            <v-checkbox label="利用規約の内容を確認しました。" v-model="agree" class="ma-0" hide-details />
            <v-btn :disabled="!agree" color="info" outlined class="mt-4" @click="agreetment">利用する</v-btn>
          </div>
        </template>
      </alert>
      <div v-if="data.harvests_count > 0">
        <h2 class="text-center ma-4">{{ $t("farmDashboard") }}</h2>
        <div class="link-group">
          <MstLinkBar icon="truck-cargo-container" to="/farm/order_items?status=unshipped" :label="$t('schedule_ship_today')(data.order_items_count_today)" />
          <MstLinkBar icon="truck-cargo-container" to="/farm/order_items?status=unshipped" :label="$t('new_orders')(data.confirmed_order_item_count)" />
          <MstLinkBar icon="truck-cargo-container" to="/farm/order_items?status=request" :label="$t('new_reservations')(data.request_order_items_count)" />
        </div>
        <div class="link-group">
          <MstLinkBar icon="text-box" to="/farm/shipping_results" :label="$t('show_shipping_results')" />
          <MstLinkBar icon="chart-line" to="/farm/pdf_picker" :label="$t('download_report')" />
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import Alert from "@/components/shared/Alert.vue";
import { MstLinkBar } from "@/components/master";

export default {
  components: {
    MstLinkBar,
    Alert
  },
  data() {
    return {
      agree: false
    };
  },
  computed: {
    data() {
      return this.$store.state.farmDashboard;
    },
    agreeTerms() {
      return this.$store.state.auth.agree_terms;
    }
  },

  created() {
    this.$store.dispatch("getDashboardData");
  },

  methods: {
    async agreetment() {
      const res = await this.$http("put", "users/agree_terms");
      if (res.status === 200) { this.$store.dispatch("agreement"); }
    }
  }
};
</script>

<style scoped>
.link-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 40px;
}
</style>
